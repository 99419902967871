import ConfigDev from "./development.config";
import ConfigLocal from "./local.config.json";
import ConfigStaging from "./staging.config";
import ConfigProduction from "./production.config";
import ConfigMyfiles from "./myfilesStaging.config";
import ConfigMyfilesProd from "./myfiles_production.config";

const Config =
  process.env.REACT_APP_ENVIRONMENT === "local"
    ? ConfigLocal
    : process.env.REACT_APP_ENVIRONMENT === "development"
    ? ConfigDev
    : process.env.REACT_APP_ENVIRONMENT === "staging"
    ? ConfigStaging
    : process.env.REACT_APP_ENVIRONMENT === "myfiles-staging"
    ? ConfigMyfiles
    : process.env.REACT_APP_ENVIRONMENT === "myfiles-production"
    ? ConfigMyfilesProd
    : ConfigProduction;

export default Config;
