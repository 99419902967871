import { React } from "react";
import {
  Alert,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Card,
  CardMedia,
  Button,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { ThemeProvider } from "@mui/material/styles";
import wordiblyLogo from "../assets/img/wordiblyLogo.webp";
import ourClients from "../assets/img/Our_client.png";
import { themeWordibly } from "../theme";
import WordiblyFooter from "./myFiles/WordiblyFooter.js";

export default function Login_wordibly(props) {
  const { handleSubmit, error, alertError, errorFields, loading, open } = props;
  return (
    <ThemeProvider theme={themeWordibly}>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "200vh",
          padding: "0",
          margin: "0",
          width: "100%",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100%",
          }}
        >
          <Card
            component="main"
            sx={{
              width: { xs: "100%", md: 3 / 4 },
              overflowY: "auto",
              p: { xs: 1, md: 4, lg: 6 },
              maxWidth: "900px",
              boxSizing: "border-box",
            }}
          >
            <CardMedia
              component="img"
              height="100"
              sx={{ objectFit: "contain" }}
              image={wordiblyLogo}
              onClick={() => (window.location.href = "https://wordibly.com")}
            />
            <Box
              container
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "space-between" },
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "center", md: "start" },
              }}
            >
              <Box
                sx={{
                  marginTop: 8,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: { xs: "center", md: "space-between" },
                  maxWidth: { xs: "100%", md: 300 },
                }}
                style={{ marginTop: 10 }}
              >
                <Typography variant="h1" xs={{ fontWeight: "bold" }}>
                  Sign In
                </Typography>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ display: "block" }}
                >
                  {error && (
                    <Alert variant="outlined" severity="error">
                      {alertError}
                    </Alert>
                  )}
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    variant="filled"
                    autoComplete="email"
                    error={errorFields}
                    autoFocus
                    helperText={errorFields ? "Incorrect entry." : ""}
                    display={"block"}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    variant="filled"
                    type="password"
                    error={errorFields}
                    id="password"
                    autoComplete="current-password"
                    helperText={errorFields ? "Incorrect entry." : ""}
                    display={"block"}
                  />
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                      gap: "10px",
                    }}
                  >
                    <LoadingButton
                      type="submit"
                      color="primary"
                      variant="contained"
                      sx={{ flex: 1, flexBasis: "auto", px: 4 }}
                      loading={loading}
                    >
                      Login
                    </LoadingButton>
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{ flex: 1, flexBasis: "auto", px: 3 }}
                      href="/account/auth/register"
                    >
                      CREATE ACCOUNT
                    </Button>
                  </Box>

                  <Grid container>
                    <Grid item xs>
                      <Link href="#" variant="body2" onClick={() => open()}>
                        Forgot password?
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  maxWidth: "450px",
                  height: "100%",
                }}
              >
                <CardMedia
                  component="img"
                  sx={{ objectFit: "contain" }}
                  image={ourClients}
                />
              </Box>
            </Box>
          </Card>
        </Box>
        <WordiblyFooter isFullWidth={true} />
      </Box>
    </ThemeProvider>
  );
}
