import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useZustant from "../hooks/useZustant";
import Requests from "../logic/Requests";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKeyboard } from "@fortawesome/free-regular-svg-icons";
import { LoadingButton } from "@mui/lab";
import Config from "../config";

export default function ActionsByUnitState(props) {
  const {
    assigned_to,
    reviewed_by,
    unitId,
    project_id,
    transcript_id,
    state,
    transcriberHasUnit,
    changeUnitState,
    handleChangeTranscriberState,
    openUnitError,
    new_editor,
    manual_assigned,
    open,
    setAllowNavigate,
    unit_content,
    group_unit
  } = props;

  const navigate = useNavigate();
  const { user } = useZustant();
  const [exit, setExit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assignedTo, setAssignedTo] = useState("");
  const disableAcceptButtons = () => {
    const htmlCollection = document.getElementsByClassName("accept");

    const acceptButtons = Array.prototype.slice.call(htmlCollection);

    acceptButtons.forEach((element) => {
      element.disabled = true;
    });
  };

  const acceptUnit = (unitId) => {
    
    if (transcriberHasUnit && manual_assigned === "0") {
      openUnitError();
    } else {
      disableAcceptButtons();
      setLoading(true);
      const doc = {
        state: "Accepted",
        user_id: user.id,
        transcript: group_unit
      };

      Requests.changeStateUnitV2(unitId, doc)
        .then((res) => {
          if (res.status === 200) {
            if(group_unit) {
              navigate(`/mywork/${user._id}`)
            }else {
              handleTranscribe();
              setAssignedTo(user._id);
              setExit(true);
              setLoading(false);
              handleChangeTranscriberState.setTrue();
              changeUnitState(unitId, "accept");
            }
            
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const handleTranscribe = () => {
    const transcriberHubCookieExpires = new Date();
    transcriberHubCookieExpires.setTime(
      transcriberHubCookieExpires.getTime() + 24 * 60 * 60 * 1000
    );
    const transcriberHubCookie = `comesFromTranscriberHub=true; expires=${transcriberHubCookieExpires.toUTCString()}; domain=${
      Config.cookieDomain
    }; path=/`;
    document.cookie = transcriberHubCookie;
    navigate(
      `/projects/${project_id}/transcript/${transcript_id}/unit/${unitId}`
    );
  };
  let color;
  if (unit_content == null) {
    if (!new_editor) {
      color = "success";
    } else {
      color = "primary";
    }
  } else if (unit_content.hasOwnProperty("type")) {
    color = "success";
  } else {
    color = "primary";
  }
  if (
    ((state !== "Accepted" ) &&  
    (state !== "Assigned" || assigned_to !== user._id) &&
    state !== "Ready for Review" &&
    
    state !== "Incomplete") || (state == "Ready for Review" && reviewed_by != user._id) ||
    (state == "Incomplete" && props.role == "QA" && !transcriberHasUnit )&&
    !loading
  ) {
    return (
      <>
        <LoadingButton
          variant="contained"
          sx={{
            fontSize: "11px",
            color: "white !important",
            width: "100%",
            minWidth: "auto",
            maxWidth: "90%",
          }}
          color={color}
          onClick={() => acceptUnit(unitId, manual_assigned)}
          loading={loading}
        >
          Claim
        </LoadingButton>
      </>
    );
  }

  if (
    ((assigned_to === user._id || assignedTo === user._id || reviewed_by === user._id) &&
      (state === "Accepted" || state === "Ready for Review" ||
        state === "Assigned" ||
        state === "Incomplete" ||
        state === "Revision Request")) ||
    exit === true
  ) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        {loading ? (
          <Tooltip title="Transcribe" className="accept">
            <IconButton style={{ marginBottom: 2 }}>
              <CircularProgress size={20} />
            </IconButton>
          </Tooltip>
        ) : manual_assigned === "0" || (state === "Accepted" || state === "Ready for Review" ||
        (state === "Incomplete" && props.role === "QA") ) ? (
          <Tooltip title="Transcribe" className="accept">
            <IconButton onClick={handleTranscribe} style={{ marginBottom: 2 }}>
              <FontAwesomeIcon
                icon={faKeyboard}
                color="primary"
                fontSize="24px"
              />
            </IconButton>
          </Tooltip>
        ) : (
          <LoadingButton
            variant="contained"
            sx={{
              fontSize: "11px",
              color: "white !important",
              width: "100%",
              minWidth: "auto",
              maxWidth: "90%",
            }}
            color={color}
            onClick={() => acceptUnit(unitId, manual_assigned)}
            loading={loading}
          >
            Claim
          </LoadingButton>
        )}
      </div>
    );
  }
}
