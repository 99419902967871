import { React, useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import {themeWordibly as theme } from "../theme";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import CustomShareFileDialog from "../components/global/CustomShareFileDialog"
import {
  Typography,
  Button,
  TableHead,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Box,
  Link,
  Menu,
  MenuItem,
  Container,
} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from "@mui/icons-material/Print";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Requests from "../logic/Requests";
import useZustant from "../hooks/useZustant";
import { useNavigate } from "react-router-dom";
import useShow from "../hooks/useShow";
import CustomSnackBarAlert from "../components/global/CustomSnackBarAlert";
import { useParams } from "react-router-dom";
import PlaceNewOrderButton from "../components/PlaceNewOrderButton";
import useFetch from "../hooks/useFetch";

export default function OrderDetails() {
  let { idProject } = useParams();
  const { user, clientPrepaidBalance, changeClientPrepaidBalance } =
    useZustant();

  const {
    data: dataOrderDetails,
    isLoading: isLoadingOrderDetails,
    setData: setDataOrderDetails,
  } = useFetch(() => Requests.getMyFilesInvoice(idProject));

  useEffect(() => {
    document.title = "Order Details - Wordibly";
  }, []);

  const navigate = useNavigate();
  const [currentAlertState, setCurrentAlertState] = useState({
    type: "error",
    message: "",
  });
  const { show, open, close } = useShow();
  const handleAlert = (type, message) => {
    setCurrentAlertState({
      type,
      message,
    });
    open();
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFile, setSelectedFile] = useState("");
  const [openShareDialog,setOpenShareDialog]=useState(false);
  const [selecteTranscript,setSelecteTranscript]=useState(false);
  const [url,setUrl]=useState("");
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedFile(event.currentTarget.id);
  };
  const handleClose = () => {
    setSelectedFile("");
    setAnchorEl(null);
  };
  // transform date
  function formattedDate(dateStr) {
    const dateObj = new Date(dateStr);
    const formatted = dateObj.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return formatted;
  }

  // subcomponents
  const ProjectInfo = () => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly", alignContent: "start", flexDirection:"column", gap:"8px", width: "450px"}}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignContent: "center" }}>
          <Typography variant="h4">Order Placed:</Typography>
          <Typography>{formattedDate(dataOrderDetails.orderPlaced)}</Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box sx={{display: "flex" , gap: "8px", flexWrap: "wrap"}}>
            <Typography variant="h4">Reference:</Typography>
            {/* <Typography sx={{ textTransform: "capitalize" }}>
              {dataOrderDetails.reference ?? "(none provided)"}
            </Typography>             */}
          </Box>
          <Typography sx={{ textTransform: "capitalize" }}>
              {dataOrderDetails.reference == "" ? "(none provided)" : dataOrderDetails.reference}
          </Typography>            
          {/* <Button>edit</Button> */}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h4">Source Language:</Typography>
          <Typography textTransform={"capitalize"}>{dataOrderDetails.files[0].language}</Typography>
        </Box>
      </div>
    );
  };

  function formatDuration(seconds) {
    if (typeof seconds !== 'number' || isNaN(seconds)) {
      return "Invalid input";
    }
    const minutes = seconds/60;
    const hours = Math.floor(seconds / 3600);
    const remainingMinutes = Math.floor((seconds/60 % 60));
    const remainingSeconds =Math.round((minutes - remainingMinutes )*60);
  
    return `${hours > 0 ? `${hours} h ` : ''}${remainingMinutes > 0 ? `${remainingMinutes} m ` : ''}${remainingSeconds>0 ? remainingSeconds+" s " : ""  }`;
  }
  const PrintSection = () => {
    return (
      <Container sx={{ boxShadow: "4px 3px 26px -1px rgba(0,0,0,0.25)", py:4, borderRadius: '8px', mr:0 , width:"300px" }} >
        <Typography variant={"subtitle2"}>TOTAL</Typography>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
          <Typography>$ {dataOrderDetails.totalCost.toFixed(2)}</Typography>
          <Button onClick={()=>window.open(`/orderreceipt/${idProject}`, '_blank')}>
            <PrintIcon /> <Typography ml={1}>Print Receipt</Typography>
          </Button>
        </Box>
      </Container>
    );
  };
  const downloadSelectedTranscript = async () => {
    try {
      const transcriptId = selectedFile;
      const selected = dataOrderDetails.files.filter(s=> s.transcriptId == transcriptId)[0] ;
      if(selected.status == "Word Doc Generated" ){
        const url = await Requests.getWordTemplate(transcriptId);
        window.location = url;
      }else{
        handleAlert("error",`The transcription of audio "${selected.name}" order ${idProject.slice(-6)} is still pending`)
      }
    } catch (error) {
      handleAlert(
        "error",
        "There was a problem downloading the transcript doc"
      );
    }
  };
  const downloadFile = (transcriptId=selectedFile) => {
      try {
        const key = dataOrderDetails.files.filter(e=>e.transcriptId == transcriptId)[0]?.path;
        const url = Requests.downloadObjects([key]);
        window.location = url;
      } catch (error) {
        handleAlert("error", "There was a problem downloading file(s)");
      }
    }

  const downloadSelectedFiles = (files) => {
    try {
      const arrayOfIds = files.map((file) => file.path);
      const url = Requests.downloadObjects(arrayOfIds);
      window.location = url;
    } catch (error) {
      handleAlert("error", "There was a problem downloading file(s)");
    }
  };
  const handleShare = async (event) => {
    try {
      const transcriptId = selectedFile;
      const selected = dataOrderDetails.files.filter(s=> s.transcriptId == transcriptId)[0] ;
      if(selected.status == "Word Doc Generated" ){
        const URL = await Requests.getWordTemplate(transcriptId);
        setUrl(URL)
        setOpenShareDialog(true);
      }else{
        handleAlert("error",`The transcription of audio "${selected.name}" order ${idProject.slice(-6)} is still pending`)
      }
    } catch (error) {
      handleAlert(
        "error",
        "There was a problem downloading the transcript doc"
      );
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <CustomSnackBarAlert
        type={currentAlertState.type}
        show={show}
        close={close}
      >
        {currentAlertState.message}
      </CustomSnackBarAlert>
      <Box style={{position: "fixed", top:"125px", right:"42px",zIndex:100}}><PlaceNewOrderButton /></Box>
    { openShareDialog && <CustomShareFileDialog
        open={openShareDialog}
        setOpen={setOpenShareDialog}
        selecteTranscript ={selecteTranscript}
        url={url}
        transcriptId={selectedFile}
      />}
      {isLoadingOrderDetails ? (
        "loading Order data..."
      ) : (
        <Container
          sx={{
            mt: 3,
          }}
          style={{ height: "100%" }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography variant={"h1"}>
                Order Details - {idProject.slice(-6)}
              </Typography>

              <Button
                onClick={() => navigate("/orderhistory")}
                color="blue"
                startIcon={
                  <FontAwesomeIcon
                    icon={faBackward}
                    style={{ fontSize: "14px" }}
                  />
                }
              >
                Return to Order History
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
            <ProjectInfo />
            <PrintSection />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 8,
              mb: 4,
            }}
          >
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Length</TableCell>
                  <TableCell align="right">Order Status</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataOrderDetails.files.map((row,i) => (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">
                      {formatDuration(row.length)}
                    </TableCell>
                    <TableCell align="right">
                      {row.status === "Word Doc Generated"
                        ? "Completed"
                        : "Open"}
                    </TableCell>
                    <TableCell align="right">
                      <Button id={row.transcriptId} onClick={handleClick}>
                        <MoreHorizIcon
                          style={{
                            color: theme.palette.gray.strong,
                          }}
                        />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  style={{ boxShadow: "none" }}
                >
                  <MenuItem
                    style={{ boxShadow: "none" }}
                    onClick={() =>
                      downloadSelectedTranscript(true)
                    }
                  >
                    Download
                  </MenuItem>
                  <MenuItem
                  onClick={handleShare}
                  >
                    Share
                  </MenuItem>
                </Menu>
              </TableBody>
            </Table>
          </TableContainer>
          <Box my={4} style={{display:"flex",flexDirection:"column", gap:"4px"}}>
            <Typography>Original Files</Typography>
            {dataOrderDetails.files.map((e,i)=>(
              <Link onClick={()=>{
                downloadFile(e.transcriptId);
              }}>{e.name}</Link>
            ))}
        
          </Box>
        </Container>
      )}
    </ThemeProvider>
  );
}
