import { ThemeProvider } from "@emotion/react";
import {themeWordibly as theme } from "../theme";
import { Box, Container } from "@mui/system";
import {
  Typography,
  Button,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Paper,
  styled,
  InputBase,
  FormControl,
  InputLabel,
  Input,
  Switch,
  Stack,
} from "@mui/material";
import "../App.css";
import ChangePasswordModal from "../components/ChangePassword";
import useShow from "../hooks/useShow";
import useZustant from "../hooks/useZustant";
import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBackward,
  faCopy,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import CustomSnackBarAlert from "../components/global/CustomSnackBarAlert";
import Requests from "../logic/Requests";
import useFetch from "../hooks/useFetch";
import Utils from "../utils";
import { Navigate } from "react-router-dom";

export default function MyAccount() {
  const { user, setCurrentClientPage, changeIsInClientsSettings } =
    useZustant();
  const { open, close, show } = useShow();
  const [editingEmail, setEditingEmail] = useState(false);
  const [editingCompany, setEditingCompany] = useState(false);

  const [currentAlertState, setCurrentAlertState] = useState({
    type: "error",
    message: "",
  });

  const apikey = useRef(null);

  const {
    data: clientInformation,
    isLoading: loadingClientInformation,
    setData: setClientInformation,
    reFetch,
  } = useFetch(Requests.getClient, user.client);

  const { show: showApiKey, toggle } = useShow();

  const { show: showAlert, close: closeAlert, open: openAlert } = useShow();

  const [selectedComponent, setSelectedComponent] = useState('AccountInformation');

  const [mFAValidation, setMFAValidation] = useState(user.useMFA);
  const handleMFAChange = () => {
    Requests.saveUser(user._id, { useMFA: !mFAValidation }).then(() => {
      setMFAValidation(!mFAValidation);
      handleAlert(
        "warning",
        `MFA ${!mFAValidation ? "activated" : "deactivated"}.`
      );
    }).catch(err => handleAlert("error","There was a problem changing the MFA, please try again."));
  };


  const RenderSelectedComponent = () => {
    switch (selectedComponent) {
      case 'AccountInformation':
        return <AccountInformation />;
      case 'EditEmail':
        return <EditEmail />;
      case 'EditCompany':
        return <EditCompany />;
      case 'EditPassword':
        return <EditPassword/>;
      default:
        return null;
    }}

  const RenderApiKey = () => {
    if (showApiKey) {
      if (clientInformation.secretkey) {
        return (
          <span style={{ fontSize: "12px" }} ref={apikey}>
            {clientInformation.secretkey}
          </span>
        );
      } else {
        return <span>**********</span>;
      }
    } else {
      return <b style={{ fontSize: "16px", userSelect: "none" }}>**********</b>;
    }
  };

  const copyApiKey = () => {
    if (apikey.current) {
      navigator.clipboard.writeText(apikey.current.innerText);
      handleAlert("success", "API Key copied to clipboard");
    }
  };

  const regenerateApiKey = async () => {
    try {
      await Requests.generateAPIKey(clientInformation.id);
      reFetch();
    } catch (err) {
      handleAlert(
        "error",
        "There was a problem regenerating your api key, try again"
      );
    }
  };

  const handleToggle = () => {
    if (!clientInformation.secretkey) {
      handleAlert(
        "error",
        "There was a problem retrieving your api key, try again or regenerate it."
      );
    } else {
      toggle();
    }
  };

  useEffect(() => {
    setCurrentClientPage("My Account");
    changeIsInClientsSettings(true);
  }, []);

  const handleAlert = (type, message) => {
    setCurrentAlertState({
      type,
      message,
    });
    openAlert();
  };

  const CustomInput = styled(InputBase)(() => ({
    "& .MuiInputBase-input": {
      marginTop: theme.spacing(3),
      position: "relative",
      borderRadius: "4px",
      backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
      borderBottom: "none",
      border: "1px solid #d3d3d3",
      fontSize: 16,
      padding: "5px 6px",
      height: "36px",
      boxShadow: "inset 0 0 5px rgba(0, 0, 0, 0.1)",
      width: "100%",

      "&:focus": {
        outline: `2px solid ${theme.palette.primary.main}`,
      },
    },
  }));

  const AccountInformation = () => {
    const AntSwitch = styled(Switch)(({ theme }) => ({
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
      '&:active': {
        '& .MuiSwitch-thumb': {
          width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
          transform: 'translateX(9px)',
        },
      },
      '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
          transform: 'translateX(12px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
          },
        },
      },
      '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
          duration: 200,
        }),
      },
      '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
          theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
      },
    }));
    return (
      <Box>
        <Paper sx={{ width: "100%" }}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Account Number</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        navigator.clipboard.writeText(user.client.slice(-6));
                        handleAlert("success", "Full ID copied");
                      }}
                    >
                      #{user.client.slice(-6)}
                      <ContentCopyRoundedIcon style={{ height: "14px" }} />
                    </Button>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Company</TableCell>
                  <TableCell>
                    {loadingClientInformation
                      ? "loading..."
                      : clientInformation.name}
                  </TableCell>
                  <TableCell align="right">
                    {user.client_user_role === "admin" && (
                      <Button
                        sx={{
                          color: theme.palette.blue.main,
                          fontWeight: 700,
                        }}
                        onClick={() => setSelectedComponent("EditCompany")}
                      >
                        change
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>{user.mail}</TableCell>
                  <TableCell align="right">
                    <Button
                      sx={{
                        color: theme.palette.blue.main,
                        fontWeight: 700,
                      }}
                      onClick={() => setSelectedComponent("EditEmail")}
                    >
                      change
                    </Button>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Password</TableCell>
                  <TableCell>**********</TableCell>
                  <TableCell align="right">
                    <Button
                      sx={{
                        color: theme.palette.blue.main,
                        fontWeight: 700,
                      }}
                      onClick={() => setSelectedComponent("EditPassword")}
                    >
                      Change
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>MFA Login</TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>OFF</Typography>
                      <AntSwitch
                        checked={mFAValidation}
                        inputProps={{ "aria-label": "ant design" }}
                        onChange={handleMFAChange}
                      />
                      <Typography>ON</Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Divider />
      </Box>
    );
  };

  const EditEmail = () => {
    const handleSubmit = (e) => {
      e.preventDefault();
      try {
        const Data = new FormData(e.currentTarget);

        const email = Data.get("email");
        const confirmEmail = Data.get("newemail");

        if (!Utils.validateEmail(email)) {
          handleAlert("error", "Please enter a valid email");
          return;
        }

        if (email !== confirmEmail) {
          handleAlert("error", "Emails do not match");
          return;
        } else {
          Requests.saveUser(user.id, { mail: email }).then((res) => {
            if (res.status === 200) {
              handleAlert("success", "Email updated successfully");
              setEditingEmail(false);
              window.location.reload();
            } else {
              handleAlert("error", "Error updating email");
            }
          });
        }
      } catch (error) {}
    };

    return (
      <Box className="emailForm">
        <Button
          onClick={() => setSelectedComponent("AccountInformation")}
          color="blue"
          startIcon={
            <FontAwesomeIcon icon={faBackward} style={{ fontSize: "14px" }} />
          }
        >
          Return to settings
        </Button>

        <form
          style={{
            width: "100%",
            height: "300px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
          onSubmit={handleSubmit}
        >
          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="currentEmail" shrink>
              CURRENT EMAIL
            </InputLabel>
            <Input
              name="currentEmail"
              id="currentEmail"
              value={user.mail}
              onlyread
              disabled
            />
          </FormControl>

          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="email" shrink>
              NEW EMAIL
            </InputLabel>
            <CustomInput name="email" id="email" />
          </FormControl>

          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="newemail" shrink>
              RE-ENTER YOUR NEW EMAIL
            </InputLabel>
            <CustomInput name="newemail" id="newEmail" />
          </FormControl>

          <Button variant="contained" sx={{ width: "150px" }} type="submit">
            Change Mail
          </Button>
        </form>
      </Box>
    );
  };
    
  const EditCompany = () => {
    const [newName,setNewName]=useState("")
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        if (newName.length!=0){
          if (newName===clientInformation.name){
            handleAlert("error", "The new name must be different from the previous one.");  
          }else{
            await Requests.editClient({name:newName})
            setClientInformation((prevClientInfo)=>({...prevClientInfo,  name: newName}))
            handleAlert("success", "Company name updated.");
            setSelectedComponent("AccountInformation")
          }
        }else{
          handleAlert("error", "Please write down the new company name.");
        }
        
      } catch (error) {
        handleAlert("error", "There was an error changing the company name.");
      }
    };

    return (
      <Box
        sx={{
          width: "100%",
          maxWidth: "400px"
        }}
      >
        <Button
          onClick={() => setSelectedComponent("AccountInformation")}
          color="blue"
          startIcon={
            <FontAwesomeIcon icon={faBackward} style={{ fontSize: "14px" }} />
          }
        >
          Return to settings
        </Button>

        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
            height: "300px",
            width: "100%",
            maxWidth: "400px",
            padding:" 24px 10% 0 10%",
            boxSizing: "border-box"
          }}
        >
          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="text" shrink>
              OLD COMPANY NAME
            </InputLabel>
            <Input
              
              id="currentName"
              value={
                loadingClientInformation ? "loading..." : clientInformation.name
              }
              onlyread
              disabled
            />
          </FormControl>
          <FormControl variant="standard" fullWidth>
            <InputLabel htmlFor="text" shrink>
              NEW COMPANY NAME
            </InputLabel>
            <CustomInput name="text" id="text" onChange={(e)=>setNewName(e.target.value)} />
          </FormControl>
          <Button variant="contained" sx={{ width: "250px" }} type="submit">
            Change Company Name
          </Button>
        </form>
      </Box>
    );
  };

  const EditPassword = () => {

  const changePassword = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData(e.currentTarget);
      
      const currentPassword = data.get("currentPassword");
      const password = data.get("password");
      const confirmPassword = data.get("confirmPassword");
      const isCurrentPasswordCorrect = await Requests.validateCurrentPassword(currentPassword);

      if(!isCurrentPasswordCorrect.data) {
        handleAlert("error", "Your current password is not correct");
        return;
      }

      if (password !== confirmPassword) {
        handleAlert("warning", "Passwords do not match");
        return;
      } else {
        const validationMessage = Utils.checkPasswordRestrictions(password,user);
        if(!validationMessage.validation){
          handleAlert("error", validationMessage.message);
          return;
        }else{
          Requests.updatePassword(user.id, {
            password: password,
          }).then((res) => {
            if (res.status === 200) {
              setSelectedComponent("AccountInformation")
            }
          });
        }
        }
      
    } catch (error) {
      handleAlert("error", "There was an error updating the information");
    }
  };
  const [password, setPassword] = useState("");
    return (
      <Box
        sx={{
          width: "100%",
          maxWidth: "400px"
        }}
      >
        <Button
          onClick={() => setSelectedComponent("AccountInformation")}
          color="blue"
          startIcon={
            <FontAwesomeIcon icon={faBackward} style={{ fontSize: "14px" }} />
          }
        >
          Return to settings
        </Button>
      <h1 id="modal-modal-title">Change Password</h1>

      <form onSubmit={(e) => changePassword(e)} className="formModal">
      <FormControl  variant="standard" fullWidth>
          <InputLabel htmlFor="currentPassword" shrink>Current Password</InputLabel>
          <CustomInput
            id="currentPassword"
            name="currentPassword"
            type="password"
            required
            onChange={(e)=>setPassword(e.target.value)}
          />
        </FormControl>
        <FormControl  variant="standard" fullWidth>
          <InputLabel htmlFor="password" shrink>Password</InputLabel>
          <CustomInput
            id="password"
            name="password"
            type="password"
            required
            onChange={(e)=>setPassword(e.target.value)}
          />
        </FormControl>
        <FormControl  variant="standard" fullWidth>
          <InputLabel htmlFor="password" shrink>
            Confirm Password
          </InputLabel>
          <CustomInput
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            required
          />
        </FormControl>
        <Typography variant={"p"} style={{ fontSize: "10px" }}>{Utils.checkPasswordRestrictions(password,user).message}</Typography>
        <div className="buttonsModalContainer">
          <Button type="submit" variant="contained" color="primary">
            Change Password
          </Button>
        </div>
      </form>
    </Box>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CustomSnackBarAlert
        type={currentAlertState.type}
        show={showAlert}
        close={closeAlert}
      >
        {currentAlertState.message}
      </CustomSnackBarAlert>
      <Container sx={{ width: "100%", pt: 3 }}>
        <Typography variant="h1" sx={{ mb: 3 }}>
          My Account
        </Typography>
        <RenderSelectedComponent />
      </Container>
    </ThemeProvider>
  );
}
