import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  TableRow,
  TableCell,
  Button,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  Rating,
  Dialog
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FolderIcon from "@mui/icons-material/Folder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePen } from "@fortawesome/free-solid-svg-icons";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Utils from "../../utils";
import "../../App.css";
import Config from "../../config";
import MoveFilesModal from "./moveFilesModal";
import useShow from "../../hooks/useShow";
import awsUtils from "../../awsUtils";
import { useDrag, useDrop } from "react-dnd";
import Requests from "../../logic/Requests";
import { styled } from '@mui/material/styles';
import CustomShareFileDialog from "../../components/global/CustomShareFileDialog"

import CustomAlertDialogSlide from "../global/CustomAlertDialogSlide"
import { ConsoleView } from "react-device-detect";

const options = ["Details","Share","Download", "Edit Transcript" , "Move", "Rename", "Delete"];



export default function FileManagerRow(props) {
  const {
    user,
    changeAllClientFiles,
    allClientFiles,
    row,
    isItemSelected,
    labelId,
    filesAction,
    changePrefix,
    folderPrefix,
    handleClick,
    itemHeight,
    updateFiles,
    folder,
    handleAlert,
    selected,
    clearSelected,
  } = props;
  const navigate = useNavigate();

  const {
    emptyS3Directory,
    deleteItem,
    moveMyFilesObject,
    moveMyFilesMultipleObject,
  } = awsUtils;

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  const downloadSelectedTranscript = async (transcriptId) => {
    try {
      /* 
        (row.engine !== "Manual" && row.state === "Word Doc Generated") 
        The project is automated and has its document generated 
      */
      if (row.sent !== 0 || (row.engine !== "Manual" && row.state === "Word Doc Generated")) {
        const url = await Requests.getWordTemplate(transcriptId);
        window.location = url;
      } else {
        handleAlert("error", `The transcription of audio "${row.name}" order ${row.project_id.slice(-6)} is still pending`)
      }
    } catch (error) {
      handleAlert(
        "error",
        "There was a problem downloading the transcript doc"
      );
    }
  };

  const { parseSize } = Utils;
  const [anchorEl, setAnchorEl] = useState(null);

  const ItemTypes = {
    item: "file/folder",
  };

  const [, drag, preview] = useDrag(() => ({
    type: ItemTypes.item,
    item: { row },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        try {
          moveMyFilesObject(
            item.row,
            [dropResult.row],
            user,
            changeAllClientFiles,
            updateFiles,
            clearSelected,
            setRowFileData
          )
            .then((res) => {
              if (res.type) {
                handleAlert(res.type, res.message);
              }
            })
            .catch((err) => console.error("error", err));
        } catch (error) {
          console.error(error)
          if (error.type) {
            handleAlert(error.type, error.message);
          } else {
            handleAlert("error", "there was an error trying to move the file.");
          }
        }

      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));
  const [, drop] = useDrop(() => ({
    accept: ItemTypes.item,

    drop: () => ({ row }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));



  const { show: showModal, close: closeModal, open: openModal } = useShow();

  const [openShareDialog,setOpenShareDialog]=useState(false);
  const [url,setUrl]=useState("");
  const [isRenaming, setIsRenaming] = useState(false);

  const [rowFileData, setRowFileData] = useState(allClientFiles?.filter(e => e.path == row.id)[0]);

  const [rating, setRating] = useState(row.client_rate ? row.client_rate : 0);
  const [tempRating, setTempRating] = useState(row.client_rate ? row.client_rate : 0);


  const open = Boolean(anchorEl);

  const openMenu = (event) => setAnchorEl(event.currentTarget);

  const closeMenu = () => setAnchorEl(null);

  const [isLoadingName, setIsLoadingName] = useState(false);

  const convert = (date) => {
    if (date) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      const localizedDate = new Date(date).toLocaleDateString(undefined, options);
      return localizedDate;
    } else {
      return null;
    }
  };

  const [loading, setLoading] = useState(false);

  const RenderperType = (element) =>
    element.isDir ? (
      //<FolderIcon className="filesIcon" />
      <img
        src="icons/thumbnail_blue folder.png"
        alt="new_folder_icon"
        style={{
          maxWidth: '28px', // Add a max-width
          objectFit: 'contain',
          marginRight: "8px"
        }}
      />
    ) : (
      <img
        src={element.engine === "Manual" ? "/icons/HUMAN TRANSCRIPTION LOGO - Wordibly Portal.png" : "/icons/AI LOGO - Wordibly Portal.png"}
        alt={element.engine === "Manual" ? "Human Transcript Icon" : "Automated Transcript Icon"}
        style={{
          maxWidth: "28px", // Add a max-width
          height: "18px", // Set the height
          objectFit: "contain",
          marginRight: "8px",
        }}
      />
    );

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoadingName(true);
    try {
      const data = e.currentTarget;

      const formdata = new FormData(data);

      const name = formdata.get("name");

      const getPureName = rowFileData.filename.split(".")[0];

      const newId = row.id.replace(getPureName, name);

      if (name === getPureName) {
        handleAlert("warning", "The name is the same");
        setIsLoadingName(false);
        return;
      }

      Requests.changeUserFolderOrFiles(user.id, { path: rowFileData.path, filename: name }).then(
        fileUpdated => {
          Requests.getUserFolders(user.id).then((res) => {
            changeAllClientFiles(res.data);
            setRowFileData(res.data.filter(e => e.path == fileUpdated.data)[0]);
            setIsRenaming(false);
            clearSelected();
            setIsLoadingName(false);
          })
        })
    } catch (e) {
      handleAlert("error", "There was an error renaming the item");
      setIsRenaming(false);
    }
  };

  const actions = (option, key, transcript_id) => {

    if (option === "Details") {
      navigate(`/orderhistory/orderDetails/${row.project_id}`);
      closeMenu();
    }

    if (option === "Download") {
      downloadSelectedTranscript(transcript_id)
      closeMenu();
    }

    if (option === "Edit Transcript") {
      // here check if the transcript is completed
      if (row.isDir) {
        handleAlert("error", `Can not open a folder in transcript editor`)
      } else {
        if (row.state == "Word Doc Generated") {
          navigate(`/transcript/${row.transcript_id}`)
        } else {
          handleAlert("error", `The transcription of audio "${row.name}" order ${row.project_id.slice(-6)} is still pending`)
        }
      }
    }

    if (option === "Rename") {
      setIsRenaming(true);
      closeMenu();
    }

    if (option === "Delete") {
      setIsDeleteDialogOpen(true)
    }

    if (option === "Move") {
      closeMenu();
      openModal();
    }
    
    if (option === "Share"){
      closeMenu();
      handleShare(transcript_id).then(url=> {
        setUrl(url);
        setOpenShareDialog(true);
      })
    }
  };
  const RenameInput = () => {

    useEffect(() => {

      const input = document.getElementById("renameInput");
      if (input) {
        input.focus();
        input.select();
      }
    }, []);
    return (
      <div style={{ display: "flex" }}>
        <form
          onSubmit={(e) => handleSubmit(e)}
          style={{ pointersEvents: "none", marginRight: "10px" }}
        >
          <input
            style={{ pointersEvents: "none" }}
            defaultValue={rowFileData?.filename.split(".")[0]}
            className="renameInput"
            id="renameInput"
            onBlur={() => setIsRenaming(false)}
            name="name"
            disabled={isLoadingName}
          ></input>
        </form>
        {isLoadingName ? <CircularProgress size={20} /> : null}
      </div>
    );
  };

  const confirmDeletion = () => {
    if (row.isDir) {
      emptyS3Directory(Config.awsBucketName, row.id).then(() => {
        updateFiles();
      });
    } else {
      deleteItem(Config.awsBucketName, row.id).then(() => {
        updateFiles();
      });
    }
    closeMenu();
  }

  const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#66BB6A',
    },
    '& .MuiRating-iconHover': {
      color: '#66BB6A',
    },
  });

  // handle rating
  const [openDialog, setOpenDialog] = useState(false)
  const [feedbackText, setFeedbackText] = useState("")

  const handleRating = (event, value) => {
    if (row.state === "Word Doc Generated") {
      if (row.client_user_feedback === user.id || !row.client_user_feedback) {
        setOpenDialog(true);
        setTempRating(value);
      } else {
        handleAlert("error", "Only the initial feedback provider user can overwrite the transcript feedback.");
      }
    } else {
      handleAlert("error", "Transcript is not completed, can not be rated.");
    }

  }
const handleSubmitRating=(filePath)=>{
  setRating(tempRating);
  const rate = {score: tempRating, feedback: feedbackText}
  Requests.submitRating(filePath,rate);
 
  handleAlert("success", "Your feedback is greatly appreciated and propels our drive for improvement here at FocusForward. :)");
  setOpenDialog(false);
}

const handleShare = async (transcript_id) => {

  try {
    const transcriptId = transcript_id;
    // const selected = dataOrderDetails.files.filter(s=> s.transcriptId == transcriptId)[0] ;
    if(row.state == "Word Doc Generated" ){
      const resUrl = await Requests.getWordTemplate(transcriptId);
      return resUrl

    }else{
      // handleAlert("error",`The transcription of audio "${selected.name}" order ${idProject.slice(-6)} is still pending`)
    }
  } catch (error) {
    handleAlert(
      "error",
      "There was a problem downloading the transcript doc"
    );
  }
}

  return (
    <>
      <TableRow
        hover
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        id={row.id}
        selected={isItemSelected}
        sx={{ cursor: "pointer" }}
        className="parent"
      >
        <MoveFilesModal
          folder={folder}
          show={showModal}
          close={closeModal}
          folderPrefix={folderPrefix}
          currentFolder={folderPrefix[folderPrefix.length - 1]}
          row={row}
          updateFiles={updateFiles}
          handleAlert={handleAlert}
          clearSelected={clearSelected}
          setRowFileData={setRowFileData}
        />
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            onClick={(event) => handleClick(event, row)}
            inputProps={{
              "aria-labelledby": labelId,
            }}
            className="checkBox"
            id={`check${labelId}`}
            sx={isItemSelected ? { display: "block !important" } : {}}
          />
        </TableCell>
        <TableCell
          component="th"
          id={labelId}
          scope="row"
          padding="none"
          onClick={isRenaming ? null : (row.isDir ? () => changePrefix(folderPrefix, row) : () => downloadSelectedTranscript(row.transcript_id))}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            ref={row.isDir ? (node) => drag(drop(node)) : drag}
            data-testid={`box`}
          >
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              <>
                {RenderperType(row)}
                {isRenaming ? (
                  <RenameInput name={rowFileData?.filename ?? row.name} />
                ) : (
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "280px",
                      textTransform: "capitalize"
                    }}
                  >
                    {rowFileData?.filename ?? row.name}
                  </span>
                )}
              </>
            )}
          </div>
        </TableCell>
        <TableCell
          align="right"
          onClick={() => {
            changePrefix(folderPrefix, row);
          }}
        >
          {row.size ? parseSize(row.size) : ""}
        </TableCell>
        <TableCell
          align="right"
          onClick={() => {
            changePrefix(folderPrefix, row);
          }}
        >
          {convert(row.updated_at)}
        </TableCell>
        <TableCell>
          {row.isDir ? null : (
            <>
              <StyledRating value={rating} onChange={handleRating} size="small" />
              <CustomAlertDialogSlide
                open={openDialog}

                setOpen={setOpenDialog}
                title={""}
                textInput={true}
                message={
                  "Thanks for rating! Wordibly's Quality Team will review the file and offer to re-start any files below our standards."
                }
                setText={setFeedbackText}
                BtnCloseText={"CANCEL"}
                BtnAgreeText={
                  <Button style={{ backgroundColor: "#1a336e", color: "white" }} onClick={() => { handleSubmitRating(row.id) }}>
                    SUBMIT
                  </Button>
                }
              />
            </>
          )}
        </TableCell>
        {
        /* 
          (row.engine !== "Manual" && row.state === "Word Doc Generated") 
          The project is automated and has its document generated 
        */
        }
        <TableCell>{row.isDir ? null : (row.sent !== 0 || (row.engine !== "Manual" && row.state === "Word Doc Generated")? <> Completed </> : <>Pending</>)}</TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={openMenu}
            sx={
              selected.length > 0
                ? { color: "transparent !important", pointerEvents: "none" }
                : null
            }
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={closeMenu}
            PaperProps={{
              style: {
                maxHeight: itemHeight * 4.5,
                width: "20ch",
              },
            }}
          >
            {options.map((option) => {
              if (row.isDir && option === "Download") {
                return null;
              } else {
                return (
                  <MenuItem key={option} onClick={() => actions(option, row.id, row.transcript_id)}>
                    {option}
                  </MenuItem>
                );
              }
            })}
            <CustomAlertDialogSlide
              open={isDeleteDialogOpen}
              setOpen={setIsDeleteDialogOpen}
              title={""}
              textInput={false}
              message={
                "Are you sure you want to permanently delete this file/folder?"
              }
              BtnCloseText={"CANCEL"}
              BtnAgreeText={
                <Button color="error" variant="contained" onClick={confirmDeletion}>
                  Delete
                </Button>
              }
            />
          </Menu>
        </TableCell>
      { openShareDialog && <CustomShareFileDialog
        open={openShareDialog}
        setOpen={setOpenShareDialog}
        url={url}
        transcriptId={row.transcript_id}
      />}
      </TableRow>
    </>
  );
}
