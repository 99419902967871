import { Modal, Box, ThemeProvider, Button } from "@mui/material";
import { useState } from "react";
import "../../../App.css";
import theme from "../../../theme";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import Requests from "../../../logic/Requests";
import Config from "../../../config";

export default function ReassignUnitModal(props) {
  const { show, close, dataUnit, handleAlert } = props;

  const [reassignReasons, setReassignReasons] = useState([
    { text: "Late Assignment", checked: false },
    { text: "Confirmed and Did Not Complete", checked: false },
    { text: "No Action", checked: false },
    { text: "Sloppy Verbatim", checked: false },
    { text: "Did Not Revise", checked: false },
    { text: "Failed to follow instructions", checked: false },
    { text: "Submitted partial labeled as complete?", checked: false },
    { text: "Wrong format?", checked: false },
    { text: "Speech attribution wrong?", checked: false },
    { text: "Sparse Notes", checked: false },
    { text: "Terminology Super-Star", checked: false },
    { text: "Reliable", checked: false },
    { text: "Same-Day Savior", checked: false },
    { text: "Overnight Hero", checked: false },
    { text: "Format Specialist", checked: false },
    { text: "Quick and Accurate", checked: false },
    { text: "Very detailed note-taking", checked: false },
  ]);

  const [unitTime, setUnitTime] = useState(null);
  const [grade, setGrade] = useState(null);
  const [payAdjust, setPayAdjust] = useState(null);
  const [firstStep, setFirstStep] = useState(true);
  const [allTranscribers, setAllTranscribers] = useState(false);
  const [comments, setComments] = useState("");
  const [transcriber, setTranscriber] = useState(null);

  const handleTranscriber = (value) => {
    setTranscriber(value);
  };

  const handleComments = (value) => {
    setComments(value);
  };

  const handleAllTranscribers = () => {
    setAllTranscribers(!allTranscribers);
  };

  const handleStep = () => {
    setFirstStep(!firstStep);
  };

  const handleCheckboxChange = (id) => {
    setReassignReasons((prevReasons) => {
      return prevReasons.map((reason) => {
        if (reason.text === id) {
          return { ...reason, checked: !reason.checked };
        } else {
          return reason;
        }
      });
    });
  };

  const handleUniTime = (value) => {
    setUnitTime(value);
  };

  const handleGrade = (value) => {
    setGrade(value);
  };

  const handlePayAdjust = (value) => {
    setPayAdjust(value);
  };

  const clearState = () => {
    setReassignReasons([
      { text: "Late Assignment", checked: false },
      { text: "Confirmed and Did Not Complete", checked: false },
      { text: "No Action", checked: false },
      { text: "Sloppy Verbatim", checked: false },
      { text: "Did Not Revise", checked: false },
      { text: "Failed to follow instructions", checked: false },
      { text: "Submitted partial labeled as complete?", checked: false },
      { text: "Wrong format?", checked: false },
      { text: "Speech attribution wrong?", checked: false },
      { text: "Sparse Notes", checked: false },
      { text: "Terminology Super-Star", checked: false },
      { text: "Reliable", checked: false },
      { text: "Same-Day Savior", checked: false },
      { text: "Overnight Hero", checked: false },
      { text: "Format Specialist", checked: false },
      { text: "Quick and Accurate", checked: false },
      { text: "Very detailed note-taking", checked: false },
    ]);
    setUnitTime(null);
    setGrade(null);
    setPayAdjust(0);
    setFirstStep(true);
  };

  const reassign = () => {
    try {
      const doc = {};
      doc.length = unitTime;
      doc.grade = grade;
      doc.pay_adjust = payAdjust;
      doc.assignment = dataUnit.assignment._id;
      doc.reassign_notes = comments ? comments : undefined;
      doc.score_card = reassignReasons.filter((reason) => reason.checked);
      doc.user_id = dataUnit.assignment.assigned_to._id;
      doc.new_user_id = transcriber._id;

      Requests.reassign(dataUnit._id, doc).then(() => {
        clearState();
        close();
        handleAlert("success", "Unit reassigned successfully");
      });
    } catch (err) {
      handleAlert("error", "Error reassigning unit");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={show}
        onClose={() => {
          clearState();
          close();
        }}
      >
        <Box className="reassingModal">
          <div
            style={{ maxHeight: "500px", overflow: "auto", padding: "20px" }}
          >
            {firstStep ? (
              <FirstStep
                unitTime={unitTime}
                reassignReasons={reassignReasons}
                handleUniTime={handleUniTime}
                handleGrade={handleGrade}
                handlePayAdjust={handlePayAdjust}
                handleCheckboxChange={handleCheckboxChange}
                grade={grade}
                payAdjust={payAdjust}
                comments={comments}
                handleComments={handleComments}
              />
            ) : (
              <SecondStep
                allTranscribers={allTranscribers}
                handleAllTranscribers={handleAllTranscribers}
                dataUnit={dataUnit}
                handleCheckboxChange={handleCheckboxChange}
                reassignReasons={reassignReasons}
                handleStep={handleStep}
                handleTranscriber={handleTranscriber}
                transcriber={transcriber}
              />
            )}
          </div>
          <div className="reasingnModalButtonsContainer">
            <Button
              variant="contained"
              sx={{ mr: 2, color: "white !important" }}
              color="red"
              onClick={() => {
                clearState();
                close();
              }}
            >
              Cancel
            </Button>
            {firstStep ? (
              <Button variant="contained" onClick={handleStep}>
                Next
              </Button>
            ) : (
              <Button
                variant="contained"
                color="fourth"
                sx={{ color: "white !important" }}
                onClick={reassign}
              >
                Reassign
              </Button>
            )}
          </div>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}
