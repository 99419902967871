// Import dependencies and components
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Box,
  CircularProgress,
  Paper,
  TableContainer,
  TableSortLabel,
  Container,
  Typography,
} from "@mui/material";
import { useState, useRef } from "react";
import TranscriberHubRow from "./transcriberHubRow";
import MediaModal from "../mediaModal";
import moment from "moment";
import theme from "../../theme";
import Utils from "../../utils";

// Define styles for the table header rows
const headRowStyle = {
  backgroundColor: theme.palette.white.main,
  color: "#808080",
  fontSize: 18,
  py: 1,
  fontWeight: 520,
  padding: 0,
  paddingLeft: "6px",
  borderBottom: `2px solid ${theme.palette.primary.main}`,
};

// Export the component
export default function TranscriberHub(props) {
  const {
    user,
    showMedia,
    showMediaModal,
    unitToShow,
    mediaType,
    isLoading,
    sortedUnits,
    transcriberHasUnit,
    handleChangeTranscriberState,
    changeUnitState,
    openUnitError,
    setSortedUnits,
    idUser,
    location,
  } = props;
  const projectLocation = location.pathname.split("/")[3];
  const [sortOrder, setSortOrder] = useState("desc");
  const mediaModalRef = useRef(null);
  const HeaderList =
    user.rolename == "Transcriber"
      ? [
          {
            id: "",
            label: "Unit ID",
          },
          {
            id: "transcript_transcriber_rate",
            label: "Pay/Min",
          },
          {
            id: "unit_length",
            label: "Length",
          },
          {
            id: "total_rate",
            label: "Total Pay",
          },
          {
            id: "language",
            label: "Language",
          },
          {
            id: "timestamps",
            label: "Time Stamping",
          },
          {
            id: "assignment_due",
            label: "Due",
          },
          {
            id: "speakers",
            label: "Speakers",
          },
          {
            id: "text_format",
            label: "Format",
          },
          {
            id: "",
            label: "Preview",
            style: {
              justifyContent: "center",
              paddingRight: "18px",
            },
          },
          {
            id: "rejected_count",
            label: "Unclaimed",
          },
          {
            id: "",
            label: "Make My Job",
            style: {
              marginRight: "0",
              justifyContent: "center",
            },
          },
        ]
      : user.rolename == "QA" && projectLocation == "transcript"
      ? [
        {
          id: "transcript",
          label: "Transcript ID",
        },
        {
          id: "unit_length",
          label: "Length",
        },
        {
          id: "language",
          label: "Language",
        },
        {
          id: "timestamps",
          label: "Time Stamping",
        },
        {
          id: "assignment_due",
          label: "Due",
        },
        {
          id: "speakers",
          label: "Speakers",
        },
        {
          id: "text_format",
          label: "Format",
        },
        {
          id: "",
          label: "Preview",
          style: {
            justifyContent: "center",
            paddingRight: "18px",
          },
        },
        {
          id: "",
          label: "Status",
          style: {
            justifyContent: "center",
            paddingRight: "18px",
          },
        },
        {
          id: "",
          label: "TPS number",
          style: {
            marginRight: "0",
            justifyContent: "center",
          },
        },
        {
          id: "",
          label: "AI",
          style: {
            marginRight: "0",
            justifyContent: "center",
          },
        },
        {
          id: "",
          label: "All in?",
          style: {
            marginRight: "0",
            justifyContent: "center",
          },
        },
        {
          label: "Make My Job",
          style: {
            marginRight: "0",
            justifyContent: "center",
          },
        },
      ]
      : [
          {
            id: "",
            label: "Unit ID",
          },
          {
            id: "unit_length",
            label: "Length",
          },
          {
            id: "language",
            label: "Language",
          },
          {
            id: "timestamps",
            label: "Time Stamping",
          },
          {
            id: "assignment_due",
            label: "Due",
          },
          {
            id: "speakers",
            label: "Speakers",
          },
          {
            id: "text_format",
            label: "Format",
          },
          {
            id: "",
            label: "Preview",
            style: {
              justifyContent: "center",
              paddingRight: "18px",
            },
          },
          {
            id: "rejected_count",
            label: "Unclaimed",
          },
          {
            id: "",
            label: "TPS number",
            style: {
              marginRight: "0",
              justifyContent: "center",
            },
          },
          {
            id: "",
            label: "AI",
            style: {
              marginRight: "0",
              justifyContent: "center",
            },
          },
          {
            id: "",
            label: "Extra proofing",
            style: {
              marginRight: "0",
              justifyContent: "center",
            },
          },
          {
            label: "Make My Job",
            style: {
              marginRight: "0",
              justifyContent: "center",
            },
          },
        ];
  const sortByDates = (order, column) => {
    const unitsWithMomentDates = sortedUnits.map((unit) => ({
      ...unit,
      [column]: moment.utc(unit[column], "YYYY-MM-DD HH:mm"),
    }));
    if (order === "asc") {
      unitsWithMomentDates.sort((a, b) => {
        const diffA = a[column].diff(moment(), "milliseconds");
        const diffB = b[column].diff(moment(), "milliseconds");
        return diffA - diffB;
      });
    } else {
      unitsWithMomentDates.sort((a, b) => {
        const diffA = a[column].diff(moment(), "milliseconds");
        const diffB = b[column].diff(moment(), "milliseconds");
        return diffB - diffA;
      });
    }
    setSortedUnits(unitsWithMomentDates);
  };

  const sortByNumber = (order, column) => {
    const newSortedarray = [...sortedUnits];
    if (order === "asc") newSortedarray.sort((a, b) => a[column] - b[column]);
    else newSortedarray.sort((a, b) => b[column] - a[column]);
    setSortedUnits(newSortedarray);
  };

  const calculateTranscriberPayRate = (unit, transform = true) => {
    const {
      transcript_transcriber_rate,
      project_transcriber_rate,
      assignment_transcriber_rate,
    } = unit;
    let payRate;
    if (assignment_transcriber_rate && assignment_transcriber_rate > 0) {
      payRate = assignment_transcriber_rate;
    } else if (transcript_transcriber_rate && transcript_transcriber_rate > 0) {
      payRate = transcript_transcriber_rate;
    } else if (project_transcriber_rate && project_transcriber_rate > 0) {
      payRate = project_transcriber_rate;
    } else {
      payRate = user.rate ? user.rate.amount : 0;
    }
    if (transform) {
      Utils.transformPayRate(payRate, unit.unit_length);
      return Number(Utils.transformPayRate(payRate, unit.unit_length).slice(1));
    } else {
      return "$" + payRate.toFixed(2);
    }
  };

  const sortByToTalRate = (order) => {
    const newSortedarray = [...sortedUnits];
    if (order === "asc")
      newSortedarray.sort(
        (a, b) =>
          calculateTranscriberPayRate(a) - calculateTranscriberPayRate(b)
      );
    else
      newSortedarray.sort(
        (a, b) =>
          calculateTranscriberPayRate(b) - calculateTranscriberPayRate(a)
      );
    setSortedUnits(newSortedarray);
  };

  const sortAlphabetically = (order, column) => {
    const newSortedArray = [...sortedUnits];
    if (order === "asc")
      newSortedArray.sort((a, b) => {
        if (a[column] === null) {
          a[column] = "";
        }
        if (b[column] === null) {
          b[column] = "";
        }
        if (a[column] < b[column]) return -1;
        if (a[column] > b[column]) return 1;
        return 0;
      });
    else
      newSortedArray.sort((a, b) => {
        if (a[column] === null) {
          a[column] = "";
        }
        if (b[column] === null) {
          b[column] = "";
        }
        if (a[column] < b[column]) return 1;
        if (a[column] > b[column]) return -1;
        return 0;
      });
    setSortedUnits(newSortedArray);
  };

  const sortByUnitName = (order, column) => {
    if (
      user.mail === "juan.castellon@ultertechnologies.com" ||
      user.mail === "focusfwd.assignments@gmail.com"
    ) {
      const filteredArray = sortedUnits.map((unit) => ({
        ...unit,
        unit_name: unit._id.slice(-8),
      }));
      if (order === "asc")
        filteredArray.sort((a, b) =>
          a[column].localeCompare(b[column], undefined, { numeric: true })
        );
      else
        filteredArray.sort((a, b) =>
          b[column].localeCompare(a[column], undefined, { numeric: true })
        );
      setSortedUnits(filteredArray);
    } else {
      sortAlphabetically(order, column);
    }
  };

  const sortByUnclaimed = (order) => {
    const newSortedArray = [...sortedUnits];
    if (order === "asc")
      newSortedArray.sort((a, b) => a.rejected_count - b.rejected_count);
    else newSortedArray.sort((a, b) => b.rejected_count - a.rejected_count);
    setSortedUnits(newSortedArray);
  };

  const handleSort = (column) => {
    const sortMethodDictionary = {
      assignment_due: () => sortByDates(sortOrder, column),
      transcript_transcriber_rate: () => sortByNumber(sortOrder, column),
      unit_length: () => sortByNumber(sortOrder, column),
      total_rate: () => sortByToTalRate(sortOrder),
      language: () => sortAlphabetically(sortOrder, column),
      speakers: () => sortByNumber(sortOrder, column),
      text_format: () => sortAlphabetically(sortOrder, column),
      rejected_count: () => sortByUnclaimed(sortOrder),
      timestamps: () => sortAlphabetically(sortOrder, column),
      unit_name: () => sortByUnitName(sortOrder, column),
    };
    sortMethodDictionary[column]();
    setSortOrder(sortOrder === "desc" ? "asc" : "desc");
  };

  const shouldRenderTranscriberHubRow = (row, idUser) => {
    if (location.pathname.split("/")[1] === "mywork") {
      return row.assigned_to === idUser || row.reviewed_by == idUser;
    } else if (
      location.pathname.split("/")[1] === "projects" &&
      row.assigned_to === idUser
    ) {
      return false;
    }
    return true;
  };

  const finish = () => {
    showMediaModal("", "", true);
  };

  return !isLoading && sortedUnits.length > 0 ? (
    <Box sx={{ width: "100%" }} className="transcriberQueue">
      <MediaModal
        showMedia={showMedia}
        finish={finish}
        mediaUrl={unitToShow}
        mediaType={mediaType}
        mediaModalRef={mediaModalRef}
      />
      <TableContainer
        component={Paper}
        sx={{
          overflowX: "auto",
          WebkitOverflowScrolling: "touch",
          scrollbarWidth: "thin",
          "&::-webkit-scrollbar": {
            height: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            borderRadius: "8px",
            border: "2px solid transparent",
          },
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {HeaderList.map((row) => (
                <TableCell
                  sx={headRowStyle}
                  style={{ minWidth: 80, ...row.style }}
                  key={row.id}
                >
                  {row.id === "" ? (
                    <p
                      style={{
                        whiteSpace: "nowrap",
                        display: "flex",
                        justifyContent: "start",
                        alignContent: "center",
                        textAlign: "center",
                        ...row.style,
                      }}
                    >
                      {row.label}
                    </p>
                  ) : (
                    <TableSortLabel
                      sx={{
                        whiteSpace: "nowrap",
                        display: "flex",
                        justifyContent: "start",
                        alignContent: "center",
                        textAlign: "center",
                        ...row.style,
                      }}
                      direction={sortOrder}
                      onClick={() => handleSort(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: "red" }}>
            {sortedUnits.map((row) =>
              shouldRenderTranscriberHubRow(row, idUser) ? (
                <TranscriberHubRow
                  row={row}
                  key={row.unit_id}
                  transcriberHasUnit={transcriberHasUnit}
                  changeUnitState={changeUnitState}
                  handleChangeTranscriberState={handleChangeTranscriberState}
                  openUnitError={openUnitError}
                  unitToShow={unitToShow}
                  showMediaModal={showMediaModal}
                  location={location}
                  role={user.rolename}
                />
              ) : null
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  ) : !isLoading && sortedUnits.length === 0 ? (
    <>
      <p style={{ fontSize: "30px" }}>
        There are no jobs available, please come back later
      </p>
    </>
  ) : (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color="success" sx={{ zIndex: 100 }} />
    </Box>
  );
}
