import { React, useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import {themeWordibly as theme }  from "../theme";
import { faBackward } from "@fortawesome/free-solid-svg-icons";

import {
  Typography,
  Button,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  styled,
  FormLabel,
  TextField,
  RadioGroup,
  FormControlLabel,
  InputBase,
  FormControl,
  InputLabel,
  Input,
  Grid,
  Box,
  Card,
  IconButton,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Radio from "@mui/material/Radio";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SquarePayment from "../components/SquarePayment";
import Requests from "../logic/Requests";
import useZustant from "../hooks/useZustant";
import { useNavigate } from "react-router-dom";
import useShow from "../hooks/useShow";
import CustomSnackBarAlert from "../components/global/CustomSnackBarAlert";

export default function PaymentDetails() {
  const { user, clientPrepaidBalance, changeClientPrepaidBalance, setPaymentLink } =
    useZustant();
  useEffect(() => {
    document.title = "Payment Details - Wordibly";
  }, []);
  const navigate = useNavigate();
  const inputStyle = {
    marginTop: theme.spacing(3),
    position: "relative",
    borderRadius: "4px",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    borderBottom: "none",
    border: "1px solid #D3D3D3",
    fontSize: 18,
    padding: "5px 6px",
    height: "40px",

    "&::before": {
      display: "none",
    },
  };

  const handleSuccessPayment = (client, amountPaid) => {
    handleAlert("success","Prepaid Added")
    navigate("/transactionhistory");
  };
  const { show: showAlert, close: closeAlert, open: openAlert } = useShow();
  const [currentAlertState, setCurrentAlertState] = useState({
    type: "error",
    message: "",
  });
  const handleAlert = (type, message) => {
    setCurrentAlertState({
      type,
      message,
    });
    openAlert();
  };



  const [selectedComponent, setSelectedComponent] = useState("Main");


  const navigationTitles = {
    Main: "Payment Details",
    AddMore: "Add Prepaid Balance",
  };

  const RenderSelectedComponent = () => {
    switch (selectedComponent) {
      case "Main":
        return <PaymentDetailsDisplay />;
      case "AddMore":
        return <AddMore />;

      default:
        return null;
    }
  };

  const PaymentDetailsDisplay = () => {
    return (
      <Box sx={{ mt: 3 }}>
        <Box>
          Your users will be allowed to choose between the payment methods
          below.
        </Box>
        <Grid
          item
          container
          spacing={2}
          sx={{
            flexGrow: 1,
            mt: 1,
            p: 2,
            borderBottom: 1,
            borderColor: "secondary.main",
          }}
        >
          <Grid item xs={6} md={8}>
            <Typography variant="h3">Prepay Balance</Typography>
          </Grid>
          <Grid
            item
            style={{ display: "flex", justifyContent: "end" }}
            xs={6}
            md={4}
          >
            {user.client_user_role === "admin" && <Button
              sx={{
                color: theme.palette.blue.main,
                fontWeight: 700,
              }}
              onClick={() => setSelectedComponent("AddMore")}
            >
              ADD MORE
            </Button>}
          </Grid>
          <Grid item xs={6} md={4}>
            <Box>Balance Remaining</Box>
          </Grid>
          <Grid item xs={6} md={8}>
            <Box>
              $ {clientPrepaidBalance != null ? clientPrepaidBalance.toFixed(2) : "loading..."}
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          container
          spacing={2}
          sx={{
            flexGrow: 1,
            mt: 1,
            p: 2,
            borderBottom: 1,
            borderColor: "secondary.main",
          }}
        >
          <Grid item xs={6} md={8}>
            <Typography variant="h3">Invoicing</Typography>
          </Grid>
          <Grid
            item
            style={{ display: "flex", justifyContent: "end" }}
            xs={6}
            md={4}
          >
            <Button
              sx={{
                color: theme.palette.blue.main,
                fontWeight: 700,
              }}
              onClick={()=>handleAlert("warning", "Feature coming soon!")}
            >
              APPLY NOW
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Box>
              Receive one invoice at the end of each billing period for
              educational institutions, government institutions, and businesses.
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const AddMore = () => {
    const [amount, setAmount] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("squareQuickPayCheckout");
    const [loading, setLoading] = useState(false);
    const [canPay,setCanPay] = useState(false);
    const handleRadioChange = (event) => {
      setPaymentMethod(event.target.value);
    };
    const handleSubmit = () => {
      setLoading(true);
      const paymentDetails = {amount,currency: "USD"};
      switch (paymentMethod) {
        case "squareQuickPayCheckout":
          Requests.squareQuickPayCheckout(paymentDetails, "addPrepay").then(
            (res) => {
              setTimeout(() => {
                setPaymentLink(res.data.link);
                navigate("/paymentCheckout");
              }, 500);
            }
          );
          return true;
        case "PayPal":
          return null;
        default:
          return null;
      } 
    };
    const handleAmountType = (event) => {
      setAmount(event.target.value);
    };
    useEffect(() => {
      if (amount > 0) {
        setCanPay(true);
      } else {
        setCanPay(false);
      }
    }, [amount]);
    return (
      <>
        <Button
          onClick={() => setSelectedComponent("Main")}
          color="blue"
          startIcon={
            <FontAwesomeIcon icon={faBackward} style={{ fontSize: "14px" }} />
          }
        >
          Return to settings
        </Button>
       { user.client_user_role === "admin" ? <Box
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            maxWidth: "400px",
          }}
        >
          <Box>
            <Box
              sx={{
                mt: 2,
              }}
            >
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink>AMOUNT</InputLabel>
                <InputBase
                  type="number"
                  sx={inputStyle}
                  id="amount"
                  name="amount"
                  value={amount}
                  onChange={handleAmountType}
                  placeholder="$ Enter an amount"
                />
                <Typography variant="p" sx={{ color: "#999", mt: 1 }}>
                  Current balance is ${" "}
                  {clientPrepaidBalance != null ? clientPrepaidBalance.toFixed(2) : "loading..."}
                </Typography>
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              mt: 2,
            }}
          >
            {/* <Typography variant="h3">Payment Method</Typography> */}
            <FormControl style={{ width: "100%", marginTop: "12px" }}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="squareQuickPayCheckout"
                value={paymentMethod}
                name="radio-buttons-group"
                onChange={handleRadioChange}
              >
                {/* <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label={
                    <Typography variant="p">
                      Pay with saved Visa ending in 6702
                    </Typography>
                  }
                /> */}
                {/* <FormControlLabel
                  value="squareQuickPayCheckout"
                  control={<Radio />}
                  label={
                    <Typography variant="p" style={{ whiteSpace: "nowrap" }}>
                      Use Credit card{" "}
                      <img
                        style={{ height: "24px" }}
                        src={"/cardsLogos/Accepted-Cards-US.png"}
                        alt="(Visa, Mastercard, American Express, Discover, JCB, or UnionPay International )"
                      />
                    </Typography>
                  }
                /> */}
                {/* <Box
                  style={{ display: paymentMethod === "2" ? "block" : "none" }}
                >
                  <SquarePayment
                    style={{ padding: "24px" }}
                    amount={amount}
                    currency={"USD"}
                    afterPayment={() =>
                      handleSuccessPayment(user.client, amount,"square")
                    }
                    typeOfPay={"addPrepay"}
                  />
                </Box> */}
                {/* <FormControlLabel
                  value="PayPal"
                  control={<Radio />}
                  label={<img src={"/cardsLogos/cc-paypal.svg"} alt="PayPal" />}
                /> */}
                {/* <FormControlLabel
                  value="squareQuickPayCheckout"
                  control={<Radio />}
                  label={"squareQuickPayCheckout"}
                /> */}
                {paymentMethod !== "2" && (
                  <LoadingButton
                    sx={{ mt: 1, mx: 4 }}
                    type="submit"
                    color="primary"
                    variant="contained"
                    //style={{backgroundColor: canPay?"#1A336E" : "#ccc" , cursor: !canPay && "not-allowed",  color:  "white",  fontWeight: 700 , border:"none"}}
                    onClick={handleSubmit}
                    loading={loading}
                  >
                    ADD TO BALANCE
                  </LoadingButton>
                )}
              </RadioGroup>
            </FormControl>
          </Box>
        </Box> : <Typography>Only Account owners can add prepaid balance.</Typography>} 
      </>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CustomSnackBarAlert
        type={currentAlertState.type}
        show={showAlert}
        close={closeAlert}
      >
        {currentAlertState.message}
      </CustomSnackBarAlert>
      <Box sx={{ mt: 3, mx: { xs: 2, md: 10 } }}>
        <Box component="main">
          <h1>
            {navigationTitles[selectedComponent]
              ? navigationTitles[selectedComponent]
              : "no title yet:" + selectedComponent}
          </h1>
        </Box>
        <RenderSelectedComponent />
      </Box>
    </ThemeProvider>
  );
}
