import { useNavigate } from "react-router-dom";
import {themeWordibly as theme } from "../../theme";
import {
  useMediaQuery,
  MenuItem,
} from "@mui/material";

const RenderMenuItems = (props) => {
  const { user, handleClose } = props;
  const navigate = useNavigate();
  const isLargeScreen = !useMediaQuery("(max-width:1035px)");
  const adminPmPages = [
    { name: "Dashboard", path: "/dashboard" },
    { name: "Projects", path: "/projects" },
    { name: "People", path: "/people" },
    { name: "Clients", path: "/clients" },
    { name: "Reports", path: "/reports" },
    { name: "File Management", path: "/filemanagement" },
    { name: "Knowledge Center", path: "/knowledge" },
  ];

  const clientPages = [
    { name: "My Words", path: "/filemanagement" },
    //{ name: "Search Files", path: "/searchFiles" },
    { name: "Invite Teammates", path: "/inviteTeammates" },
    { name: "Order History", path: "/orderHistory" },
    { name: "Transaction History", path: "/transactionHistory" },
    { name: "My Account", path: "/myaccount" },
  ];

  const transcriberPages = [
    { name: "My Work", path: `/mywork/${user._id}` },
    { name: "Find Work", path: `/projects/queue/${user._id}` },
    { name: "Knowledge Center", path: "/knowledge" },
    { name: "Settings", path: `/profile/${user._id}` },
  ];

  const QAPages = [
    { name: "My Work", path: `/mywork/${user._id}` },
    { name: "Find Work", path: `/projects/queue/${user._id}` },
    { name: "Ready To Combine", path: `/readyToCombine/` },
    { name: "Knowledge Center", path: "/knowledge" },
    { name: "Settings", path: `/profile/${user._id}` },
  ];

  let pages;

  if (user.rolename === "Admin" || user.rolename === "PM") {
    pages = adminPmPages;
  } else if (user.rolename === "Client") {
    pages = clientPages;
  } else if (user.rolename === "Transcriber") {
    pages = transcriberPages;
  } else if (user.rolename === "QA") {
    pages = QAPages;
  }

  return pages.map((page,i) => {
    return (
      <MenuItem
        className={"BarOptionResp"}
        // style={{display:"flex",flexDirection:"column"}}
        key={i}
        onClick={() => {
          navigate(page.path);
          handleClose();
        }}
        sx={{
          color: isLargeScreen
            ? theme.palette.black.main
            : theme.palette.white.main,
        }}
      >
        {page.name}
      </MenuItem>
    );
  });
};

export default RenderMenuItems;
