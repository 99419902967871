import { React, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { themeWordibly as theme } from "../theme";

import {
  Typography,
  Box,
  Link,
  Container,
} from "@mui/material";
export default function ComingSoon() {

  useEffect(() => {
    document.title = "Coming Soon - Wordibly";
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "12px",
          height: "100%",
        }}
      >
        <img
          src="wordiblyLogoBlue.svg"
          alt="centerImage"
          style={{ height: "120px" }}
        />
        <Typography
          variante="h1"
          style={{
            color: "rgb(89, 89, 89)",
            fontSize: "30px",
            fontWeight: 400,
            height: "37.5px",
            letterSpacing: "4.29px",
            lineHeight: "37.5px",
          }}
        >
          COMING SOON!
        </Typography>
        <img src="/Image-right-row_sharing_solution.webp" alt="centerImage" />
        <Box
          style={{
            height: "1px",
            width: "80%",
            backgroundColor: theme.palette.gray.main,
          }}
        ></Box>
        <Typography variante="p">
          Copyright © 2023 Wordibly - All Rights Reserved.
        </Typography>
        <Box
          style={{
            height: "1px",
            width: "20%",
            backgroundColor: theme.palette.gray.main,
          }}
        ></Box>
        <Typography variante="p">
          Powered by <Link>GoDaddy</Link>
        </Typography>
      </Container>
    </ThemeProvider>
  );
}
