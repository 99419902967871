import Config from "./config";
import { basename, baseNameFolder } from "./utils";
import Requests from "./logic/Requests";

const basePath = "Knowledge/";

async function emptyS3Directory(bucket, dir) {
  const listedObjects = await Requests.getMyFilesObjects(bucket, dir)


  if (listedObjects.Contents.length === 0) return;

  const deleteParams = {
    Bucket: bucket,
    Delete: { Objects: [] },
  };

  listedObjects.Contents.forEach(({ Key }) => {
    deleteParams.Delete.Objects.push({ Key });
  });

  await Requests.deleteMyFilesObjects(deleteParams);

  if (listedObjects.IsTruncated) await emptyS3Directory(bucket, dir);
}


async function deleteItem(bucket, key) {
  await Requests.deleteMyFilesObject(bucket, key)
}

function generateUniqueString(existingStrings, baseString) {  
  const tempVar = "SOMERANDOMETEMPVAR";
  const tempArray = existingStrings.map(e=>e.replace(baseString,tempVar))

  // Extract numbers from existing strings that match the baseString format
  const pattern = new RegExp(`${tempVar} \\((\\d+)\\)$`);
  const numbers = tempArray
    .map((str) => str.match(pattern))
    .filter((match) => match !== null)
    .map((match) => parseInt(match[1], 10));

  if (numbers.length > 0) {
    const maxNumber = Math.max(...numbers);
    return `${baseString} (${maxNumber + 1})`;
  } else {
    return `${baseString} (1)`;
  }
}

function getNameOfFolder(name) {
  return name.split("/").reverse()[1];
}

function getNameOfFile(fileName) {
  return fileName.replace(/^(.*[/\\])?/, "").replace(/(\.[^.]*)$/, "");
}

function listAllKeys(params) {
  return new Promise((done, reject) => {
    let data = [];
    list();
    function list() {
      Requests.getMyFilesObjects(Config.awsBucketName, basePath, false).then((_data) => {
          data.push(
            ..._data.CommonPrefixes.map((prefix) => {
              return { Key: prefix.Prefix };
            })
          );
          data.push(..._data.Contents);
          if (_data.IsTruncated) {
            params.ContinuationToken = _data.NextContinuationToken;
            list();
          } else {
            done(data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
}

function parseInformation(object) {
  object.isFile =
    object.Key.slice(object.Key.length - 1, object.Key.length) === "/"
      ? false
      : true;
  object.name = !object.isFile
    ? object.Key.split("/").reverse()[1]
    : object.Key.replace(/^(.*[/\\])?/, ""); //.replace(/(\.[^.]*)$/, "");
  object.isPublic = !object.isFile && object.name === "Public";

  if (object.isFile) {
    object.url = `https://${Config.awsBucketName}.s3.amazonaws.com/${object.Key}`;
    object.ext = /[.]/.exec(object.Key)
      ? /[^.]+$/.exec(object.Key)[0]
      : undefined;
  }
  return object;
}

function listObjectS3(key = "", isGlobal = false, isSearch = false) {
  return new Promise((done, reject) => {
    let params = {
      MaxKeys: 2147483647,
      Prefix: isGlobal ? key : basePath + key,
    };
    if (!isSearch) {
      params.Delimiter = "/";
    }

    listAllKeys(params)
      .then((_data) => {
        let resu = _data.map((object) => parseInformation(object));
        let itemForRemove = resu.findIndex(
          (a) => a.isFile === false && a.Size === 0
        );
        if (itemForRemove >= 0) {
          if (!isSearch) {
            resu.splice(itemForRemove, 1);
          }
        }
        done(resu.sort((_data) => _data.name));
      })
      .catch((err) => {
        reject(err);
      });
  });
}

async function siteMap(paths, onlyFolders = false) {
  let isArrayOfPaths = paths instanceof Array ? true : false;
  let folders = [];
  if (isArrayOfPaths) {
    for (let path of paths) {
      try {
        let folder = await listObjectS3(path, true, true);
        folders.push(...folder);
      } catch (err) {
        console.error("Unable to download the files", err);
      }
    }
  } else {
    try {
      folders = await listObjectS3("", false, true);
    } catch (err) {
      console.error("Unable to download the files", err);
    }
  }

  let tree_folder = [];
  let push_folder = (str) => {
    if (tree_folder.filter((item) => item.path === str.path).length === 0) {
      tree_folder.push(str);
    }
  };
  folders.forEach((item) => {
    let paths = item.Key.split("/");
    let path = "";
    paths.forEach((_item, index) => {
      if (_item !== "") {
        let isFile = false;
        if (
          item.isFile === true &&
          index === paths.length - 1 &&
          onlyFolders === true
        ) {
          return;
        } else if (item.isUrl === true) {
          return;
        } else if (
          item.isFile === true &&
          index === paths.length - 1 &&
          onlyFolders === false
        ) {
          path += _item;
          isFile = true;
        } else {
          path += _item + "/";
        }
        push_folder({ path, isFile });
      }
    });
  });
  let getParent = (str) => {
    str = str.split("/").filter((item) => item !== "");
    return str.slice(0, str.length - 1).join("/") + "/";
  };
  let tree = [];
  let i = 0;
  let detect_folders = (_path, isFile) => {
    let children = tree_folder.filter((item) => _path === getParent(item.path));
    let resu = { id: i, path: _path, isFile };
    if (i === 0 && !isArrayOfPaths) {
      resu.toggled = true;
      resu.name = "Home";
    } else {
      resu.name = isFile ? getNameOfFile(_path) : getNameOfFolder(_path);
    }
    i += 1;
    if (children.length > 0) {
      resu.children = children.map((item) =>
        detect_folders(item.path, item.isFile)
      );
    }
    return resu;
  };
  if (isArrayOfPaths) {
    for (let path of paths) {
      tree.push(detect_folders(path, false));
    }
  } else {
    tree.push(detect_folders(basePath, false));
  }

  return tree;
}

const moveMyFilesObject =  async (row, folderInsidePrefix, user,changeAllClientFiles,updateFiles,clearSelected,setRowFileData) => {
  if (row.id === folderInsidePrefix[folderInsidePrefix.length - 1].id) {
    return {type:"error", error:  "You can't move a folder to itself!", message:"You can't move a folder to itself!"}
  } else {
    const prefixes = !row.isDir
      ? row.id.replace(row.name, "")
      : row.id.replace(row.name + "/", "");
    if (prefixes === folderInsidePrefix[folderInsidePrefix.length - 1].id) {
      return {type:"warning",message:  "You cannot move the file/folder into the same folder where that file/folder is located." };
    } else {
      try{
        const res = await moveMyFilesMultipleObject(
          row.id,
          folderInsidePrefix[folderInsidePrefix.length - 1].id,
          user,
          changeAllClientFiles,
          updateFiles,
          clearSelected,
          setRowFileData
        );
        return res
      }catch(err){
        throw new Error(err);
      }
    }
  }
};

const moveMyFilesMultipleObject = async (originalPath, newPath, user,changeAllClientFiles,updateFiles,clearSelected,setRowFileData) => {
  let newPrefix = "";
  if(originalPath.endsWith("/")){
    const folderName = originalPath.split("/").reverse()[1]
     newPrefix = `${newPath}${folderName}/`;
  }else{
    const fileName = originalPath.split("/").reverse()[0]
    newPrefix=`${newPath}${fileName}`;
  }

  const currentPath = await Requests.getMyFilesObjects(Config.awsBucketName, originalPath)
  const futurePath = await Requests.getMyFilesObjects(Config.awsBucketName, newPath)
  
  const futurePathContent = futurePath.Contents
  const currentPathContent = currentPath.Contents
  const futurePathContentKeys = futurePathContent.map((c) => c.Key)
  if (futurePathContentKeys.includes(newPrefix)) {
    return {type: "warning",message:"You cannot move the file/folder into a folder that contains a file/folder with the same name"}
  }
  try {
    const response = await Requests.renameObject(originalPath, newPrefix)
    if (response.status !== 200) {
      return {type: "error",message:"We couldn't move your folder"}
    }
    const pathsToUpdate = currentPathContent.map(c => c.Key)
    const ans = await Requests.updateS3PathsInTheDatabase(originalPath, newPrefix, pathsToUpdate);
    const res = await Requests.getUserFolders(user.id)
      changeAllClientFiles(res.data);
      updateFiles()
      clearSelected();
      setRowFileData(null);
      return {type:"success", message: "File/folder moved successfully."}
  } catch (err) {
      throw new Error(err);
  }
};


const awsUtils = {
  emptyS3Directory,
  deleteItem,
  siteMap,
  moveMyFilesObject,
  moveMyFilesMultipleObject,

};

export default awsUtils;
