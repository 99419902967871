export const formatDuration = (duration) => {
    if (duration < 60) {
        return { format: `${Math.ceil(duration)} Min`, duration: duration };
    } else if (duration < 3600) {
        return {
            format: `${Math.ceil(duration / 60)} Mins`,
            duration: duration,
        };
    } else {
        const hours = Math.floor(duration / 3600);
        const mins = Math.ceil(duration - hours * 3600);
        return {
            format: `{${hours} Hours${mins > 0 && mins + " Mins"}`,
            duration: duration,
        };
    }
}

export const calculateTranscriptionTime = (duration, estimatedDeliveryTime,typeOfTranscript, needsHuman) => {
    // duration is in seconds.
    // min and max are in minutes
    if(typeOfTranscript == "human" || needsHuman){
        // Andrew requested to hardcode the duration in the case of human transcripts in ticket: TOPS-3396
        return "24-48hrs";

    // const transcriptionTime = estimatedDeliveryTime.filter(
    //     (range) =>
    //         duration >= range.min &&
    //         (duration < range.max || range.max === undefined)
    // );
    // return transcriptionTime[0].medianTurnaroundTime;   
    }else{
    return "2 ~ 20 min"   
    }

}

export const getDuration = (file) => { 

    if (file.type.startsWith("audio/") || file.type.startsWith("video/") ) {
        const element = new Audio();

        element.src = URL.createObjectURL(file);
        return new Promise((resolve) => {
            element.addEventListener("loadedmetadata", () => {
                resolve(Math.round(element.duration));
            });
        });
    }
}
