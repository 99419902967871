import EditorUtils from "../../../editorUtils";
import {
  withColumnsDefaultContent,
  withOutColumnsDefaultContent,
} from "./defaultContent";
import {
  handleBeforeUndo,
  handleClick,
  handleKeyDown,
  handleKeyUp,
  handleUndo,
} from "./eventHandlers";

const baseConfig = ({
  speakerList,
  speakerOrder,
  content,
  user,
  defaultContent,
}) => {
  return {
    height: 500,
    extended_valid_elements: "script[language|type|src]",
    valid_elements: EditorUtils.validElements,
    content_css: "/editor.css",
    menubar: false,
    elementpath: false,
    plugins: EditorUtils.plugins,
    quickbars_insert_toolbar: "",
    quickbars_selection_toolbar: "",
    spellchecker_active: true,
    powerpaste_word_import: "merge",
    powerpaste_googledocs_imports: "merge",
    powerpaste_html_import: "merge",
    content_style: "p {margin: 0;}",
    save_onsavecallback: function () {},
    toolbar:
      user.rolename !== "Transcriber" && user.rolename !== "QA"
        ? "undo redo | bold italic underline strikethrough | spellchecker language spellcheckdialog | alignleft aligncenter alignright alignjustify | searchreplace | casechange | pagebreak | charmap emoticons | fullscreen preview save print | insertfile image media pageembed link anchor codesample | a11ycheck ltr rtl"
        : "undo redo | bold italic underline | spellchecker language spellcheckdialog | searchreplace | casechange",
    init_instance_callback: (editor) => {
      //add contenteditable to false
      const body = window.tinymce.activeEditor.dom.select("body");
      body[0].setAttribute("contenteditable", "false");
      if (content && content.html !== "") {
        //Getting speakers
        speakerList.current = [];
        speakerOrder.current = content.speakerOrder;
        editor.setContent(content.html);
        if (defaultContent == null) {
          body[0].setAttribute("contenteditable", "true");
        }
        /* if (
          user.rolename === "QA" ||
          user.rolename === "Admin" ||
          user.rolename === "PM"
        ) {
          let id = 1;
          const p = window.tinymce.activeEditor.dom.select("p");
          for (let i = 0; i < p.length; i++) {
            if (p[i].classList.contains("EditorContent")) {
              let textToArray = p[i].innerHTML;
              textToArray = textToArray.replace(/&nbsp;/g, " ");
              textToArray = textToArray.split(" ");
              textToArray = textToArray.filter((data) => data !== "");
              for (let j = 0; j < textToArray.length; j++) {
                if (
                  textToArray[j].includes("<strong>") &&
                  !textToArray[j].includes("</strong>")
                ) {
                  textToArray[j] = textToArray[j] + "</strong>";
                } else if (
                  textToArray[j].includes("</strong>") &&
                  !textToArray[j].includes("<strong>")
                ) {
                  textToArray[j] = "<strong>" + textToArray[j];
                } else if (
                  textToArray[j].includes("</em>") &&
                  !textToArray[j].includes("<em")
                ) {
                  textToArray[j] = "<em>" + textToArray[j];
                } else if (
                  textToArray[j].includes("<em>") &&
                  !textToArray[j].includes("</em>")
                ) {
                  textToArray[j] = textToArray[j] + "</em>";
                } else if (
                  textToArray[j].includes("</s>") &&
                  !textToArray[j].includes("<s>")
                ) {
                  textToArray[j] = "<s>" + textToArray[j];
                } else if (
                  textToArray[j].includes("<s>") &&
                  !textToArray[j].includes("</s>")
                ) {
                  textToArray[j] = textToArray[j] + "</s>";
                }
                textToArray[
                  j
                ] = `<span id="${id}" class="${id}"> ${textToArray[j]}</span>`;
                id = id + 1;
              }
              textToArray = textToArray.join("");
              p[i].innerHTML = textToArray;
            }
          }
        }*/
      } else {
        if (defaultContent != null) {
          editor.setContent(defaultContent);
          EditorUtils.displaySpeakers();
        } else {
          body[0].setAttribute("contenteditable", "true");
        }
      }
    },
  };
};

const editorWithColumnsInitConfig = ({
  speakerList,
  speakerOrder,
  content,
  user,
  setEditorFocus,
  WordAlignment,
  AddSpeaker,
  lastSetTimeOutId,
  handleSave,
}) => {
  return {
    ...baseConfig({
      speakerList,
      speakerOrder,
      content,
      user,
      defaultContent: withColumnsDefaultContent,
    }),
    setup: (editor) => {
      editor.on("click", (e) =>
        handleClick(e, { setEditorFocus, user, content, WordAlignment, editor })
      );
      editor.on("keydown", (e) => handleKeyDown(e, { editor, AddSpeaker }));
      editor.on("keyup", (e) =>
        handleKeyUp(e, { lastSetTimeOutId, handleSave, editor })
      );
      editor.on("undo", (e) => handleUndo(e, { editor }));
      editor.on("PastePreProcess", function (data) {
        if (user.rolename === "Transcriber" && user.copypaste === 0) {
          data.content = "";
        } else {
          const content = data.content;
          var regex = /(<[^>]*>)/g;
          var resultArray = content.split(regex);
          let finalContent = "";
          let nextbold = false;
          let nextitalic = false;
          let conteo = 0;
          resultArray.forEach((element, index) => {
            if (
              element.includes("<") &&
              !element.includes("</") &&
              !element.includes("<br")
            ) {
              if (
                element.includes("font-weight") ||
                element.includes("<strong>")
              ) {
                finalContent += "<strong>";
                nextbold = true;
              }
              if (
                element.includes("italic") ||
                element.includes("<em>") ||
                element.includes("<i>")
              ) {
                finalContent += "<em>";
                nextitalic = true;
              }
              if (conteo == 0) {
                finalContent += "<p>";
              }
              conteo = conteo + 1;
            }
            if (element.includes("</")) {
              if (nextbold && element != "") {
                finalContent += "</strong>";
                nextbold = false;
              }
              if (nextitalic && element != "") {
                finalContent += "</em>";
                nextitalic = false;
              }
              conteo = conteo - 1;
              if (conteo == 0) {
                finalContent += "</p>";
              }
            }
            if (!element.includes("<") && !element.includes("</")) {
              finalContent += element;
            }
            if (element.includes("<br")) {
              finalContent += "</br>";
            }
          });
          finalContent = finalContent.replace(/<p><\/p>/gi, "");
          finalContent = finalContent.replace(/<p><\/br><\/p>/gi, "</br>");
          data.content = finalContent;
        }
      });
    },
  };
};

const editorWithOutColumnsInitConfig = ({
  speakerList,
  speakerOrder,
  content,
  user,
  setEditorFocus,
  WordAlignment,
  AddSpeaker,
  lastSetTimeOutId,
  handleSave,
}) => {
  return {
    ...baseConfig({
      speakerList,
      speakerOrder,
      content,
      user,
      defaultContent: null,
    }),
    setup: (editor) => {
      editor.on("PastePreProcess", function (data) {
        if (user.rolename === "Transcriber" && user.copypaste === 0) {
          data.content = "";
        } else {
          const content = data.content;
          var regex = /(<[^>]*>)/g;
          var resultArray = content.split(regex);
          let finalContent = "";
          let nextbold = false;
          let nextitalic = false;
          let conteo = 0;
          resultArray.forEach((element, index) => {
            if (
              element.includes("<") &&
              !element.includes("</") &&
              !element.includes("<br")
            ) {
              if (
                element.includes("font-weight") ||
                element.includes("<strong>")
              ) {
                finalContent += "<strong>";
                nextbold = true;
              }
              if (
                element.includes("italic") ||
                element.includes("<em>") ||
                element.includes("<i>")
              ) {
                finalContent += "<em>";
                nextitalic = true;
              }
              if (conteo == 0) {
                finalContent += "<p>";
              }
              conteo = conteo + 1;
            }
            if (element.includes("</")) {
              if (nextbold && element != "") {
                finalContent += "</strong>";
                nextbold = false;
              }
              if (nextitalic && element != "") {
                finalContent += "</em>";
                nextitalic = false;
              }
              conteo = conteo - 1;
              if (conteo == 0) {
                finalContent += "</p>";
              }
            }
            if (!element.includes("<") && !element.includes("</")) {
              finalContent += element;
            }
            if (element.includes("<br")) {
              finalContent += "</br>";
            }
          });
          finalContent = finalContent.replace(/<p><\/p>/gi, "");
          finalContent = finalContent.replace(/<p><\/br><\/p>/gi, "</br>");
          data.content = finalContent;
        }
      });
      editor.on("keyup", (e) =>
        handleKeyUp(e, { lastSetTimeOutId, handleSave, editor })
      );
    },
    branding: false,
  };
};

export { editorWithColumnsInitConfig, editorWithOutColumnsInitConfig };