import { useEffect, React, useState } from "react";
import {
  TextField,
  Box,
  Typography,
  Button,
  FormControl,
  Switch,
  styled,
  Stack
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import theme from "../theme";
import themeWordibly from "../theme";
import Requests from "../logic/Requests";
import { useNavigate } from "react-router-dom";
import "../App.css";
import useZustant from "../hooks/useZustant";
import useShow from "../hooks/useShow";
import RecoverPasswordModal from "../components/RecoverPasswordModal";
import MultiFactorAuthModal from "../components/MultiFactorAuthModal";
import CustomSnackBarAlert from "../components/global/CustomSnackBarAlert";

import Login_focusfwd from "../components/Login_focusfwd";
import Login_wordibly from "../components/Login_wordibly";

import Config from "../config";
import Utils from "../utils";

export default function Login() {
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const confirmToken = (query) => {
    Requests.confirmEmail(query)
    .then(response => {
      handleAlert("success", response.data);
    })
    .catch(error => {
      handleAlert("error", error.response.data.errorMessage);
    });
  }

  useEffect(() => {
    document.title = window.location.host.includes('wordibly') ? "Login - Wordibly" :"Login - Focus Forward Tops";
    const url = new URL(window.location.href);
    const urlToken = url.searchParams.get("token");
    if(window.location.search.includes("confirmationToken")) {
      confirmToken(window.location.search)
    }

    if (urlToken) {
      setToken(urlToken);
    }
  }, []);

  const { show: showAlert, close: closeAlert, open: openAlert } = useShow();

  const [currentAlertState, setCurrentAlertState] = useState({
    type: "error",
    message: "",
  });

  const handleAlert = (type, message) => {
    setCurrentAlertState({
      type,
      message,
    });
    openAlert();
  };
  const [alertError, setAlertError] = useState("We were unable to log you in. Please verify your username and password.");
  const [errorFields, setErrorFields] = useState(false);
  const [error, setError] = useState(false);
  const [MFAemail, setMFAemail] = useState("");
  const [MFApassword, setMFApassoword] = useState("");
  const [loading, setLoading] = useState(false);
  const { user, getUser } = useZustant();
  const { show, open, close } = useShow();
  const [isWordibly , setIsWordibly] = useState(window.location.host.includes("wordibly"));

  const {
    show: showRecover,
    open: openRecover,
    close: closeRecover,
  } = useShow();

  const {
    show: showMfa,
    open: openMfa,
    close: closeMfa,
  } = useShow();

  const changePassword = async (e,user) => {
    e.preventDefault();
    try {
      const data = new FormData(e.currentTarget);

      const password = data.get("password");
      const confirmPassword = data.get("confirmPassword");

      if (password !== confirmPassword) {
        handleAlert("warning", "Passwords do not match");
        return;
      } else {
        const validationMessage = Utils.checkPasswordRestrictions(
          password,
          user
        );
        if (!validationMessage.validation) {
          handleAlert("error", validationMessage.message);
          return;
        } else {
          Requests.changePassword({
            name: "",
            token: token,
            password: password,
          }).then((res) => {
            if (res.status === 200) {
              handleAlert("success", "Password changed successfully");
              navigate("/");
              window.location.reload();
            }
          })
          .catch(error => {
            handleAlert("error", error.response.data);
          });
        }
      }
    } catch (error) {
      handleAlert("error", "There was an error updating the information");
    }
  };

  const ResetPassword = () => {
    const [password, setPassword] = useState("");
    const [data, setData] = useState("");
    
    useEffect( ()=>{
      Requests.getByToken(token).then(e=>setData(e.data))
      .catch((error) => {
        console.log(error)
        handleAlert("error", error.response.data);
        navigate("/");
        window.location.reload();
      })
    },[])

    return (
      <Box
        className="boxModal"
        sx={{
          p: 3,
        }}
      >
        <h1 id="modal-modal-title">Change Password</h1>

        <form className="formModal" onSubmit={(e)=>changePassword(e,data)}>
          <FormControl fullWidth>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              variant="filled"
              type="password"
              error={errorFields}
              id="password"
              autoComplete="current-password"
              helperText={errorFields ? "Incorrect entry." : ""}
              display={"block"}
              onChange={(e)=>setPassword(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              variant="filled"
              type="password"
              error={errorFields}
              id="confirmPassword"
              autoComplete="current-password"
              helperText={errorFields ? "Incorrect entry." : ""}
              display={"block"}
            />
          </FormControl>
          <Typography variant={"p"} style={{ fontSize: "10px" }}>{Utils.checkPasswordRestrictions(password,data).message}</Typography>
          <div className="buttonsModalContainer">
            <Button type="submit" variant="contained" color="primary">
              Change Password
            </Button>
          </div>
        </form>
      </Box>
    );
  };

  const verifyMFACode = async (e)=>{
    e.preventDefault()
    const data = new FormData(e.currentTarget);
    const code = data.get("MFA");
    try {
      let res = await Requests.verifyMFACode(MFAemail,code,MFApassword)
      if(res.status==200){
        await getUser();
      }
    } catch (error) {
      alert('WRONG MFA CODE')
    }
  }

  const { show: showError, open: openError, close: closeError } = useShow();
  const handleSubmit = async (event) => {
    document.cookie = `comesFromTranscriberHub=true; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${Config.cookieDomain}; path=/;"`;
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const userData = {
      username: data.get("email"),
      password: data.get("password"),
    };
    event.preventDefault();
    if (userData.username === "" || userData.password === "") {
      setErrorFields(true);
      setLoading(false);
    } else {
      try {
        const login = await Requests.login(userData);
        if (login.status === 200) {
          await getUser();
        } else {
          setError(true)
          setAlertError("Your account is not activated please, check your email to confirm it.")
        }
      } catch (error) {
        if(error.response.data.errorMessage) {
          if(error.response.data.errorMessage=='MFA Required'){
            setMFAemail(userData.username)
            setMFApassoword(userData.password)
            openMfa()
          }else{setAlertError(error.response.data.errorMessage)}
        } else {
          setAlertError("We were unable to log you in. Please verify your username and password.")
        }
        setLoading(false);
        console.error(error);
        setError(true);
      }
    }
  };

  const recoverPassword = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData(e.currentTarget);
      const email = data.get("email");

      const res = await Requests.ResetPassword(email, true);
      if (res.status === 200) {
        openRecover();
        close();
      } else {
        openError();
      }
    } catch (error) {
      handleAlert("error",error.response.data);
    }
  };
 // switch button style
 const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

  return (
    <ThemeProvider
      theme={window.location.host.includes("wordibly") ? themeWordibly : theme}
      style={{height:"100%"}}
    >
      <MultiFactorAuthModal
        show={showMfa}
        close={closeMfa}
        verifyMFACode={verifyMFACode}
      />
      <RecoverPasswordModal
        show={show}
        close={close}
        recoverPassword={recoverPassword}
      />
      <CustomSnackBarAlert show={showError} close={closeError} type="error">
        There was an error sending the email
      </CustomSnackBarAlert>

      <CustomSnackBarAlert
        show={showRecover}
        close={closeRecover}
        type="success"
      >
        A recovery email has been sent.
      </CustomSnackBarAlert>

      <CustomSnackBarAlert
        show={showAlert}
        close={closeAlert}
        type={currentAlertState.type}
      >
        {currentAlertState.message}
      </CustomSnackBarAlert>
      {token ? (
        <ResetPassword />
      ) : (
        <div className={isWordibly ? "wordiblyApp" : "App"}>
         { window.location.host.includes("localhost") && <Box
            style={{
              position: "absolute",
              top: "10%",
              backgroundColor: "white",
              padding: "10px 20px 10px 20px",
              borderRadius: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              left: "50%",
              transform: "translate(-50%, 0)",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>FocusFwd</Typography>
              <AntSwitch
                checked={isWordibly}
                inputProps={{ "aria-label": "ant design" }}
                onChange={() => setIsWordibly(!isWordibly)}
              />
              <Typography>Wordibly</Typography>
            </Stack>
          </Box>}
          {isWordibly ? (
            <Login_wordibly
              handleSubmit={handleSubmit}
              error={error}
              alertError={alertError}
              errorFields={errorFields}
              loading={loading}
              open={open}
            />
          ) : (
            <Login_focusfwd
              handleSubmit={handleSubmit}
              error={error}
              alertError={alertError}
              errorFields={errorFields}
              loading={loading}
              open={open}
            />
          )}
          <Typography
            variant="body2"
            color="white"
            align="center"
            style={{ marginTop: 10, color: "white" }}
          >
            {isWordibly
              ? ""
              : "© 2023 Focus Forward, LLC. All rights reserved. "}
          </Typography>
        </div>
      )}
    </ThemeProvider>
  );
}
