import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#1A336E",
    },
    neutral: {
      main: "#12010C",
    },
    secondary: {
      main: "#DCE1C7",
    },
    third: {
      main: "#64E83E",
    },
    fourth: {
      main: "#EA7806",
    },
    red: {
      main: "#D50000",
    },
    white: {
      main: "#FFFFFF",
    },
    success: {
      main: "#66BB6A",
    },
    gray: {
      main: "#E0E0E0",
      light: "#FFFFFF",
      strong: "#808080"
    },
    black: {
      main: "#000"
    },
    purple:{
      main: "#800080"
    },
    wordibly:{
      green: {
        main:"#65E83E"
      },
      blue: {
        main:"#1A326E"
      },
      gray: {
        main: "#E0E0E0",
        light: "#FFFFFF",
        strong: "#808080"
      },
      white: {
        main: "#FFFFFF",
      },
    }
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
    h1: {
      fontSize: "2rem",
      fontWeight: 600,
      "@media (max-width:600px)": {
        fontSize: "1.75rem",
      },
      "@media (max-width:550px)": {
        fontSize: "1.5rem",
      },
      "media (max-width:350px)": {
        fontSize: "1.25rem",
      },
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: 600,
      "@media (max-width:600px)": {
        fontSize: "1.25rem",
      },
      "@media (max-width:550px)": {
        fontSize: "1.125rem",
      },
      "media (max-width:350px)": {
        fontSize: "1rem",
      },
    },
    h3: {
      fontSize: "1.25rem",
      fontWeight: 600,
      "@media (max-width:600px)": {
        fontSize: "1.125rem",
      },
      "@media (max-width:550px)": {
        fontSize: "1rem",
      },
      "media (max-width:350px)": {
        fontSize: "0.875rem",
      },
    },
    h4: {
      fontSize: "1rem",
      fontWeight: 600,
      "@media (max-width:600px)": {
        fontSize: "0.875rem",
        fontWeight: 600,
      },
    },
    h5: {
      fontSize: "0.875rem",
      fontWeight: 300,
    },
    footer: {
      fontSize: "0.8rem",
      fontWeight: 300,
    },
    p: {
      fontSize: "0.9rem",
      fontWeight: 400,
      "@media (max-width:600px)": {
        fontSize: "0.875rem",
      },
      "@media (max-width:550px)": {
        fontSize: "0.75rem",
      },
      "media (max-width:350px)": {
        fontSize: "0.625rem",
      },
    },
  },
});

const themeWordibly = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#1A326E",
    },
    neutral: {
      main: "#12010C",
    },
    secondary: {
      main: "#DCE1C7",
    },
    third: {
      main: "#64E83E",
    },
    fourth: {
      main: "#EA7806",
    },
    red: {
      main: "#D50000",
    },
    white: {
      main: "#FFFFFF",
    },
    success: {
      main: "#66BB6A",
    },
    gray: {
      main: "#E0E0E0",
      light: "#FFFFFF",
      strong: "#808080"
    },
    black: {
      main: "#000"
    },
    purple:{
      main: "#800080"
    },
    green: {
      main:"#64E83E"
    },
    blue: {
      main:"#1A326E"
    },
    white: {
      main: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(","),
    h1: {
      fontSize: "2rem",

      fontWeight: 800,
      "@media (max-width:600px)": {
        fontSize: "1.75rem",
      },
      "@media (max-width:550px)": {
        fontSize: "1.5rem",
      },
      "media (max-width:350px)": {
        fontSize: "1.25rem",
      },
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: 600,
      "@media (max-width:600px)": {
        fontSize: "1.25rem",
      },
      "@media (max-width:550px)": {
        fontSize: "1.125rem",
      },
      "media (max-width:350px)": {
        fontSize: "1rem",
      },
    },
    h3: {
      fontSize: "1.25rem",
      fontWeight: 600,
      "@media (max-width:600px)": {
        fontSize: "1.125rem",
      },
      "@media (max-width:550px)": {
        fontSize: "1rem",
      },
      "media (max-width:350px)": {
        fontSize: "0.875rem",
      },
    },
    h4: {
      fontSize: "1rem",
      fontWeight: 600,
      "@media (max-width:600px)": {
        fontSize: "0.875rem",
        fontWeight: 600,
      },
    },
    h5: {
      fontSize: "0.875rem",
      fontWeight: 300,
    },
    footer: {
      fontSize: "0.8rem",
      fontWeight: 300,
    },
    p: {
      fontSize: "0.9rem",
      fontWeight: 400,
      "@media (max-width:600px)": {
        fontSize: "0.875rem",
      },
      "@media (max-width:550px)": {
        fontSize: "0.75rem",
      },
      "media (max-width:350px)": {
        fontSize: "0.625rem",
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      tablet: 1035,
      lg: 1035,
      xl: 1536,
    },
  },
});
export default theme;
export { themeWordibly };
/*
1A336E - primary blue
12010C - secondary black
DCE1C7 - third light green
64E83E - fourth green
EA7806 - fifth orange
*/
