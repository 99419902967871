import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  Grid,
  Box,
  Typography,
  Chip,
  IconButton,
  TextField,
} from "@mui/material";
import useShow from "../../hooks/useShow";
import "../../App.css";
import { Container } from "@mui/system";
import footPedalIcon from "../../assets/img/FootPedal.png";

function ConnectPedalModal(props) {
  const { show, open, close } = useShow();
  const { sendCommand } = props;
  const [isChrome,setIsChrome] = useState(false);

  const vecPedalID = 0x05f3;
  const style = {
    border: "none",
    margin: "10px",
  };

  useEffect(() => {
    var browser, usrAg = navigator.userAgent;
    if(usrAg.indexOf("Chrome") > -1){
      setIsChrome(true)
      navigator.hid.getDevices().then(async (devices)  => {
        if(devices.length==1){
          if(devices[0].vendorId==vecPedalID){
            let pedal = devices[0]
            if (!pedal.opened) await pedal.open();
            pedal.oninputreport = ({ device, reportId, data }) => {
              let command = new Uint8Array(data.buffer);
              sendCommand(command[0]);
            };
          }
        }
      });
    }   
  }, []);

  

  async function connectPedal() {
    let pedal;
    try {
      const devices = await navigator.hid.requestDevice({
        filters: [{ vendorId: vecPedalID }],
      });
      pedal = devices[0];
    } catch {
      console.log("Error");
    }
    if (!pedal) {
      alert("No device was selected.")
      console.log("No device was selected.");
    } else {
      if (!pedal.opened) await pedal.open();
      pedal.oninputreport = ({ device, reportId, data }) => {
        let command = new Uint8Array(data.buffer);
        sendCommand(command[0]);
      };
    }
  }

  return (
    <>
      {isChrome ? (
        <IconButton onClick={open} style={{ color: "black", marginLeft: 10 }}>
          <img
            src={footPedalIcon}
            alt="footPedal_icon"
            style={{
              maxWidth: "25px",
              height: "25px",
              objectFit: "contain",
            }}
          />
        </IconButton>
      ) : null}

      <Dialog open={show} onClose={close} sx={{ p: 3 }}>
        <Typography variant="h3" sx={{ ml: 3, mb: 3, fontWeight: 400, mt: 3 }}>
          Connect Your Pedal
        </Typography>

        <div style={{ width: "370px" }}>
          <Button
            onClick={() => connectPedal()}
            color="primary"
            style={{ marginLeft: "15px" }}
          >
            Connect
          </Button>
        </div>
        <TextField
          readOnly
          label={"Rewind"}
          value={"Left Button"}
          style={style}
        />
        <TextField
          readOnly
          label={"Play or Pause"}
          value={"Center Button"}
          style={style}
        />
        <TextField
          readOnly
          label={"Fast Forward"}
          value={"Right Button"}
          style={style}
        />

        <Container sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}>
          <Button onClick={close} color="red">
            Close
          </Button>
        </Container>
      </Dialog>
    </>
  );
}

export default ConnectPedalModal;
