import Requests from "../logic/Requests";
import { Modal,Box, FormControl, InputLabel , Input, Button } from "@mui/material";

export default function ChangePasswordModal(props) {
  const { handleAlert , closeModal, currentUser, showModal} = props;

  const changePassword = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData(e.currentTarget);

      const password = data.get("password");
      const confirmPassword = data.get("confirmPassword");

      if (password !== confirmPassword) {
        handleAlert("warning", "Passwords do not match");
        return;
      } else {
        Requests.updatePassword(currentUser.id, {
          password: password,
        }).then((res) => {
          if (res.status === 200) {
            closeModal();
          }
        })
        .catch((error) => {
          handleAlert("error", error.response.data);
        });
      }
    } catch (error) {
      alert("error", "There was an error updating the information");
    }
  };

  return (
    <Modal open={showModal}>
      <Box
        className="boxModal"
        sx={{
          p: 3,
        }}
      >
        <h1 id="modal-modal-title">Change Password</h1>

        <form onSubmit={(e) => changePassword(e)} className="formModal">
          <FormControl fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              id="password"
              name="password"
              type="password"
              required
            />
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor="confirmPassword">
              Confirm Password
            </InputLabel>
            <Input
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              required
            />
          </FormControl>
          <div className="buttonsModalContainer">
            <Button type="submit" variant="contained" color="primary">
              Change Password
            </Button>
            <Button onClick={() => closeModal()}>Cancel</Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
}
