import { React, useEffect, useState } from "react";
import "./MyFiles.css";
import { Button, Container, Box } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Requests from "../../logic/Requests";
import useShow from "../../hooks/useShow";
import CustomSnackBarAlert from "../../components/global/CustomSnackBarAlert";

function FinishedOrder() {
  let { transactionId,typeOfPayment } = useParams();
  const { show, open, close } = useShow();
  const navigate = useNavigate();
  const [currentAlertState, setCurrentAlertState] = useState({
    type: "error",
    message: "",
  });
  const handleAlert = (type, message) => {
    setCurrentAlertState({
      type,
      message,
    });
    open();
  };
  useEffect(() => {
    // make the after 
    if (transactionId && /^[a-zA-Z0-9]{24}$/.test(transactionId)) {
      Requests.afterSquareQuickPayCheckout(transactionId);
    } else if (!transactionId) {
      // Handle the case where transactionId is empty
    } else {
      handleAlert("error", "Wrong request");
      // Handle the case where transactionId is not a valid alphanumeric 24-character string
    }
  }, []);
  const handleLinkClick = (navigatioPath) => {

    // event.preventDefault();
    const currentOrigin = window.location.origin;
    window.top.location.href = currentOrigin+navigatioPath;
  };

  return (
    <div
      className="AppFinished"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent:"center",
        height: "100%",
        alignItems: "center",
      }}
    >
      <CustomSnackBarAlert
        type={currentAlertState.type}
        show={show}
        close={close}
      >
        {currentAlertState.message}
      </CustomSnackBarAlert>
      {typeOfPayment === "addPrepay" ? (
        <Box>
          <h1 className="animated bounceInDown">PREPAID ADDED!</h1>
          <p className="animated fadeIn">
            NOW YOU CAN START A NEW PROJECT NOW, GO TO YOUR FILES, OR ADD
            TEAMMATES.
          </p>
        </Box>
      ) : (
        <Box>
          <h1 className="animated bounceInDown">
            YOUR WORDS ARE ON THEIR WAY!
          </h1>
          <p className="animated fadeIn">
            WANT MORE? START ANOTHER PROJECT NOW, GO TO YOUR FILES, OR ADD
            TEAMMATES.
          </p>
          <h2 className="animated zoomIn">WON'T BE LONG NOW!</h2>
          <h3 className="animated shake">Thank you, Wordibly</h3>
        </Box>
      )}
      <Container
        style={{
          marginTop: "2rem",
        }}
      >
        <Button
          variant="contained"
          style={{
            backgroundColor: "#1a336e",
            color: "white",
            marginRight: "1rem",
            width: "11rem",
            height: "3rem",
          }}
          onClick={() => handleLinkClick("/filemanagement")}
        >
          My Words
        </Button>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#64E83E",
            color: "#1a336e",
            width: "11rem",
            height: "3rem",
          }}
          onClick={() => handleLinkClick("/inviteteammates")}
        >
          Invite teammates
        </Button>
      </Container>
    </div>
  );
}

export default FinishedOrder;