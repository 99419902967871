import { ThemeProvider } from "@mui/material/styles";
import { themeWordibly as theme } from "../../../theme";
import UploadFilesContextProvider from "./context/UploadFilesContext"
import UploadFilesController from "./UploadFilesController";

export default function UploadFiles() {
    return (
        <UploadFilesContextProvider>
            <ThemeProvider theme={theme}>
                <UploadFilesController />
            </ThemeProvider>
        </UploadFilesContextProvider>
    )
}