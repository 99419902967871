import {
  Drawer,
  List,
  Toolbar,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Button,
  IconButton,
  Box,
  MenuItem,
  Collapse,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import useZustant from "../hooks/useZustant";
import "../App.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { themeWordibly as theme } from "../theme";
import UndoIcon from "@mui/icons-material/Undo";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import useShow from "../hooks/useShow";
import CustomSnackBarAlert from "../components/global/CustomSnackBarAlert";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddIcon from "@mui/icons-material/Add";
import RenderMenuItems from "./navbar/RenderMenuItems";
import Requests from "../logic/Requests";

import ordersIcon from "../assets/img/Orders icon - wbg.svg";
import searchIcon from "../assets/img/Search icon - wbg.svg";
import teammatesIcon from "../assets/img/Teammates icon - wbg.svg";
import transactioIcon from "../assets/img/Transaction icon - wbg.svg";
import wordsIcon from "../assets/img/Words icon - wbg.svg";

// src/assets/img/cliendSideBarIcons/Orders icon - wbg.svg

const linksList = [
  {
    name: "Words",
    link: "/filemanagement",
    icon: (
      <img
        src={wordsIcon}
        alt="new_folder_icon"
        style={{
          maxWidth: "18px", // Add a max-width
          height: "18px", // Set the height
          objectFit: "contain",
          marginRight: "8px",
        }}
      />
    ),
  },
  /*{
    name: "Search",
    link: "/searchfiles",
    icon: (
      <img
        src={searchIcon}
        alt="new_folder_icon"
        style={{
          maxWidth: "18px", // Add a max-width
          height: "18px", // Set the height
          objectFit: "contain",
          marginRight: "8px",
        }}
      />
    ),
  },*/
  {
    name: "Orders",
    link: "/orderhistory",
    icon: (
      <img
        src={ordersIcon}
        alt="new_folder_icon"
        style={{
          maxWidth: "18px", // Add a max-width
          height: "18px", // Set the height
          objectFit: "contain",
          marginRight: "8px",
        }}
      />
    ),
  },
  {
    name: "Teammates",
    link: "/inviteteammates",
    icon: (
      <img
        src={teammatesIcon}
        alt="new_folder_icon"
        style={{
          maxWidth: "18px", // Add a max-width
          height: "18px", // Set the height
          objectFit: "contain",
          marginRight: "8px",
        }}
      />
    ),
  },
  {
    name: "Transactions",
    link: "/transactionhistory",
    icon: (
      <img
        src={transactioIcon}
        alt="transactions_icon"
        style={{
          maxWidth: "18px", // Add a max-width
          height: "18px", // Set the height
          objectFit: "contain",
          marginRight: "8px",
        }}
      />
    ),
  },
];

const settingsList = [
  {
    name: "My Account",
    link: "/myaccount",
  },
  {
    name: "Account Details",
    link: "/accountdetails",
  },
  {
    name: "Payment Details",
    link: "/paymentdetails",
  },
];

export default function ClientSidebar() {
  const {
    user,
    currentClientPage,
    isOpenSidebar,
    changeSidebarValue,
    isInClientsSettings,
    clearUser,
    placeFolderPrefix,
    currentFolderPrefix,
    goToRootFunction
  } = useZustant();

  const logout = () => {
    try {
      Requests.logout().then((res) => {
        if (res.status === 200) {
          clearUser();
          navigate("/");
        }
      });
    } catch (error) {
      console.error(error);
    }
  };
  const navigate = useNavigate();
  const { show: showAlert, close: closeAlert, open: openAlert } = useShow();
  const [openCollapse, setOpenCollapse] = useState(false);
  const isLargeScreen = useMediaQuery("(max-width:1035px)");
  const drawerWidth = isLargeScreen ? window.innerWidth : 250;
  const [currentLinks, setCurrentLinks] = useState(linksList);
  const [currentAlertState, setCurrentAlertState] = useState({
    type: "error",
    message: "",
  });
  const handleAlert = (type, message) => {
    setCurrentAlertState({
      type,
      message,
    });
    openAlert();
  };
  const navBarLinksList = [
    /*{
      name: "My Cart",
      link: "/inviteteammates",
      icon: <ShoppingCartIcon style={{ color: theme.palette.green.main }} />,
      onClick: () => handleAlert("warning", "Feature coming soon!"),
    },*/
    {
      name: "Learn",
      link: "/ComingSoon",
    },
    {
      name: "Enterprise",
      link: "/ComingSoon",
    },
  ];

  useEffect(() => {
    const page = document.getElementById(currentClientPage);
    const currentActive = document.querySelector(".active");

    if (currentActive) {
      currentActive.classList.remove("active");
    }

    if (page) {
      page.classList.add("active");
    }
  }, [currentClientPage, isInClientsSettings, currentLinks]);

  useEffect(() => {
    if (isInClientsSettings) {
      setCurrentLinks(settingsList);
    } else {
      setCurrentLinks(linksList);
    }
  }, [isInClientsSettings]);

  const location = useLocation();

  const isInPayment = location.pathname === "/uploadFiles";

  const handleSideBarClick=(link)=>{
    if(link==="/filemanagement"){
      try{
        goToRootFunction();
      }catch(error){
        navigate(link)
      }
    }
    navigate(link)
  }

  return user.rolename === "Client" ? (
    <div style={{ display: "flex" }}>
      <div
        style={{
          position: "fixed",
          left: isOpenSidebar ? drawerWidth : 0,
          zIndex: 11000,
        }}
      >
        <CustomSnackBarAlert
          type={currentAlertState.type}
          show={showAlert}
          close={closeAlert}
        >
          {currentAlertState.message}
        </CustomSnackBarAlert>
        {isLargeScreen &&
                <IconButton
                variant="outlined"
                sx={{
                  display: {
                    xs: "none",
                    [theme.breakpoints.only("lg")]: {
                      display: "block",
                    },
                  },
                }}
                style={{
                  position: "fixed",
                  transition: "left 100ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
                  bottom: 50,
                  left: isOpenSidebar ? drawerWidth - 24 : 0,
                  // left: -8,
                  borderRadius: "50%",
                  height: "44px",
                  width: "44px",
                  border: `2px solid ${theme.palette.gray.main}`,
                  backgroundColor: theme.palette.blue.main,
                }}
                onClick={() => {
                  changeSidebarValue(!isOpenSidebar);
                }}
              >
                {isOpenSidebar ? (
                  <ArrowBackIosNewIcon style={{ color: theme.palette.white.main }} />
                ) : (
                  <ArrowForwardIosIcon style={{ color: theme.palette.white.main }} />
                )}
              </IconButton>
        }
      </div>

      <Drawer
        variant="persistent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: theme.palette.blue.main,
          },
        }}
        anchor="left"
        open={!isLargeScreen ? true : isOpenSidebar}
      >
        <Toolbar
          sx={{
            marginBottom: {
              xs: 10,
              [theme.breakpoints.only("lg")]: {
                marginBottom: 30,
              },
            },
          }}
        />

        {isInClientsSettings ? (
          <Button
            sx={{
              marginLeft: "10px",
              fontWeight: 600,
            }}
            onClick={() => navigate("/filemanagement")}
          >
            <UndoIcon color="white"/>
            {/* <ListItemText
              primary={"Back to My Words"}
              className={!isLargeScreen ? "highlightBarUnde" : "BarOptionResp"}
              disableTypography
            /> */}
            <Typography ml={1} fontWeight={600} className={!isLargeScreen ? "highlightBarUnde" : "BarOptionResp"}>Back To My Words</Typography>
          </Button>
        ) : null}

        <List style={{ marginLeft: "20px" }}>
          {isLargeScreen && (
            <>
              <Button
                color="primary"
                sx={{
                  textTransform: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  paddingRight: "35px",
                  boxSizing: "border-box",
                }}
                onClick={() => setOpenCollapse(!openCollapse)}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <AccountCircleIcon
                    style={{ color: theme.palette.green.main }}
                  />
                  <ListItemText
                    primary={`Hi ${user.firstname}`}
                    className={
                      !isLargeScreen ? "highlightBarUnde" : "BarOptionResp"
                    }
                    disableTypography
                  />
                </Box>
                <AddIcon
                  color={"white"}
                  className={`openSideBarCollapse ${
                    !openCollapse ? "plus" : "cross"
                  }`}
                />
              </Button>
              <Collapse
                in={openCollapse}
                timeout="auto"
                unmountOnExit
                style={{ marginLeft: "35px" }}
              >
                <RenderMenuItems user={user} />
              </Collapse>
            </>
          )}
          {currentLinks.map((item, index) => (
            <ListItem key={index} disablePadding>
              <Button
                id={item.name}
                className="listButton"
                onClick={() => handleSideBarClick(item.link)}
              >
                {item.icon}
                <ListItemText
                  primary={item.name}
                  style={{fontSize: "18px"}}
                  className={
                    !isLargeScreen ? "highlightBarUnde" : "BarOptionResp"
                  }
                  disableTypography
                />
              </Button>
            </ListItem>
          ))}
          {isLargeScreen &&
            navBarLinksList.map((item, index) => (
              <ListItem key={index} disablePadding>
                <Button
                  id={item.name}
                  className="listButton"
                    style={{fontSize: "18px"}}
                    onClick={() => navigate(item.link)}
                >
                  {item.icon}
                  <ListItemText
                    style={{fontSize: "18px"}}
                    primary={item.name}
                    className={
                      !isLargeScreen ? "highlightBarUnde" : "BarOptionResp"
                    }
                    disableTypography
                  />
                </Button>
              </ListItem>
            ))}
          <Button
            variant="contained"
            style={{
              position: "absolute",
              backgroundColor: theme.palette.green.main,
              color: theme.palette.blue.main,
              fontSize: "25px",
              fontStyle: 600,
              letterSpacing: "1.25px",
              height: "54px",
              // width: `${drawerWidth-40}px`,
              marginRight: "20px",
              boxSizing: "border-box",
              // bottom: "20px",
              left: "20px",
              right: "20px",
              display: isLargeScreen ? "Block" : "none",
            }}
            onClick={() => logout()}
          >
            Log Out
          </Button>
        </List>
      </Drawer>
    </div>
  ) : null;
}
