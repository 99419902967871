import { useContext, useState } from "react";
import { themeWordibly as theme } from "../../../theme";
import Requests from "../../../logic/Requests";
import useZustant from "../../../hooks/useZustant";
import Utils from "../../../utils";
import { UploadFilesContext } from "../../../views/MyFiles/UploadFiles/context/UploadFilesContext";
import { useForm } from "react-hook-form";
import SquarePayment from "../../SquarePayment";

import {
    Box,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Typography,
    Button,
    Link,
    Divider
} from "@mui/material";
import { CountryList, StateList } from "../../../lists";
import CustomFormControl from "../../global/CustomInputFormControl";
import { useNavigate } from "react-router-dom";
import UndoIcon from "@mui/icons-material/Undo";
import { LoadingButton } from "@mui/lab";

const CustomerAddress = (props) => {
    const { validateZipCode } = Utils
    const { isEditing, setIsEditing, paymentMethod, handleAlert, user, getUser } = props;
    const { register, handleSubmit } = useForm();
    const verifyField = (value) => (value && value !== "undefined" ? value : "");
    const [isTheFormChange, setIsTheFormChange] = useState(false);
    const handleFormChange = () => setIsTheFormChange(true);
    const onSubmit = async (data) => {
        try {
            if (!validateZipCode(data.zip)) {
                handleAlert("error", "Please enter a valid zip code");
                return;
            }
            if (paymentMethod == "prepay" || paymentMethod == "payPal") {
                await Requests.saveUser(user.id, data).then((res) => {
                    if (res.status === 200) {
                        handleAlert("success", "Your information has been updated");
                        setIsEditing(false);
                        getUser()
                    }
                });
            }
        } catch (error) {
            handleAlert("error", "Error updating your information");
        }
    };
    return (
        <Box my={3}>
            <Typography variant="h3" mb={3} >{paymentMethod == "prepay" || paymentMethod == "payPal" ? "Customer Address" : "Billing Address"}</Typography>
            {!isEditing ?
                <Box style={{ position: "relative" }}>
                    <Box >
                        <Typography>{verifyField(user.address1)}</Typography>
                        <Typography>{verifyField(user.address2)}</Typography>
                        <Typography>{verifyField(user.city)}, {verifyField(user.state)}, {verifyField(user.country)}, {verifyField(user.zip)}</Typography>
                    </Box>
                    <Button style={{ position: 'absolute', right: "10px", top: "0px" }} onClick={() => (setIsEditing(true))}>
                        edit address
                    </Button>
                </Box>
                :
                <form
                    className={"basicInformationForm"}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <CustomFormControl
                        register={register}
                        label="Address 1"
                        fieldId="address1"
                        currentValue={verifyField(user.address1)}
                        type="text"
                        required
                    />

                    <CustomFormControl
                        register={register}
                        label="Address 2"
                        fieldId="address2"
                        currentValue={verifyField(user.address2)}
                        type="text"
                    />

                    <CustomFormControl
                        register={register}
                        label="City"
                        fieldId="city"
                        currentValue={verifyField(user.city)}
                        type="text"
                        required
                    />

                    <div className="formContainer">
                        <CustomFormControl
                            label="Country"
                            fieldId="country"
                            currentValue={verifyField(user.country)}
                            type="select"
                            list={CountryList}
                            register={register}
                            required
                        />

                        <CustomFormControl
                            label="State"
                            fieldId="state"
                            currentValue={verifyField(user.state)}
                            type="select"
                            list={StateList}
                            register={register}
                            required
                        />

                        <CustomFormControl
                            label="Zip"
                            fieldId="zip"
                            currentValue={verifyField(user.zip)}
                            type="text"
                            register={register}
                            required
                        />
                    </div>
                    <Box style={{ width: "100%", display: "flex", justifyContent: "end", gap: "20px" }}>
                        <Button onClick={() => (setIsEditing(false))}>
                            cancel
                        </Button>
                        <Button variant="contained" type="submit" >
                            save address
                        </Button>
                    </Box>
                </form>}
        </Box>
    );
}


const PaymentOptions = (props) => {
    const {
        clientPrepaidBalance,
        user,
        getUser
    } = useZustant()
    const { paymentMethod, setPaymentMethod, filesObject, uploadAudioValidations, handleAfterpayment } = props;
    const [isEditing, setIsEditing] = useState(false)
    const handleRadioChange = (event) => {
        setPaymentMethod(event.target.value);
        if (event.target.value === "newCard") {
            setIsEditing(true);
        } else {
            setIsEditing(false);
        };
    };
    return (
      <FormControl style={{ width: "100%", marginTop: "12px" }}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          value={paymentMethod}
          name="radio-buttons-group"
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value="prepay"
            control={<Radio />}
            disabled={
              clientPrepaidBalance < 0 ||
              clientPrepaidBalance < filesObject.total
            }
            label={
              <Typography variant="p" style={{color: clientPrepaidBalance < 0 ||
                clientPrepaidBalance < filesObject.total ? "gray" : "" }}>
                Prepaid balance, ${clientPrepaidBalance.toFixed(2)} remaining
              </Typography>
            }
          />
          {/* <FormControlLabel
                    value="newCard"
                    control={<Radio />}
                    label={
                        <Typography variant="p" style={{ whiteSpace: "nowrap" }}>
                            Use Credit card{" "}
                            <img
                                style={{ height: "24px" }}
                                src={"/cardsLogos/Accepted-Cards-US.png"}
                                alt="(Visa, Mastercard, American Express, Discover, JCB, or UnionPay International )"
                            />
                        </Typography>
                    }
                /> */}
          {/* <Box
                    style={{ display: paymentMethod === "newCard" ? "block" : "none" }}
                    maxWidth={450}
                >
                    <SquarePayment
                        amount={filesObject.total}
                        currency={"USD"}
                        afterPayment={handleAfterpayment}
                        filesObject={{ ...filesObject, validations: uploadAudioValidations }}
                        typeOfPay="square"
                    />
                </Box> */}
          <FormControlLabel
            value="squareQuickPayCheckout"
            control={<Radio />}
            label={
              <Typography variant="p" style={{ whiteSpace: "nowrap" }}>
                Use Credit card{" "}
                <img
                  style={{ height: "24px" }}
                  src={"/cardsLogos/Accepted-Cards-US.png"}
                  alt="(Visa, Mastercard, American Express, Discover, JCB, or UnionPay International )"
                />
              </Typography>
            }
          />
          {/* <FormControlLabel
                    value="payPal"
                    control={<Radio />}
                    label={<img src={"/cardsLogos/cc-paypal.svg"} alt="PayPal" />}
                /> */}
        </RadioGroup>
        {/* <CustomerAddress
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          paymentMethod={paymentMethod}
          user={user}
          getUser={getUser}
        /> */}
      </FormControl>
    );
}

export default function UploadFilesCheckout({ handleAlert, setIsInCheckout }) {
    const navigate = useNavigate()
    const { filesObject, uploadAudioValidations } = useContext(UploadFilesContext);
    const [loading, setLoading] = useState(false);
    const {
        clientPrepaidBalance,
        changeClientPrepaidBalance,
        currentFolderPrefix,
        placeFolderPrefix,
        setPaymentLink
    } = useZustant()

    const [paymentMethod, setPaymentMethod] = useState(clientPrepaidBalance > filesObject.total && clientPrepaidBalance > 0 ? "prepay" : "newCard");

    const handlePayment = async () => {
      setLoading(true);
      switch (paymentMethod) {
        case "prepay":
          if (clientPrepaidBalance > filesObject.total) {
            const typeOfPay = "usePrepay";
            try {
              await Requests.squarePayment(
                { amount: filesObject.total },
                { ...filesObject, validations: uploadAudioValidations },
                typeOfPay
              );
              changeClientPrepaidBalance(
                clientPrepaidBalance - filesObject.total
              );
              handleAlert("success", "Prepay Blance payment done.");
              navigate("/filemanagement");
            } catch (error) {
              console.log(error);
              handleAlert("error", "Error using prepaid balance.");
            }
            break;
          } else {
            handleAlert("error", "insufficient prepaid balance.");
            break;
          }
        case "squareQuickPayCheckout":
          Requests.squareQuickPayCheckout(
            { amount: filesObject.total, currency: "USD" },
            "quickPayOrder",
            filesObject
          ).then((res) => {
            setTimeout(() => {
              setPaymentLink(res.data.link);
              navigate("/paymentCheckout")
            }
            , 500);
          });
          return true;
        case "savedCard":
          handleAlert("error", "Feature on development.");
          break;
        case "newCard":
          break;
        case "payPal":
          handleAlert("error", "Feature on development.");
          break;
        default:
          console.log("no option selected");
      }
    };

    const exitCheckout = () => { }

    const handleAfterpayment = () => {
        setIsInCheckout(false);
        placeFolderPrefix(currentFolderPrefix[0]);
        navigate("/filemanagement");
    }

    return (
      <Box
        sx={{ display: "flex" }}
        width={"100%"}
        mt={2}
        style={{ gap: "10%" }}
      >
        <Box width={"71%"} sx={{ pl: 5 }}>
          <Box sx={{ display: "flex" }} width={"100%"} mt={2} mb={4}>
            <Box>
              <Button
                sx={{ mt: 2 }}
                onClick={() => navigate("/filemanagement")}
              >
                <UndoIcon />
              </Button>
            </Box>
          </Box>
          <div style={{ marginBottom: "10px" }}>
            <span
              variant="h3"
              style={{
                color: theme.palette.primary.main,
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              Secure Payment
            </span>
          </div>
          <PaymentOptions
            filesObject={filesObject}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            handleAfterpayment={handleAfterpayment}
            uploadAudioValidations={uploadAudioValidations}
          />

          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            <Box>
              By continuing, you agree to the <Link>terms of service</Link>.
            </Box>
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={handlePayment}
            >
              order transcript
            </LoadingButton>
          </Box>
        </Box>
        <Box style={{ width: "29%" }}>
          <div style={{ marginBottom: "10px" }}>
            <span
              style={{
                fontSize: "20px",
                color: "transparent",
              }}
            >
              P
            </span>
          </div>
          <Box className="filesDetails">
            <div className="item">
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 400,
                  color: theme.palette.primary.main,
                  fontSize: "17px",
                }}
              >
                My Transcript Order
              </Typography>
            </div>
            <Divider />

            {filesObject.completedUploads
              ? filesObject.completedUploads.map((file, index) => (
                  <div key={index}>
                    <div className="item file">
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: 300,
                          fontSize: "17px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "150px",
                          color: theme.palette.blue.main,
                        }}
                      >
                        {file.fileName}
                      </Typography>
                      <span className="value">${file.cost} </span>
                    </div>
                    <Divider />
                  </div>
                ))
              : null}

            <div className="item" style={{ paddingBottom: "10-px" }}>
              <Typography variant="43" sx={{ fontWeight: 300 }}>
                SUBTOTAL <b style={{ fontSize: "11px" }}>$1.50/MIN</b>
              </Typography>
              <span className="value">
                ${filesObject.subtotal ? filesObject.subtotal.toFixed(2) : null}
              </span>
            </div>

            {filesObject.checkedOptions
              ? filesObject.checkedOptions.map((option, index) => (
                  <div className="item check" key={index}>
                    <Typography variant="43" sx={{ fontWeight: 300 }}>
                      {option.label}
                    </Typography>
                    <span className="value">
                      ${option.checked ? option.value.toFixed(2) : 0.0}
                    </span>
                  </div>
                ))
              : null}
            <Divider sx={{ mt: 2 }} />

            <div className="item">
              <Typography variant="43" sx={{ fontWeight: 300 }}>
                SALES TAX
              </Typography>

              <span className="value">$0.0</span>
            </div>
            <div className="item">
              <Typography variant="43" sx={{ fontWeight: 300 }}>
                TOTAL
              </Typography>

              <Typography variant="h3">
                ${filesObject.total ? filesObject.total.toFixed(2) : 0.0}
              </Typography>
            </div>
          </Box>
        </Box>
      </Box>
    );
}