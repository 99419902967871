import "../../styles/TranscriberQueue.css";
import { TableRow, TableCell } from "@mui/material";
import Utils from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVolumeHigh,
  faFilm,
  faPause,
  faCheckDouble,
} from "@fortawesome/free-solid-svg-icons";
import { IconButton } from "@mui/material";
import ActionsByUnitState from "../ActionsByUnitState";
import useZustant from "../../hooks/useZustant";

export default function TranscriberHubRow(props) {
  const { decimalDateConverter } = Utils;
  const { user } = useZustant();
  const {
    row,
    transcriberHasUnit,
    changeUnitState,
    handleChangeTranscriberState,
    openUnitError,
    showMediaModal,
    unitToShow,
    location,
  } = props;

  const { transformPayRate } = Utils;
  const projectLocation = location.pathname.split("/")[3];

  const calculateTranscriberPayRate = (
    transcript_transcriber_rate,
    project_transcriber_rate,
    assignment_transcriber_rate,
    revision_rate,
    assigned_to,
    transform,
    is_revision
  ) => {
    let payRate;
    if(is_revision && user._id != assigned_to && revision_rate != null){
      payRate = revision_rate;
    } else if (assignment_transcriber_rate && assignment_transcriber_rate > 0) {
      payRate = assignment_transcriber_rate;
    } else if (transcript_transcriber_rate && transcript_transcriber_rate > 0) {
      payRate = transcript_transcriber_rate;
    } else if (project_transcriber_rate && project_transcriber_rate > 0) {
      payRate = project_transcriber_rate;
    } else {
      payRate = user.rate ? user.rate.amount : 0;
    }

    if (transform) {
      return transformPayRate(payRate, row.unit_length);
    } else {
      return "$" + payRate.toFixed(2);
    }
  };

  const renderSepakers = (speakers) => {
    let speakersNumber = parseInt(speakers);
    if (speakersNumber <= 3 || !speakersNumber) {
      return speakers;
    } else {
      return "3+";
    }
  };

  return (
    <TableRow key={row.unit_id}>
      <TableCell>
        <p style={{ fontSize: 16, fontWeight: 500, textAlign: "start" }}>
          {projectLocation == "transcript"
            ? row.transcript.substr(row.unit_id.length - 8)
            : `${row.unit_id.substr(row.unit_id.length - 8)}`}
        </p>
      </TableCell>
      {user.rolename == "Transcriber" && (
        <TableCell>
          <p style={{ fontSize: 16, fontWeight: 500, textAlign: "start" }}>
            {calculateTranscriberPayRate(
              row.transcript_transcriber_rate,
              row.project_transcriber_rate,
              row.assignment_transcriber_rate,
              row.transcript_revision_rate ? row.transcript_revision_rate : row.project_revision_rate,
              row.assigned_to,
              false,
              row.is_revision
            )}
          </p>
        </TableCell>
      )}
      <TableCell>
        <p style={{ fontSize: 16, fontWeight: 500, textAlign: "start" }}>
          {projectLocation == "transcript"
            ? /^([0-9]{2}):([0-9]{2}):([0-9]{2})$/.test(row.transcript_length)
              ? row.transcript_length[1] === "0"
                ? row.transcript_length.substring(
                    3,
                    row.transcript_length.length
                  )
                : row.transcript_length
              : Utils.toHHMMSS(row.transcript_length)
            : /^([0-9]{2}):([0-9]{2}):([0-9]{2})$/.test(row.duration)
            ? row.duration[1] === "0"
              ? row.duration.substring(3, row.duration.length)
              : row.duration
            : Utils.toHHMMSS(row.duration)}
        </p>
      </TableCell>
      {user.rolename == "Transcriber" && (
        <TableCell>
          <p style={{ fontSize: 16, fontWeight: 500, textAlign: "start" }}>
            {calculateTranscriberPayRate(
              row.transcript_transcriber_rate,
              row.project_transcriber_rate,
              row.assignment_transcriber_rate,
              row.transcript_revision_rate ? row.transcript_revision_rate : row.project_revision_rate,
              row.assigned_to,
              true,
              row.is_revision
            )}
          </p>
        </TableCell>
      )}
      <TableCell>
        <p style={{ fontSize: 16, fontWeight: 500, textAlign: "start" }}>
          {row.language}
        </p>
      </TableCell>
      <TableCell>
        <p style={{ fontSize: 16, fontWeight: 500, textAlign: "start" }}>
          {row.timestamps}
        </p>
      </TableCell>
      <TableCell
        sx={{
          minWidth: "100px",
        }}
      >
        <p style={{ fontSize: 16, fontWeight: 500, textAlign: "start" }}>
          {user.rolename == "Transcriber"
            ? decimalDateConverter(row.assignment_due)
            : decimalDateConverter(row.transcript_due)}
        </p>
      </TableCell>
      <TableCell>
        <p style={{ fontSize: 16, fontWeight: 500, textAlign: "start" }}>
          {renderSepakers(row.speakers)}
        </p>
      </TableCell>
      <TableCell
        sx={{
          minWidth: "100px",
        }}
      >
        <p
          style={{
            fontSize: 16,
            fontWeight: 500,
            textAlign: "start",
            wordSpacing: "normal",
          }}
        >
          {row.text_format}
        </p>
      </TableCell>
      <TableCell
        style={{
          fontSize: 16,
          fontWeight: 500,
          textAlign: "center",
          wordSpacing: "normal",
          justifyContent: "center",
        }}
      >
        {unitToShow !== row.unit_id ? (
          row.media === "audio" ? (
            <IconButton
              onClick={() => showMediaModal(row.unit_id, row.media)}
              style={{ marginBottom: 2, textAlign: "center" }}
            >
              <FontAwesomeIcon
                icon={faVolumeHigh}
                color={"#37474F"}
                style={{ fontSize: 18, textAlign: "center" }}
              />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => showMediaModal(row.unit_id, row.media)}
              style={{ marginBottom: 2, textAlign: "center" }}
            >
              <FontAwesomeIcon
                icon={faFilm}
                style={{ fontSize: 18, textAlign: "center" }}
                color={"#004D40"}
              />
            </IconButton>
          )
        ) : (
          <IconButton
            onClick={() => showMediaModal(row.unit_id, row.media, true)}
          >
            <div
              style={{
                height: 30,
                display: "flex",
                flexDirection: "column",
                padding: 0,
                margin: 0,
              }}
            >
              <FontAwesomeIcon
                icon={faPause}
                style={{ marginLeft: 5 }}
              ></FontAwesomeIcon>
              <div id="bars">
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
              </div>
            </div>
          </IconButton>
        )}
      </TableCell>
      {projectLocation == "transcript" ? null : (
        <TableCell>
          <p style={{ fontSize: 16, fontWeight: 500, textAlign: "start" }}>
            {row.rejected_count}
          </p>
        </TableCell>
      )}

      {props.role == "QA" ? (
        <>
          {projectLocation == "transcript" ? (
            <TableCell>
              <p style={{ fontSize: 14, fontWeight: 500, textAlign: "center" }}>
                {row.units_availables_for_review}/{row.transcript_units}
              </p>
            </TableCell>
          ) : null}
          <TableCell>
            <p style={{ fontSize: 14, fontWeight: 500, textAlign: "center" }}>
              {row.tps_number}
            </p>
          </TableCell>
          <TableCell>
            <p style={{ fontSize: 14, fontWeight: 500, textAlign: "center" }}>
              {row.engine == "Manual" ? "No" : "Yes"}
            </p>
          </TableCell>
          {projectLocation == "transcript" ? (
            <TableCell>
              <p style={{ fontSize: 14, fontWeight: 500, textAlign: "center" }}>
                {row.units_availables_for_review == row.transcript_units
                  ? "Yes"
                  : "No"}
              </p>
            </TableCell>
          ) : null}
          {projectLocation == "transcript" ? null : (
            <TableCell>
              <p style={{ fontSize: 14, fontWeight: 500, textAlign: "center" }}>
                {row.total_assignments <= 20 ? (
                  <FontAwesomeIcon
                    icon={faCheckDouble}
                    color={"#FF0000"}
                    style={{ fontSize: 35, textAlign: "center" }}
                  />
                ) : null}
              </p>
            </TableCell>
          )}
        </>
      ) : null}
      <TableCell>
        <ActionsByUnitState
          group_unit={projectLocation == "transcript" ? 1 : 0}
          assigned_to={row.assigned_to}
          reviewed_by={row.reviewed_by}
          new_editor={row.new_editor}
          unit_content={row.unit_content}
          unitId={row.unit_id}
          project_id={row.project ?? row.project_id}
          transcript_id={row.transcript ?? row.transcript_id}
          state={row.state}
          transcriberHasUnit={transcriberHasUnit}
          changeUnitState={changeUnitState}
          handleChangeTranscriberState={handleChangeTranscriberState}
          openUnitError={openUnitError}
          manual_assigned={row.manual_assigned}
          role={props.role}
        />
      </TableCell>
    </TableRow>
  );
}
