import { useEffect, useState } from "react";
import useZustant from "../hooks/useZustant";
import useFetch from "../hooks/useFetch";
import useShow from "../hooks/useShow";
import {
  Box,
  Container,
  MenuItem,
  ThemeProvider,
  Typography,
  Button,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Toolbar,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import {themeWordibly as theme }  from "../theme";
import { monthsList } from "../lists";
import "../App.css";
import { useNavigate } from "react-router-dom";
import Requests from "../logic/Requests";
import CustomSnackBarAlert from "../components/global/CustomSnackBarAlert";
import PlaceNewOrderButton from "../components/PlaceNewOrderButton";

const headCells = [
  { id: "recorded_on", label: "Recorded On", canSort: true },
  { id: "placed_on", label: "Placed On", canSort: true },
  { id: "activity", label: "Activity", canSort: false },
  { id: "transaction", label: "Type of Transaction", canSort: true },
  { id: "usd", label: "USD", canSort: false },
  { id: "balance", label: "Balance", canSort: false },
];

export default function TransactionHistory() {
  const {
    setCurrentClientPage,
    changeIsInClientsSettings,
    isOpenSidebar,
    user,
    changeClientPrepaidBalance,
    clientPrepaidBalance,
  } = useZustant();
  const navigate = useNavigate();
  const { show, open, close } = useShow();
  const [currentAlertState, setCurrentAlertState] = useState({
    type: "error",
    message: "",
  });
  useEffect(() => {
    document.title = "Transaction History - Wordibly";
    setCurrentClientPage("Transaction History");
    changeIsInClientsSettings(false);
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const { data: clientInformation, isLoading } = useFetch(
    Requests.getClient,
    user.client
  );

  useEffect(() => {
    changeClientPrepaidBalance(
      clientInformation?.prepaidBalance ? clientInformation.prepaidBalance : 0
    );
  }, [isLoading]);

  // get Transaction history
  const {
    data: dataTransactionHistory,
    isLoading: isLoadingTransactionHistory,
    setData: setDataTransactionHistory,
  } = useFetch(Requests.getTransactionHistory);

  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  // export to excel file
  const handleDownloadExcel = async () => {
    try {
      const ids = filteredDataTransactionHistory?.map((element) => element._id);
      const response = await Requests.exportTransactionHistory(ids);

      // Create a Blob with the response data
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);

      // Create an anchor element to trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = "transaction_history.xlsx";
      a.click();

      // Clean up the URL and Blob
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
      handleAlert("error", error.message);
    } finally {
      handleAlert("success", "order history downloading");
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage) : 0;

  const handleAlert = (type, message) => {
    setCurrentAlertState({
      type,
      message,
    });
    open();
  };

  function formattedDate(dateStr) {
    const dateObj = new Date(dateStr);
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    const year = dateObj.getFullYear();

    return `${month}-${day}-${year}`;
  }

  // Filter data function
  const [monthFrom, setMonthFrom] = useState(
    String(new Date().getMonth()-1)
  );
  const [yearFrom, setYearFrom] = useState(
    String(new Date().getMonth()).padStart(2, "0") === "12"
      ? String(new Date().getFullYear() - 1)
      : String(new Date().getFullYear())
  );
  const [monthTo, setMonthTo] = useState(
    String(new Date().getMonth() + 1).padStart(2, "0")
  );
  const [yearTo, setYearTo] = useState(String(new Date().getFullYear()));
  const [filteredDataTransactionHistory, setFilteredDataTransactionHistory] =
    useState(null);
  const [orderBy, setOrderBy] = useState("placed_on");
  const [order, setOrder] = useState("desc");
  useEffect(() => {
    handleChange({ target: { name: "yearTo", value: yearTo } });
  }, [
    dataTransactionHistory,
    isLoadingTransactionHistory,
    monthFrom,
    yearFrom,
    monthTo,
    yearTo,
    orderBy,
    order,
  ]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const dateStates = {
      monthFrom: setMonthFrom,
      yearFrom: setYearFrom,
      monthTo: setMonthTo,
      yearTo: setYearTo,
    };

    const dateFrom = new Date(
      name === "yearFrom" ? value : yearFrom,
      name === "monthFrom" ? value : monthFrom,
      1
    );
    const dateTo = new Date(
      new Date(
        name === "yearTo" ? value : yearTo,
        name === "monthTo" ? value : +monthTo + 1,
        1
      ).getTime() -
        1 * 24 * 60 * 60 * 1000
    );

    if (dateFrom <= dateTo) {
      dateStates[name](value);
      setFilteredDataTransactionHistory(
        addMonths(
          stableSort(
            dataTransactionHistory?.filter(
              (transaction) =>
                new Date(transaction[orderBy]) >= dateFrom &&
                new Date(transaction[orderBy]) <= dateTo
            ),
            getComparator(order, orderBy)
          ),
          orderBy,
          order
        )
      );
    } else {
      //warning
      handleAlert(
        "error",
        "Invalid Date Selection - the starting date could not be lower than the end date."
      );
    }
  };

  const handleChangeRefesh = () => {
    setMonthFrom(String(new Date().getMonth()).padStart(2, "0"));
    setYearFrom(
      String(new Date().getMonth()).padStart(2, "0") === "12"
        ? String(new Date().getFullYear() - 1)
        : String(new Date().getFullYear())
    );
    setMonthTo(String(new Date().getMonth() + 1).padStart(2, "0"));
    setYearTo(String(new Date().getFullYear()));
  };

  // Format Activity
  function FormatActivity(props) {
    const { activity, project_id } = props;
    return (
      <Box>
        {activity}
                 { project_id && <Tooltip title={"Go to order details"} arrow>
                    <Button
                      onClick={() => {
                        navigate(`/orderhistory/orderDetails/${project_id}`);
                      }}
                    >
                      {project_id?.slice(-6)}
                    </Button>
                  </Tooltip>}
      </Box>
    );
  }
  // sort data
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // add months to array
  
  function addMonths(array, orderBy, order) {
    let newArray = [];
    let monthArray = [];
    if (array) {
      // create the array

      const dateTo = new Date(new Date(yearTo, monthTo - 1, 1));

      let currentDate = new Date(yearFrom, monthFrom, 1);
      let monthIndex = currentDate.getMonth();
      let year = currentDate.getFullYear();
      while (currentDate <= dateTo) {
        monthArray.push({
          lable: `${monthsList[monthIndex].fullName} ${year}`,
          date: new Date(year, monthIndex, 1),
        });

        year = monthIndex + 1 == 12 ? year + 1 : year;
        monthIndex = monthIndex + 1 == 12 ? 0 : monthIndex + 1;
        currentDate = new Date(year, monthIndex, 1);
      }
      // sort the array
      if (order == "desc") {
        monthArray = monthArray.slice().reverse();
      }
      // add customer data
  
      for (let i = 0; i < monthArray.length; i++) {
        const currentMonth = monthArray[i].date.getMonth();
        const currentYear = monthArray[i].date.getFullYear();
        newArray.push(monthArray[i]);
        newArray.push(...array.filter( e=>    new Date(e[orderBy]).getMonth() == currentMonth &&
        new Date(e[orderBy]).getFullYear() == currentYear));
      }
    }

    return newArray;
  }

  return (
    <ThemeProvider theme={theme}>
      <CustomSnackBarAlert
        type={currentAlertState.type}
        show={show}
        close={close}
      >
        {currentAlertState.message}
      </CustomSnackBarAlert>

      <Box sx={{ width: "100%" }}>
        <Container sx={{ width: "100%", pt: 3 }}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "100px",
            }}
          >
            <Typography variant="h1">Transaction History</Typography>
            <Box>
              <PlaceNewOrderButton />
            </Box>
          </Box>

          <div>
            <div style={{ marginBottom: "20px" }}>
              <span
                style={{ borderRight: "1px solid black", paddingRight: "5px" }}
              >
                Remaining prepaid balance:{" "}
                <b>${isLoading ? "loading..." : clientPrepaidBalance.toFixed(2)}</b>
              </span>

              <Button style={{marginLeft:"10px"}} variant="outlined" color="blue" onClick={() => navigate("/paymentdetails")}>
                Prepay Online
              </Button>
            </div>

            <Box sx={{ width: "100%", mb: 2 }}>
              <Toolbar
                className={
                  isOpenSidebar
                    ? "transactionToolbarSidebar"
                    : "transactionToolbar"
                }
              >
                <Box
                  className="timeForm"
                  style={{ display: "flex", alignItem: "center", gap: "24px"}}
                >
                  <Box>
                    <InputLabel style={{ marginRight: "5px" }}>
                      Show from:
                    </InputLabel>
                    <FormControl style={{ marginRight: "5px" }}>
                      <Select
                        value={monthFrom}
                        className="transactionSelect"
                        name="monthFrom"
                        onChange={handleChange}
                      >
                        {monthsList.map((month, i) => (
                          <MenuItem key={i} value={month.value - 1}>
                            {month.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <Select
                        value={yearFrom}
                        className="transactionSelect"
                        name="yearFrom"
                        onChange={handleChange}
                      >
                        <MenuItem value="2022">2022</MenuItem>
                        <MenuItem value="2023">2023</MenuItem>
                        <MenuItem value="2024">2024</MenuItem>
                        <MenuItem value="2025">2025</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box>
                    <InputLabel style={{ marginRight: "5px" }}>To:</InputLabel>
                    <FormControl style={{ marginRight: "5px" }}>
                      <Select
                        value={monthTo}
                        className="transactionSelect"
                        name="monthTo"
                        onChange={handleChange}
                      >
                        {monthsList.map((month, i) => (
                          <MenuItem key={i} value={month.value}>
                            {month.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <Select
                        value={yearTo}
                        className="transactionSelect"
                        name="yearTo"
                        onChange={handleChange}
                      >
                        <MenuItem value="2023">2023</MenuItem>
                        <MenuItem value="2024">2024</MenuItem>
                        <MenuItem value="2025">2025</MenuItem>
                      </Select>
                    </FormControl>
                    <Button
                      variant="outlined"
                      sx={{ height: "30px" }}
                      onClick={handleChangeRefesh}
                    >
                      Refresh
                    </Button>
                  </Box>
                </Box>
                <Button
                  variant="outlined"
                  className="export"
                  onClick={handleDownloadExcel}
                >
                  export as .xlsx
                </Button>
              </Toolbar>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {headCells.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            sortDirection={
                              orderBy === headCell.id ? order : false
                            }
                            style={{
                              whiteSpace: "nowrap",
                              paddingLeft:
                                headCell.id == "activity" ? "60px" : "",
                            }}
                          >
                            {headCell.canSort ? (
                              <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={
                                  orderBy === headCell.id ? order : "asc"
                                }
                                onClick={createSortHandler(headCell.id)}
                              >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                  <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                      ? "sorted descending"
                                      : "sorted ascending"}
                                  </Box>
                                ) : null}
                              </TableSortLabel>
                            ) : (
                              headCell.label
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {isLoadingTransactionHistory ? (
                        <TableRow>
                          <TableCell>loading...</TableCell>
                        </TableRow>
                      ) : (
                        <>
                          {filteredDataTransactionHistory?.map((data, i) => {
                            if (data.lable) {
                              return (
                                <TableRow key={i}>
                                  <TableCell
                                    colSpan={5}
                                    style={{
                                      width: "100%",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {data.lable}
                                  </TableCell>
                                </TableRow>
                              );
                            } else {
                              // console.log(data);
                              return (
                                <TableRow key={i}>
                                  <TableCell style={{ width: "15%" }}>
                                    {formattedDate(data.recorded_on)}
                                  </TableCell>
                                  <TableCell style={{ width: "15%" }}>
                                    {formattedDate(data.placed_on)}
                                  </TableCell>
                                  <TableCell style={{ paddingLeft: "60px" }}>
                                    <FormatActivity
                                      activity={data.activity}
                                      project_id={data.project_id}
                                    />
                                  </TableCell>
                                  <TableCell style={{ width: "15%" }}>
                                    {data.transaction}
                                  </TableCell>
                                  <TableCell style={{ width: "15%", whiteSpace: "nowrap"  }}>
                                    $ {parseFloat(data.usd).toFixed(2)}
                                  </TableCell>
                                  <TableCell style={{ width: "25%", whiteSpace: "nowrap" }}>
                                    ${" "}
                                    {data.balance
                                      ? data.balance.toFixed(2)
                                      : "0.00"}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          })}
                        </>
                      )}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: 1 * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </div>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
