import { useState, useRef, useEffect } from "react";
import { TableRow, TableCell, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import useZustant from "../../../hooks/useZustant";
import axios from "axios";
import { formatDuration, calculateTranscriptionTime, getDuration } from "./uploadFilesUtils";
import Utils from "../../../utils";
import Config from "../../../../src/config"
import Requests from "../../../../src/logic/Requests"
const estimatedDeliveryTime = [
    {
        min: 0,
        max: 10,
        medianTurnaroundTime: "2 hours",
        maxTurnaroundTime: "24 hours",
        rushMedianTurnaroundTime: "1 hour",
        rushMaxTurnaroundTime: "12 hours",
    },
    {
        min: 10,
        max: 30,
        medianTurnaroundTime: "8 hours",
        maxTurnaroundTime: "24 hours",
        rushMedianTurnaroundTime: "2 hours",
        rushMaxTurnaroundTime: "12 hours",
    },
    {
        min: 30,
        max: 60,
        medianTurnaroundTime: "16 hours",
        maxTurnaroundTime: "48 hours",
        rushMedianTurnaroundTime: "5 hours",
        rushMaxTurnaroundTime: "24 hours",
    },
    {
        min: 60,
        max: 120,
        medianTurnaroundTime: "24 hours",
        maxTurnaroundTime: "72 hours",
        rushMedianTurnaroundTime: "5 hours",
        rushMaxTurnaroundTime: "24 hours",
    },
    {
        min: 120,
        max: 9999,
        medianTurnaroundTime: "48 hours",
        maxTurnaroundTime: "72 hours",
        rushMedianTurnaroundTime: "5 hours",
        rushMaxTurnaroundTime: "24 hours",
    },
];

// const RenderCost = () => {
//     return (
//         <>
//             <h2>Render Cost</h2>
//         </>
//     )
// }

export default function FileItem({
    file,
    uploadProcess,
    setOrderSubtotal,
    orderSubtotal,
    setTotalDuration,
    deleteFile,
    orderFilesCost,
    uploadedFiles,
    currentUploadingFile,
    uploadedFilesFilesObject,
    needsHuman,
}) {
    const { newOrderType } = useZustant()
    const [durationFile, setDurationFile] = useState("");
    const [currentFileDurationInMinutes, setCurrentFileDurationInMinutes] = useState(0)
    const [complete, setComplete] = useState(false)
    const [informationUploaded, setInformationUploaded] = useState("0kb");
    const isAudioOrVideo = useRef(file.type.startsWith("audio/") || file.type.startsWith("video/"))
    const progressBar = useRef(null)
    const [cost, setCost] = useState(0)
    const { parseSize } = Utils;

    const RenderDuration = () => {
        const format = formatDuration(durationFile);
        const value = `${format.format} x ${newOrderType === "human" ? "$1.50" : "$0.25"} min`;

        if (durationFile === "") {
            return null;
        } else {
            if (isAudioOrVideo.current) {
                return <span style={{ fontWeight: 300 }}> {value} </span>;
            } else {
                return null;
            }
        }
    }

    const TimeEstimate = () => {
        const fileDuration = formatDuration(durationFile);
        const estimate = calculateTranscriptionTime(fileDuration.duration, estimatedDeliveryTime, newOrderType, needsHuman);

        if (isAudioOrVideo.current) {
            return <span>{estimate}</span>;
        } else {
            return <span>Not an audio/video file</span>;
        }
    };

    const calculateCost = () => {
        const basicRates = { automated: 0.25, human: 1.5 };
        getDuration(file).then((duration) => {
            const durationInMinutes = duration / 60;
            const rate = basicRates[newOrderType];
            setDurationFile(durationInMinutes);

            if (!isAudioOrVideo.current) return setCost(0.0)

            const currentCost = Math.ceil(durationInMinutes) * rate;
            const formattedCost = currentCost.toFixed(2);
            setCost(formattedCost)
            setOrderSubtotal(orderSubtotal + Number(formattedCost))
            orderFilesCost.current.push({ cost: Number(formattedCost), tempId: currentUploadingFile.file.tempId })
            setTotalDuration((td) => td + Math.ceil(durationInMinutes))
            setCurrentFileDurationInMinutes(durationInMinutes)
            const { file, uploadProcess, ...restOfCurrentUploadingFile } = currentUploadingFile
            const currentFileFormatted = {
                ...restOfCurrentUploadingFile,
                cost: formattedCost,
                durationInMinutes: Math.ceil(durationInMinutes),
                isAudioOrVideo: isAudioOrVideo.current,
                isUploaded: true
            }
            uploadedFilesFilesObject.current.push(currentFileFormatted)
        })
    }

    const getPresignedUrl = async () => {
        const { key } = currentUploadingFile
        const newParams = {
            Bucket: Config.awsBucketName,
            Key: key,
            ContentType: file.type,
            ACL: "private"
        };
        const signedUrl = await Requests.getSignedUrl(newParams);
        return signedUrl
    }

    const uploadFileWithPresigned = async () => {
        const signedUrl = await getPresignedUrl()
        const { file } = currentUploadingFile
        await axios.put(signedUrl, file, {
            headers: {
                'Content-Type': file.type,
            },
            onUploadProgress: (progress) => {
                const currentProgress = (progress.loaded / progress.total) * 100
                if (progressBar.current) {
                    progressBar.current.style.width = `${currentProgress}%`
                    setInformationUploaded(parseSize(progress.loaded))
                }
            },
        })
        .then((response) => {
            // Handle successful response
            calculateCost(file);
            setComplete(true);
            uploadedFiles.current.push({ tempId: file.tempId, successful: true });
        })
        .catch((error) => {
            // Handle error
            uploadedFiles.current.push({ tempId: file.tempId, successful: false });
            console.error('Error uploading file:', error);
        })
    }
    /* 
    uploadProcess.on("httpUploadProgress", (progress) => {
        const currentProgress = (progress.loaded / progress.total) * 100
        if (progressBar.current) {
            progressBar.current.style.width = `${currentProgress}%`
            setInformationUploaded(parseSize(progress.loaded))
        }
    }).send((err, data) => {
        if (err) {
            uploadedFiles.current.push({ tempId: file.tempId, succeful: false })
        } else {
            calculateCost(file)
            setComplete(true)
            uploadedFiles.current.push({ tempId: file.tempId, succeful: true })
        }
    })
    */

    useEffect(() => {
       uploadFileWithPresigned()
    }, [])

    return (
        <TableRow>
            <TableCell>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="fileName">{file.name}</div>
                    <div>
                        <Button
                            startIcon={
                                <FontAwesomeIcon
                                    icon={faTrashCan}
                                    style={{ fontSize: "14px" }}
                                />
                            }
                            disabled={!complete}
                            sx={{ fontSize: "10px", maxWidth: "90px" }}
                            color="red"
                            onClick={() => deleteFile(file.tempId, Math.ceil(currentFileDurationInMinutes))}
                        >
                            Delete
                        </Button>
                        <RenderDuration />
                    </div>
                </div>
            </TableCell>
            <TableCell>
                {complete ? (
                    <TimeEstimate />
                ) : (
                    <div className="progress">
                        <div className="progress__fill" ref={progressBar}></div>
                        <span className="progress__text">
                            {informationUploaded} / {parseSize(file.size)}
                        </span>
                    </div>
                )}
            </TableCell>
            <TableCell>
                <span style={{ whiteSpace: "nowrap" }}>$ {cost}</span>
            </TableCell>
        </TableRow>
    )
}