import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import useZustant from "../../hooks/useZustant";
import { useLocation } from "react-router-dom";
import MenuDropDown from "../MenuDropDown";
import "../../App.css";

function TranscriberTabs() {
  const { user } = useZustant();
  const navigate = useNavigate();
  const location = useLocation();
  const currentLocation = location.pathname.split("/")[1];
  const projectLocation = location.pathname.split("/")[3];

  const [value, setValue] = useState(() => {
    if (currentLocation === "mywork") {
      return 0;
    } else if (currentLocation === "projects") {
      return 1;
    } else if (currentLocation === "knowledge") {
      return 4;
    }else if (currentLocation === "readyToCombine") {
      return 3;
    }
  });
  const prevStateRef = useRef(null);

  useEffect(() => {
    if(user.rolename === "QA"){ 
      switch (currentLocation) {
        case "mywork":
          setValue(0);
          break;
        case "projects":
          setValue(1);
          break;
        case "knowledge":
          setValue(4);
          break;
        case "readyToCombine":
          setValue(3);
          break;
        default:
          setValue(0);
          break;
      }
      if(projectLocation == "transcript") {
        setValue(2);
      }
    }else {
      switch (currentLocation) {
        case "mywork":
          setValue(0);
          break;
        case "projects":
          setValue(1);
          break;
        case "knowledge":
          setValue(2);
          break;
        default:
          setValue(0);
      }
    }
  }, [location]);

  useEffect(() => {
    if (value !== null) {
      prevStateRef.current = value;
    }
  }, [value]);

  return (user.rolename === "Transcriber" ) &&
    (currentLocation === "mywork" ||
      (currentLocation === "projects" && projectLocation !== "transcript") ||
      currentLocation === "knowledge") ? (<>
        <div className="transcriberTabsMainContainer" style={{ display: 'flex', justifyContent: 'space-between', fontSize: '18px !important' }}>
          <Tabs
            value={value}
            textColor="white"
            indicatorColor="secondary"
            className="transcriberTabsContainer">
            <Tab
              sx={{ textTransform: 'capitalize' }}
              className="transcriberTabs"
              label="My Work"
              onClick={() => {
                navigate(`/mywork/${user._id}`);
              }}
            />
            <Tab
              sx={{ textTransform: 'capitalize' }}
              className="transcriberTabs"
              label="Find Work"
              onClick={() => {
                navigate(`/projects/queue/${user._id}`);
              }}
            />
            <Tab
              sx={{ textTransform: 'capitalize' }}
              className="transcriberTabs"
              label="Knowledge Center"
              onClick={() => {
                navigate(`/knowledge`);
              }}
            />
          </Tabs>
          <MenuDropDown />
        </div>
      </>
  ) : (user.rolename === "QA" ) &&
  (<>
    <div className="transcriberTabsMainContainer" style={{ display: 'flex', justifyContent: 'space-between', fontSize: '18px !important' }}>
      <Tabs
        value={value}
        textColor="white"
        indicatorColor="secondary"
        className="transcriberTabsContainer">
        <Tab
          sx={{ textTransform: 'capitalize' }}
          className="transcriberTabs"
          label="My Work"
          onClick={() => {
            navigate(`/mywork/${user._id}`);
          }}
        />
        <Tab
          sx={{ textTransform: 'capitalize' }}
          className="transcriberTabs"
          label="Find Work: Units"
          onClick={() => {
            navigate(`/projects/queue/${user._id}`);
          }}
        />
         <Tab
          sx={{ textTransform: 'capitalize' }}
          className="transcriberTabs"
          label="Find Work: Transcripts"
          onClick={() => {
            navigate(`/projects/queue/transcript/${user._id}`);
          }}
        />
        <Tab
          sx={{ textTransform: 'capitalize' }}
          className="transcriberTabs"
          label="Ready to Combine"
          onClick={() => {
            navigate(`/readyToCombine`);
          }}
        />
        <Tab
          sx={{ textTransform: 'capitalize' }}
          className="transcriberTabs"
          label="Knowledge Center"
          onClick={() => {
            navigate(`/knowledge`);
          }}
        />
      </Tabs>
      <MenuDropDown />
    </div>
  </>
);
}

export default React.memo(TranscriberTabs);
