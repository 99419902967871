import React, { useEffect, useState, useCallback } from "react";
import { Button, Menu, MenuItem, Box } from "@mui/material";
import QueueIcon from "@mui/icons-material/Queue";
import { useNavigate } from "react-router-dom";
import useZustant from "../hooks/useZustant";
import {themeWordibly as theme } from "../theme";

function PlaceNewOrderButton() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const {changeNewOrderType} = useZustant();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div style={{ display: "flex" }}>
      <Button
        variant="contained"
        className="uploadFiles"
        style={{ height: "54px", width: "250px" }}
        color="green"
        startIcon={<QueueIcon />}
        onClick={handleClick}
      >
        Place new order
      </Button>

      <Menu
        id="basic-menu"
        sx={{ mt: 1 }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            changeNewOrderType("human");
            navigate("/uploadFiles");
          }}
          sx={{ display: "flex", justifyContent: "center", width: 250 }}
        >
          <Button
            variant="outlined"
            sx={{ width: "100%", py: 1, color: "#000080" }}
            style={{ textTransform: "capitalize", fontWeight: "bold" }}
          >
            Human Transcripts
          </Button>
        </MenuItem>

        <MenuItem
          onClick={() => {
            changeNewOrderType("automated");
            navigate("/uploadFiles");
          }}
          sx={{ display: "flex", justifyContent: "center", width: 250 }}
        >
          <Button
            variant="outlined"
            sx={{ width: "100%", py: 1 }}
            style={{ textTransform: "capitalize", fontWeight: "bold", color: "#000080" }}
          >
            Automated Transcripts
          </Button>
        </MenuItem>
      </Menu>
    </div>
  );
}
export default PlaceNewOrderButton;
