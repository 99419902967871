import { React, useRef, useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import {themeWordibly as theme } from "../theme";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import {
  Typography,
  Box,
  Container,
  CardMedia,
  Grid,
} from "@mui/material";

import Requests from "../logic/Requests";
import { useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import logo from "../assets/img/wordiblyLogo.webp";
import { useReactToPrint } from 'react-to-print';


export default function OrderReceipt() {
  let { idProject } = useParams();

  const {
    data: dataOrderDetails,
    isLoading: isLoadingOrderDetails,
    setData: setDataOrderDetails,
  } = useFetch(() => Requests.getMyFilesInvoice(idProject));

  useEffect(() => {
    document.title = "Order Details - Wordibly";
  }, []);


  // transform date
  function formattedDate(dateStr) {
    const dateObj = new Date(dateStr);
    const formatted = dateObj.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return formatted;
  }

  const totalTranscripts = (array1) => {
    const initialValue = 0;

    return array1.reduce(
      (accumulator, currentValue) =>
        accumulator + Math.ceil(currentValue.length/60),
      initialValue
    );
  };
 
  // printing function 
  function formatDuration(seconds) {
    if (typeof seconds !== 'number' || isNaN(seconds)) {
      return "Invalid input";
    }
    const minutes = seconds/60;
    const hours = Math.floor(seconds / 3600);
    const remainingMinutes = Math.floor((seconds/60 % 60));
    const remainingSeconds =Math.round((minutes - remainingMinutes )*60);
  
    return `${hours > 0 ? `${hours}h ` : ''}${remainingMinutes > 0 ? `${remainingMinutes}m ` : ''}${remainingSeconds>0 ? remainingSeconds+"s" : ""  }`;
  }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
     content: () => componentRef.current,
     documentTitle: 'Wordibly Receipt of '+idProject.slice(-6),
     //onAfterPrint: () => console.log('Printed PDF successfully!'),
    });

    useEffect(()=>{
      handlePrint();
    },[isLoadingOrderDetails])

  // subcomponents
  const ProjectInfo = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignContent: "start",
          flexDirection: "column",
          gap: "3px",
          width: "450px",
          margin: "18px 0 18px 0"
        }}
      >
        <Typography variant="h2" mb={3}>Order Details - Transcription Service</Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
          }}
        >
          <Typography variant="h4">Order Numbre:</Typography>
          <Typography>{dataOrderDetails.tpsNumber}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
          }}
        >
          <Typography variant="h4">Order Placed:</Typography>
          <Typography>{formattedDate(dataOrderDetails.orderPlaced)}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
          }}
        >
          <Typography variant="h4">Reference:</Typography>
          <Typography sx={{ textTransform: "capitalize" }}>
            {dataOrderDetails.reference ?? "(none provided)"}
          </Typography>
        </Box>
      </div>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      {isLoadingOrderDetails ? (
        "loading Order data..."
      ) : (
        <>
          <Container
            sx={{
              mt: 3,
            }}
            style={{ width: "21.59cm" }}
            ref={componentRef}
          >
            <Box
              id={"header"}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                borderBottom: 1,
                pb: 1,
              }}
            >
              <CardMedia
                component="img"
                sx={{ objectFit: "contain", width: "250px" }}
                image={logo}
              />
              <Box
                gap={1}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end",
                }}
              >
                <Typography display="flex" nowrap={true}>
                  wordibly.com
                </Typography>
                <Typography>|</Typography>
                <Typography display="flex" nowrap={true}>
                  Sales Receipt
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <ProjectInfo />
            </Box>
            <Grid container spacing={2} borderBottom={1} py={1}>
              <Grid item xs={6}>
                <Box>File Name</Box>
              </Grid>
              <Grid item xs={4}>
                <Box>Duration</Box>
              </Grid>
              <Grid item xs={2}>
                <Box style={{ textAlign: "right" }}>Unit Price</Box>
              </Grid>
            </Grid>
            <Grid container spacing={1} borderBottom={1} py={2}>
              <Grid item xs={6}>
                <Box>Transcription</Box>
              </Grid>
              <Grid item xs={4}>
                <Box>
                  {formatDuration(
                    dataOrderDetails.files.reduce(
                      (accumulator, currentValue) => {
                        return accumulator + currentValue.length;
                      },
                      0
                    )
                  )}
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box style={{ textAlign: "right" }}>$ {dataOrderDetails.files[0].unitPrice.toFixed(2)}</Box>
              </Grid>
              {dataOrderDetails.files.map((e) => (
                <Grid item xs={12} fontStyle={"italic"} py={2}>
                  {e.name}
                </Grid>
              ))}
            </Grid>
            <Grid container spacing={1} borderBottom={1} py={3}>
              <Grid item xs={6}></Grid>
              <Grid item xs={4}>
                <Box>
                  Transcription ({totalTranscripts(dataOrderDetails.files)})
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box style={{ textAlign: "right" }}>
                  $ {dataOrderDetails.totalCost.toFixed(2)}
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={1} borderBottom={1} py={3}>
              <Grid item xs={6}></Grid>
              <Grid item xs={4} py={1}>
                <Box>Subtotal</Box>
              </Grid>
              <Grid item xs={2} py={1}>
                <Box style={{ textAlign: "right" }}>
                  $ {dataOrderDetails.totalCost.toFixed(2)}
                </Box>
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={4} py={1}>
                <Box>Sales Tax</Box>
              </Grid>
              <Grid item xs={2} py={1}>
                <Box style={{ textAlign: "right" }}>$ 0</Box>
              </Grid>
            </Grid>
            <Grid container spacing={1} borderBottom={1} py={3}>
              <Grid item xs={6}></Grid>
              <Grid item xs={4}>
                <Box>Total</Box>
              </Grid>
              <Grid item xs={2}>
                <Box style={{ textAlign: "right" }}>
                  $ {dataOrderDetails.totalCost.toFixed(2)}
                </Box>
              </Grid>
            </Grid>

            <Box mt={2}>
              <Box>
                <Typography variant={"h4"} py={2}>
                  Payment Details
                </Typography>
                <Grid container spacing={0}>
                  <Grid item xs={3} pt={0}>
                    Amount Paid:
                  </Grid>
                  <Grid item xs={9} pt={0}>
                    $ {dataOrderDetails.totalCost.toFixed(2)}
                  </Grid>
                  <Grid item xs={3}>
                    Billed On:
                  </Grid>
                  <Grid item xs={9} pt={0}>
                    {formattedDate(dataOrderDetails.orderPlaced)}
                  </Grid>
                  <Grid item xs={3} pt={0}>
                    Amount Paid:
                  </Grid>
                  <Grid item xs={9} pt={0}>
                    $ {dataOrderDetails.totalCost.toFixed(2)}
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Typography variant={"h4"} py={2}>
                  Order Summary
                </Typography>
                <Grid container spacing={0}>
                  <Grid item xs={3} pt={0}>
                    <Typography>Order Total:</Typography>
                  </Grid>
                  <Grid item xs={9} pt={0}>
                    $ {dataOrderDetails.totalCost.toFixed(2)}
                  </Grid>
                  <Grid item xs={3} pt={0}>
                    <Typography>Total Paid:</Typography>
                  </Grid>
                  <Grid item xs={9} pt={0}>
                    $ {dataOrderDetails.totalCost.toFixed(2)}
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Typography variant={"h4"} py={2}>
                  Customer Details
                </Typography>
                <Grid container spacing={0}>
                  <Grid item xs={3} pt={0}>
                    <Typography>Customer Name:</Typography>
                  </Grid>
                  <Grid item xs={9} pt={0} textTransform={"capitalize"}>
                    {dataOrderDetails.customerName}
                  </Grid>
                  <Grid item xs={3} pt={0}>
                    <Typography>Customer Email:</Typography>
                  </Grid>
                  <Grid item xs={9} pt={0}>
                    {dataOrderDetails.customerEmail}
                  </Grid>
                  <Grid item xs={3} pt={0}>
                    <Typography>Customer Phone:</Typography>
                  </Grid>
                  <Grid item xs={9} pt={0}>
                    {dataOrderDetails.customerPhone ?? "NA"}
                  </Grid>
                  <Grid item xs={3} pt={0}>
                    <Typography>Customer Company:</Typography>
                  </Grid>
                  <Grid item xs={9} pt={0}>
                    {dataOrderDetails.company ?? "NA"}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </>
      )}
    </ThemeProvider>
  );
}
