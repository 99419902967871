import { useEffect, useState } from "react";
import useZustant from "../hooks/useZustant";
import { ThemeProvider } from "@emotion/react";
import {themeWordibly as theme } from "../theme";
import {
  Box,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import "../App.css";
import { CountryList, StateList } from "../lists";
import CustomFormControl from "../components/global/CustomInputFormControl";
import Requests from "../logic/Requests";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";
import useAlert from "../hooks/useAlert";
import CustomSnackBarAlert from "../components/global/CustomSnackBarAlert";
import Utils from "../utils";

export default function ClientBasicInformation() {
  const { register, handleSubmit } = useForm();
  const { validateZipCode } = Utils;

  const {
    changeIsInClientsSettings,
    setCurrentClientPage,
    user,
    isOpenSidebar,
    getUser
  } = useZustant();

  const [isEditing, setIsEditing] = useState(false);

  const { show, close, currentAlertState, handleAlert } = useAlert();

  useEffect(() => {
    changeIsInClientsSettings(true);
    setCurrentClientPage("Account Details");
  }, []);

  const onSubmit = async (data) => {
    try {
      if (!validateZipCode(data.zip)) {
        handleAlert("error", "Please enter a valid zip code");
        return;
      }

      await Requests.saveUser(user.id, data).then((res) => {
        if (res.status === 200) {
          handleAlert("success", "Your information has been updated");
          setIsEditing(false);
          getUser()
        }
      });
    } catch (error) {
      handleAlert("error", "Error updating your information");
    }
  };

  const PersonalInformation = () => {
    return (
      <Paper>
        <TableContainer>
          <Table>
            <TableBody className="prueba">
              <TableRow sx={{ paddingTop: "100px !important" }}>
                <TableCell className="basicInformationCell">
                  Full Name
                </TableCell>
                <TableCell align="right" className="basicInformationCell">
                  {user.firstname + " " + user.lastname}
                </TableCell>
              </TableRow>
              {/* Hidding sections
              <TableRow>
                <TableCell className="basicInformationCell">Company</TableCell>
                <TableCell
                  align="right"
                  className="basicInformationCell"
                ></TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell className="basicInformationCell">Address</TableCell>
                <TableCell align="right" className="basicInformationCell">
                  {user.address1}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="basicInformationCell">
                  Phone Number
                </TableCell>

                <TableCell align="right" className="basicInformationCell">
                  {user.phone}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  };

  const EditPersonalInformation = () => {
    return (
      <form
        className={isOpenSidebar ? null : "basicInformationForm"}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="formContainer">
          <CustomFormControl
            label="First Name"
            fieldId="firstname"
            currentValue={user.firstname}
            type="text"
            register={register}
            required
          />

          <CustomFormControl
            label="Last Name"
            fieldId="lastname"
            currentValue={user.lastname}
            type="text"
            register={register}
            required
          />
        </div>

        {/* Hidding section
        <CustomFormControl
          label="Company (Optional)"
          fieldId="company"
          currentValue={user.company}
          type="text"
          register={register}
        /> */}

        <CustomFormControl
          register={register}
          label="Address 1"
          fieldId="address1"
          currentValue={user.address1}
          type="text"
          required
        />

        <CustomFormControl
          register={register}
          label="Address 2"
          fieldId="address2"
          currentValue={user.address2}
          type="text"
        />

        <CustomFormControl
          register={register}
          label="City"
          fieldId="city"
          currentValue={user.city}
          type="text"
          required
        />

        <div className="formContainer">
          <CustomFormControl
            label="Country"
            fieldId="country"
            currentValue={user.country}
            type="select"
            list={CountryList}
            register={register}
            required
          />

          <CustomFormControl
            label="State"
            fieldId="state"
            currentValue={user.state}
            type="select"
            list={StateList}
            register={register}
            required
          />

          <CustomFormControl
            label="Zip"
            fieldId="zip"
            currentValue={user.zip}
            type="text"
            register={register}
            required
          />
        </div>

        <CustomFormControl
          register={register}
          label="Phone"
          fieldId="phone"
          currentValue={user.phone}
          type="text"
          required
        />

        <Button
          variant="contained"
          type="submit"
          sx={{
            marginRight: "auto",
            padding: "10px 20px",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          Save
        </Button>
      </form>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CustomSnackBarAlert
        type={currentAlertState.type}
        show={show}
        close={close}
      >
        {currentAlertState.message}
      </CustomSnackBarAlert>
      <Box sx={{ width: "100%" }}>
        <Container sx={{ width: "100%", pt: 3 }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h1" sx={{ mb: 3 }}>
            {isEditing ? "Edit Account Details" : "Account Details"}
            </Typography>

            <Button
              onClick={() => setIsEditing(!isEditing)}
              sx={{ textTransform: "none" }}
              startIcon={
                !isEditing ? null : (
                  <FontAwesomeIcon icon={faChevronCircleLeft} />
                )
              }
            >
              {isEditing ? "Return to Account Details" : "EDIT"}
            </Button>
          </div>
          {isEditing ? <EditPersonalInformation /> : <PersonalInformation />}
        </Container>
      </Box>
    </ThemeProvider>
  );
}
