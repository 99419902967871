export const StateList = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AS", label: "American Samoa" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "DC", label: "District of Columbia" },
  { value: "FM", label: "Federated States of Micronesia" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "GU", label: "Guam" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NU", label: "Non-US" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PW", label: "Palau" },
  { value: "PA", label: "Pennsylvania" },
  { value: "PR", label: "Puerto Rico" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VI", label: "Virgin Islands" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];

export const CountryList = [
  { value: "US", label: "United States of America" },
  { value: "AF", label: "Afghanistan, Islamic Republic of" },
  { value: "AX", label: "Åland Islands" },
  { value: "AL", label: "Albania, Republic of" },
  { value: "DZ", label: "Algeria, People's Democratic Republic of" },
  { value: "AS", label: "American Samoa" },
  { value: "AD", label: "Andorra, Principality of" },
  { value: "AO", label: "Angola, Republic of" },
  { value: "AI", label: "Anguilla" },
  { value: "AQ", label: "Antarctica (the territory South of 60 deg S)" },
  { value: "AG", label: "Antigua and Barbuda" },
  { value: "AR", label: "Argentina, Argentine Republic" },
  { value: "AM", label: "Armenia, Republic of" },
  { value: "AW", label: "Aruba" },
  { value: "AU", label: "Australia, Commonwealth of" },
  { value: "AT", label: "Austria, Republic of" },
  { value: "AZ", label: "Azerbaijan, Republic of" },
  { value: "BS", label: "Bahamas, Commonwealth of the" },
  { value: "BH", label: "Bahrain, Kingdom of" },
  { value: "BD", label: "Bangladesh, People's Republic of" },
  { value: "BB", label: "Barbados" },
  { value: "BY", label: "Belarus, Republic of" },
  { value: "BE", label: "Belgium, Kingdom of" },
  { value: "BZ", label: "Belize" },
  { value: "BJ", label: "Benin, Republic of" },
  { value: "BM", label: "Bermuda" },
  { value: "BT", label: "Bhutan, Kingdom of" },
  { value: "BO", label: "Bolivia, Republic of" },
  { value: "BA", label: "Bosnia and Herzegovina" },
  { value: "BW", label: "Botswana, Republic of" },
  { value: "BV", label: "Bouvet Island (Bouvetoya)" },
  { value: "BR", label: "Brazil, Federative Republic of" },
  {
    value: "IO",
    label: "British Indian Ocean Territory (Chagos Archipelago)",
  },
  { value: "VG", label: "British Virgin Islands" },
  { value: "BN", label: "Brunei Darussalam" },
  { value: "BG", label: "Bulgaria, Republic of" },
  { value: "BF", label: "Burkina Faso" },
  { value: "BI", label: "Burundi, Republic of" },
  { value: "KH", label: "Cambodia, Kingdom of" },
  { value: "CM", label: "Cameroon, Republic of" },
  { value: "CA", label: "Canada" },
  { value: "CV", label: "Cape Verde, Republic of" },
  { value: "KY", label: "Cayman Islands" },
  { value: "CF", label: "Central African Republic" },
  { value: "TD", label: "Chad, Republic of" },
  { value: "CL", label: "Chile, Republic of" },
  { value: "CN", label: "China, People's Republic of" },
  { value: "CX", label: "Christmas Island" },
  { value: "CC", label: "Cocos (Keeling) Islands" },
  { value: "CO", label: "Colombia, Republic of" },
  { value: "KM", label: "Comoros, Union of the" },
  { value: "CD", label: "Congo, Democratic Republic of the" },
  { value: "CG", label: "Congo, Republic of the" },
  { value: "CK", label: "Cook Islands" },
  { value: "CR", label: "Costa Rica, Republic of" },
  { value: "CI", label: "Cote d'Ivoire, Republic of" },
  { value: "HR", label: "Croatia, Republic of" },
  { value: "CU", label: "Cuba, Republic of" },
  { value: "CY", label: "Cyprus, Republic of" },
  { value: "CZ", label: "Czech Republic" },
  { value: "DK", label: "Denmark, Kingdom of" },
  { value: "DJ", label: "Djibouti, Republic of" },
  { value: "DM", label: "Dominica, Commonwealth of" },
  { value: "DO", label: "Dominican Republic" },
  { value: "EC", label: "Ecuador, Republic of" },
  { value: "EG", label: "Egypt, Arab Republic of" },
  { value: "SV", label: "El Salvador, Republic of" },
  { value: "GQ", label: "Equatorial Guinea, Republic of" },
  { value: "ER", label: "Eritrea, State of" },
  { value: "EE", label: "Estonia, Republic of" },
  { value: "ET", label: "Ethiopia, Federal Democratic Republic of" },
  { value: "FK", label: "Falkland Islands (Malvinas)" },
  { value: "FO", label: "Faroe Islands" },
  { value: "FJ", label: "Fiji, Republic of the Fiji Islands" },
  { value: "FI", label: "Finland, Republic of" },
  { value: "FR", label: "France, French Republic" },
  { value: "GF", label: "French Guiana" },
  { value: "PF", label: "French Polynesia" },
  { value: "TF", label: "French Southern Territories" },
  { value: "GA", label: "Gabon, Gabonese Republic" },
  { value: "GM", label: "Gambia, Republic of the" },
  { value: "GE", label: "Georgia" },
  { value: "DE", label: "Germany, Federal Republic of" },
  { value: "GH", label: "Ghana, Republic of" },
  { value: "GI", label: "Gibraltar" },
  { value: "GR", label: "Greece, Hellenic Republic" },
  { value: "GL", label: "Greenland" },
  { value: "GD", label: "Grenada" },
  { value: "GP", label: "Guadeloupe" },
  { value: "GU", label: "Guam" },
  { value: "GT", label: "Guatemala, Republic of" },
  { value: "GG", label: "Guernsey, Bailiwick of" },
  { value: "GN", label: "Guinea, Republic of" },
  { value: "GW", label: "Guinea-Bissau, Republic of" },
  { value: "GY", label: "Guyana, Co-operative Republic of" },
  { value: "HT", label: "Haiti, Republic of" },
  { value: "HM", label: "Heard Island and McDonald Islands" },
  { value: "VA", label: "Holy See (Vatican City State)" },
  { value: "HN", label: "Honduras, Republic of" },
  { value: "HK", label: "Hong Kong, Special Administrative Region of China" },
  { value: "HU", label: "Hungary, Republic of" },
  { value: "IS", label: "Iceland, Republic of" },
  { value: "IN", label: "India, Republic of" },
  { value: "ID", label: "Indonesia, Republic of" },
  { value: "IR", label: "Iran, Islamic Republic of" },
  { value: "IQ", label: "Iraq, Republic of" },
  { value: "IE", label: "Ireland" },
  { value: "IM", label: "Isle of Man" },
  { value: "IL", label: "Israel, State of" },
  { value: "IT", label: "Italy, Italian Republic" },
  { value: "JM", label: "Jamaica" },
  { value: "JP", label: "Japan" },
  { value: "JE", label: "Jersey, Bailiwick of" },
  { value: "JO", label: "Jordan, Hashemite Kingdom of" },
  { value: "KZ", label: "Kazakhstan, Republic of" },
  { value: "KE", label: "Kenya, Republic of" },
  { value: "KI", label: "Kiribati, Republic of" },
  { value: "KP", label: "Korea, Democratic People's Republic of" },
  { value: "KR", label: "Korea, Republic of" },
  { value: "KW", label: "Kuwait, State of" },
  { value: "KG", label: "Kyrgyz Republic" },
  { value: "LA", label: "Lao People's Democratic Republic" },
  { value: "LV", label: "Latvia, Republic of" },
  { value: "LB", label: "Lebanon, Lebanese Republic" },
  { value: "LS", label: "Lesotho, Kingdom of" },
  { value: "LR", label: "Liberia, Republic of" },
  { value: "LY", label: "Libyan Arab Jamahiriya" },
  { value: "LI", label: "Liechtenstein, Principality of" },
  { value: "LT", label: "Lithuania, Republic of" },
  { value: "LU", label: "Luxembourg, Grand Duchy of" },
  { value: "MO", label: "Macao, Special Administrative Region of China" },
  { value: "MK", label: "Macedonia, the former Yugoslav Republic of" },
  { value: "MG", label: "Madagascar, Republic of" },
  { value: "MW", label: "Malawi, Republic of" },
  { value: "MY", label: "Malaysia" },
  { value: "MV", label: "Maldives, Republic of" },
  { value: "ML", label: "Mali, Republic of" },
  { value: "MT", label: "Malta, Republic of" },
  { value: "MH", label: "Marshall Islands, Republic of the" },
  { value: "MQ", label: "Martinique" },
  { value: "MR", label: "Mauritania, Islamic Republic of" },
  { value: "MU", label: "Mauritius, Republic of" },
  { value: "YT", label: "Mayotte" },
  { value: "MX", label: "Mexico, United Mexican States" },
  { value: "FM", label: "Micronesia, Federated States of" },
  { value: "MD", label: "Moldova, Republic of" },
  { value: "MC", label: "Monaco, Principality of" },
  { value: "MN", label: "Mongolia" },
  { value: "ME", label: "Montenegro, Republic of" },
  { value: "MS", label: "Montserrat" },
  { value: "MA", label: "Morocco, Kingdom of" },
  { value: "MZ", label: "Mozambique, Republic of" },
  { value: "MM", label: "Myanmar, Union of" },
  { value: "NA", label: "Namibia, Republic of" },
  { value: "NR", label: "Nauru, Republic of" },
  { value: "NP", label: "Nepal, State of" },
  { value: "AN", label: "Netherlands Antilles" },
  { value: "NL", label: "Netherlands, Kingdom of the" },
  { value: "NC", label: "New Caledonia" },
  { value: "NZ", label: "New Zealand" },
  { value: "NI", label: "Nicaragua, Republic of" },
  { value: "NE", label: "Niger, Republic of" },
  { value: "NG", label: "Nigeria, Federal Republic of" },
  { value: "NU", label: "Niue" },
  { value: "NF", label: "Norfolk Island" },
  { value: "MP", label: "Northern Mariana Islands, Commonwealth of the" },
  { value: "NO", label: "Norway, Kingdom of" },
  { value: "OM", label: "Oman, Sultanate of" },
  { value: "PK", label: "Pakistan, Islamic Republic of" },
  { value: "PW", label: "Palau, Republic of" },
  { value: "PS", label: "Palestinian Territory, Occupied" },
  { value: "PA", label: "Panama, Republic of" },
  { value: "PG", label: "Papua New Guinea, Independent State of" },
  { value: "PY", label: "Paraguay, Republic of" },
  { value: "PE", label: "Peru, Republic of" },
  { value: "PH", label: "Philippines, Republic of the" },
  { value: "PN", label: "Pitcairn Islands" },
  { value: "PL", label: "Poland, Republic of" },
  { value: "PT", label: "Portugal, Portuguese Republic" },
  { value: "PR", label: "Puerto Rico, Commonwealth of" },
  { value: "QA", label: "Qatar, State of" },
  { value: "RE", label: "Reunion" },
  { value: "RO", label: "Romania" },
  { value: "RU", label: "Russian Federation" },
  { value: "RW", label: "Rwanda, Republic of" },
  { value: "WS", label: "Samoa, Independent State of" },
  { value: "SM", label: "San Marino, Republic of" },
  { value: "ST", label: "Sao Tome and Principe, Democratic Republic of" },
  { value: "SA", label: "Saudi Arabia, Kingdom of" },
  { value: "SN", label: "Senegal, Republic of" },
  { value: "RS", label: "Serbia, Republic of" },
  { value: "SC", label: "Seychelles, Republic of" },
  { value: "SL", label: "Sierra Leone, Republic of" },
  { value: "SG", label: "Singapore, Republic of" },
  { value: "SK", label: "Slovakia (Slovak Republic)" },
  { value: "SI", label: "Slovenia, Republic of" },
  { value: "SB", label: "Solomon Islands" },
  { value: "SO", label: "Somalia, Somali Republic" },
  { value: "ZA", label: "South Africa, Republic of" },
  { value: "GS", label: "South Georgia and the South Sandwich Islands" },
  { value: "ES", label: "Spain, Kingdom of" },
  { value: "LK", label: "Sri Lanka, Democratic Socialist Republic of" },
  { value: "SH", label: "St. Helena" },
  { value: "KN", label: "St. Kitts and Nevis, Federation of" },
  { value: "LC", label: "St. Lucia" },
  { value: "PM", label: "St. Pierre and Miquelon" },
  { value: "VC", label: "St. Vincent and the Grenadines" },
  { value: "SD", label: "Sudan, Republic of" },
  { value: "SR", label: "Suriname, Republic of" },
  { value: "SJ", label: "Svalbard &amp; Jan Mayen Islands" },
  { value: "SZ", label: "Swaziland, Kingdom of" },
  { value: "SE", label: "Sweden, Kingdom of" },
  { value: "CH", label: "Switzerland, Swiss Confederation" },
  { value: "SY", label: "Syrian Arab Republic" },
  { value: "TW", label: "Taiwan" },
  { value: "TJ", label: "Tajikistan, Republic of" },
  { value: "TZ", label: "Tanzania, United Republic of" },
  { value: "TH", label: "Thailand, Kingdom of" },
  { value: "TL", label: "Timor-Leste, Democratic Republic of" },
  { value: "TG", label: "Togo, Togolese Republic" },
  { value: "TK", label: "Tokelau" },
  { value: "TO", label: "Tonga, Kingdom of" },
  { value: "TT", label: "Trinidad and Tobago, Republic of" },
  { value: "TN", label: "Tunisia, Tunisian Republic" },
  { value: "TR", label: "Turkey, Republic of" },
  { value: "TM", label: "Turkmenistan" },
  { value: "TC", label: "Turks and Caicos Islands" },
  { value: "TV", label: "Tuvalu" },
  { value: "UG", label: "Uganda, Republic of" },
  { value: "UA", label: "Ukraine" },
  { value: "AE", label: "United Arab Emirates" },
  { value: "GB", label: "United Kingdom of Great Britain &amp; N. Ireland" },
  { value: "UM", label: "United States Minor Outlying Islands" },
  { value: "UY", label: "Uruguay, Eastern Republic of" },
  { value: "VI", label: "US Virgin Islands" },
  { value: "UZ", label: "Uzbekistan, Republic of" },
  { value: "VU", label: "Vanuatu, Republic of" },
  { value: "VE", label: "Venezuela, Bolivarian Republic of" },
  { value: "VN", label: "Vietnam, Socialist Republic of" },
  { value: "WF", label: "Wallis and Futuna" },
  { value: "EH", label: "Western Sahara" },
  { value: "YE", label: "Yemen" },
  { value: "ZM", label: "Zambia, Republic of" },
  { value: "ZW", label: "Zimbabwe, Republic of" },
];

export const monthsList = [
  { value: "01", label: "Jan", fullName: "January" },
  { value: "02", label: "Feb", fullName: "February" },
  { value: "03", label: "Mar", fullName: "March" },
  { value: "04", label: "Apr", fullName: "April" },
  { value: "05", label: "May", fullName: "May" },
  { value: "06", label: "Jun", fullName: "June" },
  { value: "07", label: "Jul", fullName: "July" },
  { value: "08", label: "Aug", fullName: "August" },
  { value: "09", label: "Sep", fullName: "September" },
  { value: "10", label: "Oct", fullName: "October" },
  { value: "11", label: "Nov", fullName: "November" },
  { value: "12", label: "Dec", fullName: "December" },
];

export const yearList = [
  { value: "2022" },
  { value: "2023" },
  { value: "2024" },
  { value: "2025" },
  { value: "2026" },
  { value: "2027" },
  { value: "2028" },
  { value: "2029" },
];
