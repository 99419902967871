import { Box, Button, Typography } from "@mui/material";
import { useContext } from "react";
import { themeWordibly as theme } from "../../../theme";
import { Lock } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ComputerIcon from "@mui/icons-material/Computer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import CloudIcon from "@mui/icons-material/Cloud";
import { UploadFilesContext } from "./context/UploadFilesContext";

const BasicInputFile = ({ getInputProps, open }) => {
  return (
    <div className="insideFile">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            marginBottom: "10px",
            position: "relative",
          }}
        >
          <ComputerIcon fontSize="large" color="primary" />
          <ArrowForwardIcon fontSize="large" color="primary" />
          <CloudIcon fontSize="large" color="primary" />
        </div>

        <Typography style={{ color: "black" }} variant="p">
          Upload files from your computer or drag and drop here
        </Typography>
      </div>
      <input hidden multiple type="file" {...getInputProps()}  />
      <Button
        variant="contained"
        color="primary"
        component="label"
        className="uploadButton"
        onClick={()=>open()}
      >
        Upload
      </Button>
    </div>
  );
};

const DropInput = ({ getInputProps }) => {
  return (
    <div
            style={
                {
        display: "flex",
        flexDirection: "column",
        alingItems: "center",
        pointerEvents: "none",
                }
            }
    >
      <CopyAllIcon
        fontSize="inherit"
        sx={{
          fontSize: "70px",
          pointerEvents: "none",
          margin: "auto",
          marginBottom: "30px",
        }}
      />
      <Typography
        variant="h2"
        sx={{ fontWeight: "200", pointerEvents: "none" }}
      >
        Drop files here
      </Typography>
      <input hidden multiple type="file" accept=".mp3, .mp4" {...getInputProps()} />
    </div>
  );
};

export default function UploadFilesView() {
    const navigate = useNavigate()
  const {
    files,
    isDragHover,
    setIsDragHover,
    getRootProps,
    getInputProps,
    open,
    uploadingFiles
    } = useContext(UploadFilesContext)

  return (
    <>
      <Box
        sx={{ display: "flex", justifyContent: "center" }}
        width={"100%"}
        mt={2}
      >
        <Box
          width={"71%"}
          sx={{ pl: 5, display: "flex", justifyContent: "center" }}
        >
          <Box
            height="292px"
            width="292px"
            {...(files.length === 0 ? { ...getRootProps() } : null)}
            className={
              files.length > 0
                ? "filesToUpload"
                : isDragHover
                ? "dragAndDrop"
                : "uploadFilesInputContainer"
            }
            sx={false ? { display: "none" } : null}
            onDragOver={() => setIsDragHover(true)}
            onDragLeave={() => setIsDragHover(false)}
          >
            {isDragHover ? (
              <DropInput getInputProps={getInputProps} />
            ) : (
              <BasicInputFile getInputProps={getInputProps} open={open}/>
            )}
          </Box>
        </Box>
      </Box>
      <Box style={{ padding: "2rem", textAlign: "center" }}>
        <Typography>
          Easily Drag and Drop your audio or video files or upload from your
          device!
        </Typography>
        <Typography>
          Your custom Rate and enhancement choices will be calculated so you can
          decide what is right for your project.{" "}
        </Typography>
        <Typography>
          With Wordibly, what you want is what matters. You have options. Come
          see!{" "}
        </Typography>
      </Box>
    </>
  );
}
