import {
  Modal,
  Box,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import "../../App.css";
import Requests from "../../logic/Requests";
import { useNavigate, useParams } from "react-router-dom";

const options = [
  {
    value: "unsupportedLanguage",
    label: "Unsupported Language",
  },
  {
    value: "noSpeech",
    label: "No Speech Detected",
  },
  {
    value: "fileLong",
    label: "File is too long",
  },
  {
    value: "accent",
    label: "Difficult Accent",
  },
  {
    value: "poorQuality",
    label: "Poor Audio Quality",
  },
  {
    value: "difficultTopic",
    label: "Difficult topic to transcribe",
  },
  {
    value: "time",
    label: "Do not have enough time to complete",
  },
  {
    value: "alignmentIssue",
    label: "Alignment Issue",
  },
  {
    value: "other",
    label: "Other",
  },
];

export default function RejectUnitModal(props) {
  const { show, close, user, handleAlert } = props;
  let { idUnit } = useParams();
  const [currentSelect, setCurrentSelect] = useState(null);
  const [other, setOther] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentSelect(null);
  }, [show]);

  const reject = () => {
    try {
      if (currentSelect) {
        let rejectReason;
        if (currentSelect === "other") {
          if (other === "") {
            handleAlert("error", "Please write a reason");
            return;
          }
          rejectReason = other;
        } else {
          rejectReason = options.find(
            (option) => option.value === currentSelect
          ).label;
        }

        Requests.reject(idUnit, {...user, rejectReason}).then(() => {
          navigate(`/`);
        });
      } else {
        handleAlert("error", "Please select a reason");
      }
    } catch (error) {
      handleAlert("error", "There was an error in rejecting the unit");
    }
  };

  return (
    <Modal open={show} onClose={close}>
      <Box
        sx={{
          boxShadow: 24,
          p: 4,
          bgcolor: "background.paper",
        }}
        className="rejectModal"
      >
        <Typography variant="h4" sx={{ fontWeight: 400, marginBottom: "10px" }}>
          Please tell us why you are unassigning this unit.
        </Typography>
        <FormControl>
          <RadioGroup
            onChange={(e) => {
              setCurrentSelect(e.target.value);
            }}
          >
            {options.map(((option, key) => {
              return (
                <FormControlLabel
                  value={option.value}
                  control={<Radio size="small" />}
                  label={option.label}
                  key={key}
                />
              );
            }))}
          </RadioGroup>
        </FormControl>
        <TextField
          fullWidth
          onChange={(e) => {
            setOther(e.target.value);
          }}
          sx={
            !(currentSelect === "other")
              ? { display: "none", width: "100% !important" }
              : null
          }
          multiline
          rows={2}
          maxRows={4}
        />

        <Box sx={{ mt: 2 }} className="rejectButtonsContainer">
          <Button variant="contained" color="red" onClick={() => close()}>
            Cancel
          </Button>
          <Button variant="contained" sx={{backgroundColor:"#3f51b5"}} onClick={() => reject()}>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
