import {
  Typography,
  Box,
  Link,
} from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import {themeWordibly } from "../../theme";
import wordiblyLogoWhite from "../../assets/img/wordiblyLogoWhite.svg";

export default function WordiblyFooter({isFullWidth}) {

  return (
    <ThemeProvider theme={themeWordibly}>
        <footer className="wordiblyFooter" 
        style={{  width:isFullWidth?"100%":""}}
        >
          <div id="triangle-topleft"></div>
          <Box style={{ backgroundColor: "#141414", width:"100%"}}>
            <Box style={{ zIndex:"2" }}>
              <Box>
                <Box className="wordiblyDiagonalFooterContent">
                  <Box style={{ width: "273px", marginLeft: "24px" }}>
                    <ul
                      className="wordiblyFooterPanel"
                      style={{ listStyleType: "none", padding: 0 }}
                    >
                      <li>
                        <Link href="https://wordibly.com">
                          <img
                            width={"189px"}
                            src={wordiblyLogoWhite}
                            alt="wordibly logo"
                            className="navbarLogo"
                          />
                        </Link>
                      </li>
                      <li>
                        <Typography variant="p">
                          950 West Valley Road Suite 2700 Wayne, PA 19087
                        </Typography>{" "}
                      </li>
                      <li>
                        <Typography variant="p">215.367.4040</Typography>
                      </li>
                      <li>
                        <Link href="mailto:contactus@wordibly.com">
                          contactus@wordibly.com
                        </Link>
                      </li>
                    </ul>
                  </Box>
                  <Box
                    className="gridPannelContainer"
                    style={{ padding: 0, margin: 0, marginRight: "24px" }}
                  >
                    <Box className="gridPannel" style={{ width: "241px" }}>
                      <ul className="wordiblyFooterPanel">
                        <li>
                          <Typography
                            variant="h2"
                            color={themeWordibly.palette.green.main}
                            onClick={() => {
                              window.location.href =
                                "https://wordibly.com/services/";
                            }}
                          >
                            Services
                          </Typography>
                        </li>
                        <li>
                          <Link href="https://wordibly.com/services/human-transcription/">Human Transcription</Link>
                        </li>
                        <li>
                          <Link href="https://wordibly.com/#">Automated Transcription</Link>
                        </li>
                        <li>
                          <Link href="https://wordibly.com/services/global-translation/">Globel Transcription</Link>
                        </li>
                        <li>
                          <Link href="https://wordibly.com/services/global-translation/#">Captions - Coming Soon</Link>
                        </li>
                        <li>
                          <Link href="https://wordibly.com/services/enterprise-level-business-services/">Enterprise Level Business Services</Link>
                        </li>
                      </ul>
                      <ul className="wordiblyFooterPanel">
                        <li>
                          <Typography
                            variant="h2"
                            color={themeWordibly.palette.green.main}
                            onClick={()=>window.location.href="https://wordibly.com/pricing/"}
                          >
                            Pricing
                          </Typography>
                        </li>
                      </ul>
                    </Box>
                    <Box
                      item
                      xs={1}
                      className="gridPannel"
                      style={{ width: "241px" }}
                    >
                      <ul className="wordiblyFooterPanel">
                        <li>
                          <Typography
                            variant="h2"
                            color={themeWordibly.palette.green.main}
                          >
                            Industries
                          </Typography>
                        </li>
                        <li>
                          <Link href="https://wordibly.com/marketing-research-transcription/">Marketing Research Transcription</Link>
                        </li>
                        <li>
                          <Link href="https://wordibly.com/academic-transcription/">Academic Transcription</Link>
                        </li>
                        <li>
                          <Link href="https://wordibly.com/audio-transcription/">Audio Transcription</Link>
                        </li>
                        <li>
                          <Link href="https://wordibly.com/medical-transcription/">Medical Transcription</Link>
                        </li>
                        <li>
                          <Link href="https://wordibly.com/podcast-transcription/">Podcast Transcription</Link>
                        </li>
                      </ul>
                      <ul className="wordiblyFooterPanel">
                        <li>
                          <Typography
                            variant="h2"
                            color={themeWordibly.palette.green.main}
                            onClick={()=>window.location.href="https://wordibly.com/resources/help-center/#"}
                          >
                            Company
                          </Typography>
                        </li>
                        <li>
                          <Link href="https://wordibly.com/about-wordibly/">About Wordibly</Link>
                        </li>
                        <li>
                          <Link href="https://wordibly.com/careers/">Careers</Link>
                        </li>
                      </ul>
                    </Box>
                    <Box item xs={1} className="gridPannel">
                      <ul className="wordiblyFooterPanel">
                        <li>
                          <Typography
                            variant="h2"
                            color={themeWordibly.palette.green.main}
                            onClick={()=>window.location.href="https://wordibly.com/audio-transcription/#"}
                          >
                            Developers
                          </Typography>
                        </li>
                        <li>
                          <Link href="https://wordibly.com/audio-transcription/#">Wordibly for Developers</Link>
                        </li>
                        <li>
                          <Link href="https://wordibly.com/audio-transcription/#">API Instructions</Link>
                        </li>
                      </ul>
                      <ul className="wordiblyFooterPanel">
                        <li>
                          <Typography
                            variant="h2"
                            color={themeWordibly.palette.green.main}
                            onClick={()=>window.location.href="https://wordibly.com/audio-transcription/#"}
                          >
                            Resources
                          </Typography>
                        </li>
                        <li>
                          <Link href="https://wordibly.com/resources/blog/">Blog</Link>
                        </li>
                        <li>
                          <Link href="https://wordibly.com/resources/blog/#">Become a Freelancer</Link>
                        </li>
                        <li>
                          <Link href="https://wordibly.com/resources/why-wordibly/">Why Wordibly</Link>
                        </li>
                        <li>
                          <Link href="https://wordibly.com/resources/help-center/">Help Center</Link>
                        </li>
                      </ul>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box style={{ height: "1px", backgroundColor: "white" }}></Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "white",
                  // width: "1300px",
                  marginTop:"10px",
                  marginBottom:"30px",
                  fontSize:"14px",
                  paddingRight: "32px",
                  paddingLeft: "32px",
                  
                }}
              >
                <Box>©2023 Wordibly - All Rights Reserved.</Box>
                <Box>
                  <ul
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      color: "white",
                      listStyleType: "none",
                      textDecoration: "none",
                      gap:"20px",
                    }}
                  >
                    <li><Link href="https://wordibly.com/legal/">Legal Center</Link></li>
                    <li><Link href="https://wordibly.com/wordibly-hippa/">Wordibly HIPPA</Link></li>
                    <li><Link href="https://wordibly.com/wordibly-confidentiality-agreement/">Confidentiality</Link></li>
                    <li><Link href="https://wordibly.com/terms-of-service/">Terms</Link></li>
                    <li><Link href="https://wordibly.com/privacy-policy/">Privacy</Link></li>
                    <li><Link href="https://wordibly.com/wordibly-sitemap/">Sitemap</Link></li>
                  </ul>
                </Box>
              </Box>
            </Box>
          </Box>
        </footer>
    </ThemeProvider>
  );
}
