import useZustant from "../../../hooks/useZustant";
import Iframe from "react-iframe";
import { React, useEffect, useState } from "react";
import { themeWordibly as theme } from "../../../theme";

import { Box, ThemeProvider } from "@mui/material";

export default function PaymentCheckout() {
  const { paymentLink } = useZustant();
  const [buttonClicked, setButtonClicked] = useState();

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          padding: "0",
          margin: "0",
          position: "relative",
        }}
        className="squareQuickCheckoutContainer"
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            padding: "0",
            margin: "0",
            position: "absolute",
            top: "-100px",
          }}
        >
          {paymentLink && (
            <Iframe
              url={paymentLink}
              sandbox={[
                "allow-top-navigation",
                "allow-scripts",
                "allow-same-origin",
              ]}
              id="iframe"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{
                display: "block",
                margin: "0",
                // position: "absolute",
              }}
              // scrolling="no"
            />
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
