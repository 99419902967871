import "../../styles/TranscriberQueue.css";
import { TableRow, TableCell } from "@mui/material";
import moment from "moment";
import useZustant from "../../hooks/useZustant";
import Config from "../../config/index";

export default function ReadyToCombineHubRow(props) {
  const { user } = useZustant();
  const {
    row
  } = props;

  return (
    <TableRow key={row.transcript_id}>
      <TableCell>
        <p style={{ fontSize: 16, fontWeight: 500, textAlign: "start" }}>
        <a href={`${Config.oldUI}/pm/${row.project_manager_id}/projects/`}>{`${row.project_manager_name}`}</a>
        </p>
      </TableCell>
      <TableCell>
      <p style={{ fontSize: 16, fontWeight: 500, textAlign: "start" }}>
        <a href={`${Config.oldUI}/projects/${row.project_id}`}>{`${row.project_name}`}</a>
      </p>
      </TableCell>
      <TableCell>
      <p style={{ fontSize: 16, fontWeight: 500, textAlign: "start" }}>
      <a href={`${Config.oldUI}/projects/${row.project_manager_id}/transcript/${row.transcript_id}`}> {`${row.transcript_name}`}</a>
        </p>
      </TableCell>
      <TableCell>
      <p style={{ fontSize: 16, fontWeight: 500, textAlign: "start" }}>
          {`${row.num_unit}`}
        </p>
      </TableCell>
      <TableCell>
        <p style={{ fontSize: 16, fontWeight: 500, textAlign: "start" }}>
          {moment(row.due_date).format("YYYY-MM-DD")}
        </p>
      </TableCell>
      <TableCell>
        <p style={{ fontSize: 16, fontWeight: 500, textAlign: "start" }}>
          {moment(row.due_date).format("HH:mm a")}
        </p>
      </TableCell>
      <TableCell
        sx={{
          minWidth: "100px",
        }}
      >
        <p style={{ fontSize: 16, fontWeight: 500, textAlign: "start" }}>
          {moment(row.due_date).fromNow()}
        </p>
      </TableCell>
    </TableRow>
  );
}