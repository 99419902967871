import * as React from "react";
import {useState} from "react";
import useZustant from "../../hooks/useZustant";
import CustomSnackBarAlert from "../../components/global/CustomSnackBarAlert";
import useShow from "../../hooks/useShow";
import {
  Slide,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { styled } from '@mui/system';
import {themeWordibly} from "../../theme.js";
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomAlertDialogAudioClearance(props) {
  const { open, setOpen, functionOnAgree, setUploadAudioValidations } =
    props;
  const {changeNewOrderType, newOrderType } = useZustant();
  const handleClose = () => {
    setOpen(false);
  };
  const [currentAlertState, setCurrentAlertState] = useState({
    type: "error",
    message: "",
  });
  const { show, open:openAlert, close } = useShow();
  const handleAlert = (type, message) => {
    setCurrentAlertState({
      type,
      message,
    });
    openAlert();
  };
  const handleHumanTranscription = () => {

    if(validations.every(item => item.state !== null)){
      changeNewOrderType("human");
      setOpen(false);
    }else{
      handleAlert("error","Please fill all the options.")
    }
  }
  const [validations, setValidations] = useState([
    {validation:"multipleSpeakers",label:"My file has 3 speakers or fewer?",state: null},
    {validation:"noiseFree",label:"File is free of noise?",state: null},
    // {validation:"closeToMic",label:"Are speakers close to the mic?",state: null},
    {validation:"NoOverTalk",label:"Are there people talking over each other?",state: null},
    {validation:"clearAccents",label:"Accents are clear?",state: null},
  ]);

  const handleAgree = () => {
    //functionOnAgree();
    const formatedValidations = validations.map(q => {
      if (q.validation === 'noiseFree') {
        return {
          is_noice_free: q.state !== 'Yes'? 0 : 1
        }
      }
      // else if (q.validation === 'closeToMic') {
      //   return {
      //     is_speaker_close_to_mic: q.state !== 'Yes'? 0 : 1
      //   }
      // }
      else if (q.validation === 'multipleSpeakers') {
        return {
          multiple_speakers: q.state !== 'Yes'? 1 : 0
        }
      }
      else if (q.validation === 'NoOverTalk') {
        return {
          is_conversation_clear: q.state !== 'Yes'? 0 : 1
        }
      }
      else if (q.validation === 'clearAccents') {
        return {
          is_accents_clear: q.state !== 'Yes'? 0 : 1
        }
      }
    })
    const validationsObject = {}
    formatedValidations.forEach(v => Object.assign(validationsObject, v))
    setUploadAudioValidations(validationsObject)
    setOpen(false);
  };
  const [hasNoValidationState,setHasNoValidationState]=useState(false); 
  const handleValidation = (event, newAlignment) => {
    const updatedValidations = validations.map((question) =>
      question.validation === event.target.id
        ? { ...question, state: newAlignment }
        : question
    );
    setValidations(updatedValidations);
    // Check if any validation state is "No"
  const hasNoValidation = updatedValidations.some((question) => question.state === "No");

  // Update an additional state (e.g., hasNoValidationState) accordingly
   setHasNoValidationState(hasNoValidation); 
  };



  return (
    <div style={{ width: "500px", position: "relative" }}>
      <CustomSnackBarAlert
        type={currentAlertState.type}
        show={show}
        close={close}
      >
        {currentAlertState.message}
      </CustomSnackBarAlert>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        // style={{position: "relative"}}
      >
        <DialogTitle style={{backgroundColor:themeWordibly.palette.blue.main, color:themeWordibly.palette.white.main }}>Is your English audio clear?</DialogTitle>
        <DialogContent sx={{mt:3}}>
          <Grid container spacing={2}>
            {validations.map((question) => (
              <React.Fragment key={question.validation}>
                <Grid item xs={9}>
                  <DialogContentText
                    id={`alert-dialog-slide-description-${question.validation}`}
                  >
                    {question.label}
                  </DialogContentText>
                </Grid>
                <Grid item xs={3}>
                  <ToggleButtonGroup
                    color="primary"
                    value={question.state}
                    exclusive
                    onChange={handleValidation}
                    aria-label={`validation-${question.validation}`}
                  >
                    <ToggleButton id={question.validation} value="No">
                      No
                    </ToggleButton>
                    <ToggleButton id={question.validation} value="Yes">
                      Yes
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap:"4px"
          }}
          sx={{mb:3}}
        >
          <Button onClick={handleAgree} variant="contained"  sx={{px:5}}>
            {hasNoValidationState ? "PROCEED ANYWAY" : "PROCEED"}
          </Button>
          {hasNoValidationState && (
            <Button onClick={handleHumanTranscription} variant="text">
              "Use Human Transcription Instead"
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
