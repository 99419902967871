import * as React from "react";
import { useState } from "react";
import {
  Slide,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  TextField,
  TextareaAutosize,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { themeWordibly as theme } from "../../theme.js";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import useShow from "../../hooks/useShow";
import CustomSnackBarAlert from "../../components/global/CustomSnackBarAlert";
import Requests from "../../logic/Requests.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomeShareFileDialog(props) {
  const {
    open,
    setOpen,
    url,
    transcriptId
  } = props;
  const [emails, setEmails] = useState("");
  const handleClose = () => {
    setOpen(false);
  };
  const [view, setView] = useState("link");
  const [text, setText] = useState(null);
  const [currentAlertState, setCurrentAlertState] = useState({
    type: "error",
    message: "",
  });
  const { show: showAlert, open: openAlert, close: closeAlert } = useShow();
  const handleAlert = (type, message) => {
    setCurrentAlertState({
      type,
      message,
    });
    openAlert();
  };
  const handleShareViaLink = () => {
    setView("email");
    // functionOnAgree();
    // setOpen(false);
  };
  const handleShareViaMail = () => {
    Requests.shareTranscriptViaEmail(transcriptId,text,emails)
    setOpen(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(url);
    handleAlert("success", "url copied to the clip board");
  };
  console.log("url from customer share file dialig", url);
  return (
    <Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <CustomSnackBarAlert
          type={currentAlertState.type}
          show={showAlert}
          close={closeAlert}
        >
          {currentAlertState.message}
        </CustomSnackBarAlert>
        <Box
          style={{ width: "450px", display: "inline", position: "relative" }}
        >
          <DialogTitle style={{ backgroundColor: "black", color: "white" }}>
            Share
          </DialogTitle>
          <Button
            style={{
              position: "absolute",
              top: "14px",
              right: "8px",
              color: "white",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </Button>
          {view == "link" ? (
            <Box>
              <DialogContent style={{ paddingBottom: 0 }}>
                <DialogContentText
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    id="outlined-read-only-input"
                    style={{ width: "100%", borderRadius: "4px 0 0 4px" }}
                    value={url}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <Tooltip
                    title="copy URL"
                    sx={{
                      textTransform: "none",
                      backgroundColor: theme.palette.gray.main,
                      borderRadius: "0 4px 4px 0",
                      height: "56px",
                      width: "56px",
                    }}
                  >
                    <IconButton onClick={handleCopy}>
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                </DialogContentText>
              </DialogContent>
              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginButton: "20px",
                }}
              >
                <Button onClick={handleShareViaLink}>Share by email</Button>
              </DialogActions>
            </Box>
          ) : (
            <Box>
              <DialogContent style={{ paddingBottom: 0 }}>
                <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                  <Typography variant="p">
                    {" "}
                    Emails separated by semicolons
                  </Typography>
                  <Button onClick={()=>setView("link")}>
                    Get link
                  </Button>
                  </div>
                <DialogContentText
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    name="email"
                    variant="outlined" 
                    autoComplete="email"
                    autoFocus
                    display={"block"}
                    onChange={(e) => setEmails(e.target.value)}
                  />
                  <TextareaAutosize
                    aria-label="maximum height"
                    className="textAreaCustomAlert"
                    minRows={5}
                    onChange={(e) => setText(e.target.value)}
                    placeholder="Write a message (optional)"
                  />
                </DialogContentText>
              </DialogContent>
              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "10px",
                }}
              >
                <Button
                  className="uploadFiles"
                  variant="contained"
                  color="green"
                  onClick={()=>handleShareViaMail()}
                  style={{ padding: "0.5rem 1.5rem 0.5rem 1.5rem" }}
                >
                  Share
                </Button>
              </DialogActions>
            </Box>
          )}
        </Box>
      </Dialog>
    </Box>
  );
}
