import {
    Modal,
    Box,
    FormControl,
    InputLabel,
    Input,
    Button,
    Typography,
    TextField
  } from "@mui/material";
  import {themeWordibly as theme } from "../theme";
  
  export default function MultiFactorAuthModal(props) {
    const { show, close, verifyMFACode } = props;
  
    return (
      <Modal open={show}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: "6px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <form onSubmit={(e) => verifyMFACode(e)}>
            <Typography variant="h2">Two-Factor Authentication: Verification Code</Typography>
            <Typography variant="p">To ensure the protection of your account, we have implemented multi-factor authentication (MFA). Please enter the unique code that you received in your e-mail</Typography>
            <FormControl fullWidth>
              <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="code"
                      label="MFA Code"
                      name="MFA"
                      variant="filled"
                      type="text"
                      autoFocus
                      display={"block"}
                    />
            </FormControl>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}>
              <Button
                variant="contained"
                sx={{ color: theme.palette.white.main }}
                type="submit"
              >
                Verify Code
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    );
  }
  