import { persist } from "zustand/middleware";
import create from "zustand";
import Requests from "./logic/Requests";

export const useUser = create(
  persist(
    (set, get) => ({
      user: {},
      userIsLoading: true,
      currentFolderPrefix: [],
      currentClientPage: null,
      isOpenSidebar: true,
      isInClientsSettings: false,
      transcriberHasUnit: null,
      clientPrepaidBalance: null,
      newOrderType: null,
      allClientFiles: [],
      isUploadingFile:false,
      goToRootFunction:{},
      paymentLink: false,
      getUser: async () => {
        try {
          const data = await Requests.getUser();
          const response = await Requests.getSpecificUser(data.data._id);
          if (response.data) {
            set({ user: response.data });
            if (response.data.client){
              const PrepaidBalance = await Requests.getPrepaidBalance(response.data.client);
              set({ clientPrepaidBalance : PrepaidBalance.data.prepaidBalance });
              const allClientFilesRes = await Requests.getUserFolders(data.data._id)
              set({ allClientFiles : allClientFilesRes.data });
            }
          } else {
            set({ user: null });
          }
        } catch (error) {
          set({ user: null });
        }
      },
      clearUser: () => {
        set({
          user: null,
          currentFolderPrefix: [],
          currentClientPage: null,
          isOpenSidebar: true,
          isInClientsSettings: false,
          transcriberHasUnit: null,
          newOrderType: null,
          clientPrepaidBalance:null,
          allClientFiles:null,
        });
      },
      isLogged: () => !!get().user,

      validateUser: async () => {
        try {
          const response = await Requests.getUser();

          if (!response.data) {
            set({ user: null });
          } else {
            return;
          }
        } catch (error) {
          set({ user: null });
        }
      },

      placeFolderPrefix: (folderPrefix) => {
        set({ currentFolderPrefix: folderPrefix });
      },

      setCurrentClientPage: (page) => {
        set({ currentClientPage: page });
      },

      changeSidebarValue: () => {
        set({ isOpenSidebar: !get().isOpenSidebar });
      },

      changeIsInClientsSettings: (value) => {
        set({ isInClientsSettings: value });
      },

      changeTranscriberHasUnit: (value) => {
        set({ transcriberHasUnit: value });
      },
      
      changeClientPrepaidBalance: (value) => {
        set({ clientPrepaidBalance: value });
      },

      changeNewOrderType: (value) =>{
        set({ newOrderType: value });
      },

      changeAllClientFiles: (value) =>{
        set({ allClientFiles: value });
      },

      changeIsUploadingFile: (value) =>{
        set({ isUploadingFile: value });
      },
      setGoToRootFunction: (value) =>{
        set({ goToRootFunction: value });
      },
      setPaymentLink: (value) =>{
        set({ paymentLink: value });
      },
    }),
    {
      name: "user",
      getStorage: () => sessionStorage,
    }
  )
);
