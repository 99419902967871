import "./App.css";
import {useEffect} from "react";
import NavBarComponent from "./components/Navbar";
import { Outlet } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import {themeWordibly} from "./theme";


import { Box, useMediaQuery } from "@mui/material";
import useZustant from "./hooks/useZustant";
import { useLocation } from "react-router-dom";
import ClientSidebar from "./components/ClientSidebar";
import TranscriberTabs from "./components/TranscriberWork/TranscriberTabs"
import QATabs from "./components/TranscriberWork/QATabs"
import WordiblyFooter from "./components/myFiles/WordiblyFooter.js"


export default function App() {
  const { user, isOpenSidebar } = useZustant();
  const matches = useMediaQuery("(max-width:790px)");
  const mobile = useMediaQuery("(max-width:400px)");
  const isClient = user.rolename === "Client";
  const location = useLocation();

  const isInPayment = location.pathname === "/uploadFiles"

  return (
    <Box
      className={isClient ? "clientAppContainer" : null}
    >
      <ThemeProvider
        theme={
          window.location.host.includes("wordibly") ? themeWordibly : theme
        }
      >
        <div className={isClient ? "clientFlexContainer" : null}>
          {isClient ? <NavBarComponent /> : null}
          {isClient ? <ClientSidebar /> : null}
       
          <div
            id="mainContainer"
            className={
              isClient
                ? mobile
                  ? "mobileContainer"
                  : matches
                  ? "mediumContainer"
                  : isOpenSidebar && !isInPayment
                  ? "sideBarTrue wordiblyMainContainer"
                  : "sideBarFalse wordiblyMainContainer"
                : null
            }
          >
            <TranscriberTabs />
            <Outlet />
          </div>
          {isClient ? <WordiblyFooter /> : null}
        </div>
      </ThemeProvider>
    </Box>
  );
}
