import { createContext, useState, useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";

import Config from "../../../../config"
import Requests from "../../../../logic/Requests"
import useZustant from "../../../../hooks/useZustant";
import awsUtils from "../../../../awsUtils";

export const UploadFilesContext = createContext({})

const checkedBoxes = [
    { checked: false, label: "ALL HUMAN" },
    { checked: false, label: "PROFESSIONAL" },
    { checked: false, label: "RUSH" },
    { checked: false, label: "VERBATIM" },
    { checked: false, label: "TIMESTAMPING" },
    { checked: false, label: "INSTANT FIRST DRAFT" },
]

export default function UploadFilesContextProvider({ children }) {
    const { user } = useZustant()
    const [folder, setFolder] = useState(null)
    const [folders, setFolders] = useState(null)

    const [filesObject, setFilesObject] = useState({
        checkedOptions: [],
        completedUploads: [],
        newOrderType: "",
        SubTotal: 0,
        total: 0
    })
    const [checkedOptionsFilesObject, setCheckedOptionsFilesObject] = useState({
        "ALL HUMAN": false,
        "PROFESSIONAL": false,
        "RUSH": false,
        "VERBATIM": false,
        "TIMESTAMPING": false,
        "INSTANT FIRST DRAFT": false,
        "NEED HUMAN": false,
        "REDACTION": false,
    })
    let uploadedFilesFilesObject = useRef([]);

    const [orderTotal, setOrderTotal] = useState(0.0)
    const [orderSubtotal, setOrderSubtotal] = useState(0.0)
    const [files, setFiles] = useState([])
    const [snackBarAlertState, setSnackBarAlertState] = useState({
        type: "error",
        message: "",
    });
    const [isSnackBarShowing, setIsSnackBarShowing] = useState(false)
    const [uploadAudioValidations, setUploadAudioValidations] = useState({})
    const [isDragHover, setIsDragHover] = useState(false)
    const [uploadingFiles, setUploadingFiles] = useState([])

    const [completedUploadsFileObjectFormat, setCompletedUploadsFileObjectFormat] = useState([])

    const uploadFile = (file) => {
        const tempFolderPath = `${folder}${user.id}_temp/`
        let sanitizedFileName = file.name.replaceAll("'","")
        sanitizedFileName = sanitizedFileName.replaceAll("$", "")
        sanitizedFileName = sanitizedFileName.replaceAll("/", "")
        sanitizedFileName = sanitizedFileName.replaceAll(",", "")
        sanitizedFileName = sanitizedFileName.replaceAll("%", "")
        sanitizedFileName = sanitizedFileName.replaceAll("\\", "")
        sanitizedFileName = sanitizedFileName.replaceAll(":", "")

        const params = {
            Bucket: Config.awsBucketName,
            Key: `${tempFolderPath}${sanitizedFileName}`,
            Body: file,
            ContentType: file.type,
        };

        const parts = sanitizedFileName.split('.');
        const extension = parts.pop().toLowerCase();
        const baseName = parts.join('.').replace(/[.\s]/g, '_');
        const newFileName = `${baseName}.${extension}`;

        return { key: `${tempFolderPath}${newFileName}`, fileName: newFileName }
    }

    const handleAlert = (type, message) => {
        setSnackBarAlertState({
            type,
            message,
        });
        setIsSnackBarShowing(true);
    };

    const { getRootProps, getInputProps, open } = useDropzone({
        noClick: true,
        onDrop: (acceptedFiles) => {
            const supportedFormats = ["audio/mpeg", "video/mp4", "audio/m4a", "audio/x-m4a"]
            const allowedFiles = acceptedFiles.filter((uploadedFile) => supportedFormats.includes(uploadedFile.type))
            const exceedsLimitFile = acceptedFiles.filter((uploadedFile) => uploadedFile.size > 2000000000)
            if(exceedsLimitFile.length >= 1){
                handleAlert("error", `The size limit for uploading files is 2000mb`)
                return
            }
            if(allowedFiles.length !== acceptedFiles.length) {
                handleAlert("error", `Currently, we only support .mp3 and .mp4 for automated transcripts`)
                return
            }
            const updatedFiles = allowedFiles.map((file, index) => {
                // Create a new File object with the same prototype and a unique tempId
                let sanitizedFileName = file.name.replaceAll("'","")
                sanitizedFileName = sanitizedFileName.replaceAll("$", "")
                sanitizedFileName = sanitizedFileName.replaceAll("/", "")
                sanitizedFileName = sanitizedFileName.replaceAll(",", "")
                sanitizedFileName = sanitizedFileName.replaceAll("%", "")
                sanitizedFileName = sanitizedFileName.replaceAll("\\", "")
                sanitizedFileName = sanitizedFileName.replaceAll(":", "")
                const newFile = new File([file], sanitizedFileName, {
                    type: file.type,
                    lastModified: file.lastModified,
                });
                newFile.tempId = Date.now() + index;
                return newFile;
            });

            setUploadingFiles([
                ...uploadingFiles,
                ...updatedFiles.map(f => ({ ...uploadFile(f), file: f, tempId: f.tempId, size: f.size }))])
            setFiles([...files, ...updatedFiles]);

            setIsDragHover(false);
        },
    })

    useEffect(() => {
        document.title = "File Upload - Wordibly";
        try {
            let data = [];
            Requests.getUserFolders(user.id).then((res) => {
                res.data.forEach((folder) => {
                    let object = {};
                    object.isFile = false;
                    object.name = folder.name;
                    object.public = false;
                    object.url = `https://${Config.awsBucketName}.s3.amazonaws.com/${folder.path}`;
                    object.key = folder.path;
                    data.push({
                        id: object.key,
                        name: object.name,
                        isDir: true,
                    });
                });

                setFolder(data[0].id);

                const lo = Requests.getMyFilesObjects(Config.awsBucketName, data[0].id).then((res) => {
                    setFolders(res);

                    awsUtils.emptyS3Directory(
                        Config.awsBucketName,
                        data[0].id + user.id + "_Temp/"
                    );
                });
            });
        } catch (error) {
            setFolder(null);
        }
    }, []);

    return (
        <UploadFilesContext.Provider value={{
            orderTotal,
            setOrderTotal,
            orderSubtotal,
            setOrderSubtotal,
            files,
            setFiles,
            snackBarAlertState,
            setSnackBarAlertState,
            isSnackBarShowing,
            setIsSnackBarShowing,
            uploadAudioValidations,
            setUploadAudioValidations,
            isDragHover,
            setIsDragHover,
            getRootProps,
            getInputProps,
            open,
            uploadingFiles,
            setUploadingFiles,
            handleAlert,
            completedUploadsFileObjectFormat,
            setCompletedUploadsFileObjectFormat,
            uploadedFilesFilesObject,
            checkedOptionsFilesObject,
            setCheckedOptionsFilesObject,
            filesObject,
            setFilesObject
        }}>
            {children}
        </UploadFilesContext.Provider>
    )
}