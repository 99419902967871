import {
  Modal,
  Box,
  FormControl,
  InputLabel,
  Input,
  Button,
  Typography,
  TextField
} from "@mui/material";
import {themeWordibly as theme } from "../theme";

export default function RecoverPasswordModal(props) {
  const { show, close, recoverPassword } = props;

  return (
    <Modal open={show}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: "6px",
          boxShadow: 24,
          p: 4,
        }}
      >
        <form onSubmit={(e) => recoverPassword(e)}>
          <Typography variant="h2">Forgot your Password?</Typography>
          <Typography variant="p">Enter your email address and we'll get you back on track</Typography>
          <FormControl fullWidth>
            <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    variant="filled"
                    autoComplete="email"
                    type="email"
                    autoFocus
                    display={"block"}
                  />
          </FormControl>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <Button
              variant="text"
              sx={{ marginRight: "10px", color: theme.palette.blue.main }}
              onClick={() => close()}
            >
              Return to Login
            </Button>
            <Button
              variant="contained"
              sx={{ color: theme.palette.white.main }}
              type="submit"
            >
              Submit
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
}
