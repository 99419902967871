import { useState, useEffect } from "react";

import {
  IconButton,
  Box,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  AppBar,
  Divider,
  Tooltip,
  useRadioGroup,
  ListItemText,
  useMediaQuery,
  Link,
} from "@mui/material";

import useZustant from "../hooks/useZustant";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import Requests from "../logic/Requests";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { themeWordibly as theme } from "../theme";
import { useLocation } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MenuIcon from "@mui/icons-material/Menu";
import RenderMenuItems from "./navbar/RenderMenuItems";
import useShow from "../hooks/useShow";
import CustomSnackBarAlert from "../components/global/CustomSnackBarAlert";
import config from "../config/index"
import wordiblyLogoWhite from "../assets/img/wordiblyLogoWhite.svg";

export default function NavbarComponent() {
  const { user, clearUser, isOpenSidebar, changeSidebarValue } = useZustant();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const open = Boolean(anchorEl);

  const { show: showAlert, close: closeAlert, open: openAlert } = useShow();
  const [currentAlertState, setCurrentAlertState] = useState({
    type: "error",
    message: "",
  });
  const handleAlert = (type, message) => {
    setCurrentAlertState({
      type,
      message,
    });
    openAlert();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    try {
      Requests.logout().then((res) => {
        if (res.status === 200) {
          clearUser();
          navigate("/");
        }
      });
    } catch (error) {
      console.error(error);
    }
  };


  const handleDrawerOpen = () => {
    changeSidebarValue(true);
  };

  const handleDrawerClose = () => {
    changeSidebarValue(false);
  };
  const userName = user.firstname;

  const isLargeScreen = useMediaQuery("(max-width:1035px)");

  return (
    <ThemeProvider theme={theme}>
      <CustomSnackBarAlert
        type={currentAlertState.type}
        show={showAlert}
        close={closeAlert}
      >
        {currentAlertState.message}
      </CustomSnackBarAlert>

      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position={user.rolename === "Client" ? "fixed" : "static"}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display: "flex",
            justifyContent: "center",
            position: "absolute",
          }}
        >
          <Toolbar
            sx={{
              height: isLargeScreen ? "116px" : "77px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Link href="https://wordibly.com">
              <img
                src={wordiblyLogoWhite}
                alt="wordibly logo"
                className="navbarLogo"
              />
            </Link>

            <Box
              sx={{
                display: !isLargeScreen ? "Block" : "none",
              }}
            >
              {/*<Tooltip title="my Cart" sx={{ textTransform: "none", mr: "16px" }} >
                <IconButton
                  onClick={() => handleAlert("warning", "Feature coming soon!")}
                >
                  <ShoppingCartIcon color="white" />
                </IconButton>
            </Tooltip>*/}
              <Button
                sx={{ textTransform: "none", mr: "16px" }}
                onClick={() => window.location.href = config.learnLink}
              >
                <ListItemText
                  style={{fontSize: "18px"}}
                  primary={"Learn"}
                  className="highlightBarUnde"
                  disableTypography
                />
              </Button>

              <Button
                color="primary"
                sx={{ textTransform: "none", mr: "16px" }}
                onClick={() => window.location.href = config.enterpriseLink}
              >
                <ListItemText
                  style={{fontSize: "18px"}}
                  primary={"Enterprise"}
                  className="highlightBarUnde"
                  disableTypography
                />
              </Button>
              <Button
                  style={{fontSize: "18px"}}
                  color="primary"
                sx={{ textTransform: "none" }}
                onClick={handleClick}
              >
                <ListItemText
                  primary={`Hi, ${userName}`}
                  className="highlightBarUnde"
                  disableTypography
                />
                <ArrowDropDownIcon color={"white"}/>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <RenderMenuItems user={user} handleClose={handleClose} />

                <Divider sx={{ borderColor: "white !important" }} />
                <MenuItem onClick={() => logout()}>Log Out</MenuItem>
              </Menu>
            </Box>
            <Box
              className={`menu-container ${isOpenSidebar ? "nav-open" : ""}`}
              sx={{
                display: isLargeScreen ? "Block" : "none",
              }}
            >
              <Button
                id="menu-toggle"
                className="menu-toggle"
                onClick={() => changeSidebarValue(!isOpenSidebar)}
              >
                <span className="menu-toggle-bar menu-toggle-bar--top"></span>
                <span className="menu-toggle-bar menu-toggle-bar--middle"></span>
                <span className="menu-toggle-bar menu-toggle-bar--bottom"></span>
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
}
