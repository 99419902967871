import { ThemeProvider } from "@emotion/react";
import { Box, Skeleton, Button } from "@mui/material"
import { useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useNavigate } from "react-router-dom";
import theme from "../../../theme";
import useZustant from "../../../hooks/useZustant";
import {
    editorWithColumnsInitConfig,
    editorWithOutColumnsInitConfig
} from "../../../components/unit/EditorsConfig/editorConfigs"
import Requests from "../../../logic/Requests";
import Config from "../../../config";
import { useEffect, useRef, useState } from "react";
import CustomSnackBarAlert from "../../../components/global/CustomSnackBarAlert";
import useShow from "../../../hooks/useShow";

export default function EditTranscript() {
    const navigate = useNavigate()

    const { user } = useZustant()
    const { transcriptId } = useParams()
    const [transcript, setTranscript] = useState({})
    const [loading, setLoading] = useState(true)
    const [alertContent, setAlertContent] = useState("");
    const [alertType, setAlertType] = useState("error");
    const [mediaType, setMediaType] = useState("audio");
    const [mediaLink, setMediaLink] = useState("");
    
    const fileTagRef = useRef(null)

    const editorInitConfig = useRef(editorWithOutColumnsInitConfig)
    const editorRef = useRef(null)
    const speakerList = useRef([])
    const speakerOrder = useRef([])

    const { show: showAlert, open: openAlert, close: closeAlert } = useShow()

    const handleAlert = (type, content) => {
        setAlertType(type);
        setAlertContent(content);
        openAlert();
    };

    const handleSave = async () => {
        try {
            const content = window.tinymce.activeEditor.getContent()
            const response = await Requests.saveTranscriptChangesAsAClient(transcriptId, content)
            handleAlert("success", "Changes saved")
        } catch (error) {
            handleAlert("error", "We couldn't save the changes")
            console.error(error);
        }
    };

    const getTranscript = async () => {
        setLoading(true)
        try {
            const response = await Requests.getFinishedTranscriptForClient(transcriptId)
            if (response.data.has_speakers_columns) editorInitConfig.current = editorWithColumnsInitConfig
            setTranscript({
                ...response.data,
                content: {
                    html: JSON.parse(response.data.content)
                }
            })
            var params = {
              Key: response.data.egnyte_path,
              Bucket: Config.awsBucketName
            };
            const audio = await Requests.getSignedUrl(params,"getObject")
            Requests.getMyFilesObject(Config.awsBucketName, response.data.egnyte_path)
            const audioBlob = new Blob([audio.Body], { type: audio.ContentType })
            const audioLink = URL.createObjectURL(audioBlob)
            fileTagRef.current.src = audio
            setMediaLink(audio)
            fileTagRef.current.style.display = "block"
            fileTagRef.current.style.width = "100%"
            fileTagRef.current.load()
            setMediaType(response.data.file_type);
            
        } catch (err) {
            if (err === "error fetching the audio") {
                handleAlert("error", "We couldn't get the audio")
            }else {
                handleAlert("error", "We couldn't get this transcription")
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        getTranscript()
    }, [])

    return (
      <>
        <CustomSnackBarAlert
          show={showAlert}
          close={closeAlert}
          type={alertType}
        >
          {alertContent}
        </CustomSnackBarAlert>
        <ThemeProvider theme={theme}>
          <Box sx={{ mt: 3, mx: { xs: 2, md: 10 } }}>
            <h1>{transcript.file}</h1>
            {mediaType == "audio" ? (
              <audio
                style={{ marginBlockEnd: "10px", display: "none" }}
                ref={fileTagRef}
                controls
              />
            ) : (
              <>
              <video width="100%" height="400px" controls>
                <source src={mediaLink} type="video/mp4"/>
              Your browser does not support the video tag.
              </video>
              </>
            )}
            {loading ? (
              <>
                <Skeleton variant="rectangular" animation="wave" height={500} />
              </>
            ) : (
              <>
                <div style={{marginTop:"10px"}}>
                  <Editor
                    id="editor"
                    apiKey={Config.editorApiKey}
                    onInit={(evt, editor) => {
                      editorRef.current = editor;
                      editor.getBody().className = "EditorBody";
                      const activeEditor = window.tinymce.activeEditor;
                      activeEditor.setProgressState(true);
                      activeEditor.setProgressState(false, 2000);
                    }}
                    toolbar="bold italic underline strikethrough searchreplace"
                    init={
                      !loading
                        ? editorInitConfig.current({
                            speakerList,
                            speakerOrder,
                            user,
                            content: transcript.content,
                            handleSave,
                          })
                        : { branding: false}
                    }
                  />
                  <div style={{ marginTop: "10px" }}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => navigate("/")}
                    >
                      Back
                    </Button>
                    <Button
                      sx={{ marginInlineStart: "10px" }}
                      variant="contained"
                      color="success"
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </>
            )}
          </Box>
        </ThemeProvider>
      </>
    );
}