const withColumnsDefaultContent = `
<div class="RowSpeaker">
  <div class="SpeakerContent">
    <div class="SpeakerColumn">
      <p class="SpeakerInput" contenteditable="true">Add speaker</p>
    </div>
    <p class="EditorContent" contenteditable="true">&nbsp;</p>
  </div>
</div>
`;

const withOutColumnsDefaultContent = `<p></p>`;

export { 
    withColumnsDefaultContent, 
    withOutColumnsDefaultContent 
}