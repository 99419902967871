import { useContext, useState } from "react"
import { themeWordibly as theme } from "../../../theme";
import { Lock } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { UploadFilesContext } from "./context/UploadFilesContext"
import CustomSnackBarAlert from "../../../components/global/CustomSnackBarAlert";
import CustomAlertDialogAudioClearance from "../../../components/global/CustomAlertDialogAudioClearance";
import useZustant from "../../../hooks/useZustant";
import UploadFilesView from "./UploadFilesView";
import OrderTicket from "../../../components/myFiles/uploadFiles/OrderTicket";
import UploadFilesCheckout from "../../../components/myFiles/uploadFiles/UploadFilesCheckout";
import UndoIcon from "@mui/icons-material/Undo";
import { useNavigate } from "react-router-dom";

export default function UploadFilesController() {
  const {
    uploadingFiles,
    snackBarAlertState,
    isSnackBarShowing,
    setIsSnackBarShowing,
    setUploadAudioValidations,
        handleAlert
    } = useContext(UploadFilesContext)
    const { newOrderType } = useZustant()
    const [showAutomatedAudioValidations, setShowAutomatedAudioValidations] = useState(newOrderType === "automated" ? true : false)
  const [isInCheckout, setIsInCheckout] = useState(false);
  const closeSnackBar = () => {
        setIsSnackBarShowing(false)
    }
    const navigate = useNavigate()

    if (isInCheckout) return (
      <>
        <CustomSnackBarAlert
          type={snackBarAlertState.type}
          show={isSnackBarShowing}
          close={closeSnackBar}
        >
          {snackBarAlertState.message}
        </CustomSnackBarAlert>
        <CustomAlertDialogAudioClearance
          setUploadAudioValidations={setUploadAudioValidations}
          open={showAutomatedAudioValidations}
          setOpen={setShowAutomatedAudioValidations}
          title={""}
          textInput={true}
          BtnCloseText={"CANCEL"}
          BtnAgreeText={
            <Button
              style={{ backgroundColor: "#1a336e", color: "white" }}
              // onClick={()=>{handleSubmitRating(row.id)}}
            >
              SUBMIT
            </Button>
          }
        />
        <Box sx={{ maxWidth: 1024, mx: "auto" }}>
                <UploadFilesCheckout handleAlert={handleAlert} setIsInCheckout={setIsInCheckout} />
          <Box
            style={{ display: "flex", alignItems: "center", gap: "8px" }}
            sx={{ ml: 5, mt: 2 }}
          >
            <Lock style={{ color: theme.palette.primary.main }} />
            <span>Secure and Encrypted</span>
          </Box>
        </Box>
      </>
    )

  return (
    <>
      <CustomSnackBarAlert
        type={snackBarAlertState.type}
        show={isSnackBarShowing}
        close={closeSnackBar}
      >
        {snackBarAlertState.message}
      </CustomSnackBarAlert>
      <CustomAlertDialogAudioClearance
        setUploadAudioValidations={setUploadAudioValidations}
        open={showAutomatedAudioValidations}
        setOpen={setShowAutomatedAudioValidations}
        title={""}
        textInput={true}
        BtnCloseText={"CANCEL"}
        BtnAgreeText={
          <Button
            style={{ backgroundColor: "#1a336e", color: "white" }}
            // onClick={()=>{handleSubmitRating(row.id)}}
          >
            SUBMIT
          </Button>
        }
      />
      <Box sx={{ display: "flex" }} width={"100%"} mt={2}>
        <Box width={"70%"} sx={{ px: 5 }}>
          <Button sx={{ mt: 2 }} onClick={() => navigate("/filemanagement")}>
            <UndoIcon />
          </Button>
        </Box>
      </Box>
      <Box sx={{ maxWidth: 1024, mx: "auto" }}>
        {uploadingFiles.length === 0 ? (
          <UploadFilesView />
        ) : (
          <OrderTicket setIsInCheckout={setIsInCheckout} />
        )}
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            justifyContent: uploadingFiles.length === 0 ? "center" : "start",
          }}
          sx={{ ml: 5, mt: 0, pb: 5}}
        >
          <Lock style={{ color: theme.palette.primary.main }} />
          <span>Secure and Encrypted</span>
        </Box>
      </Box>
    </>
    )
}
