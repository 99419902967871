import { useState, useEffect } from "react";
import FileManagementTableToolbar from "./FileManagementTableToolbar";
import FileManagementTableHead from "./FileManagementTableHead";
import {
  Box,
  TableRow,
  TableCell,
  TablePagination,
  TableContainer,
  Table,
  TableBody,
  Paper,
  CircularProgress,
} from "@mui/material";
import FileManagerRow from "./FileManagerRow";
import useZustant from "../../hooks/useZustant";

export default function FileManagementTable(props) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("size");
  const [selected, setSelected] = useState([]);
  const clearSelected = () => setSelected([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const {
    files,
    changePrefix,
    folderPrefix,
    isLoading,
    filesSearch,
    searchQuery,
    clearSearch,
    filesAction,
    downloadSelectedFiles,
    open,
    updateFiles,
    showMoveModal,
    closeMoveModal,
    folder,
    openMoveModal,
    handleAlert,
  } = props;

  const [currentFiles, setCurrentFiles] = useState(files);

  const { user, allClientFiles, changeAllClientFiles } = useZustant();

  useEffect(() => {
    setPage(0);
  }, [folderPrefix]);

  useEffect(() => {
    if (files.length > 0) {
      try {
        const trueFiles = files.filter(
          (file) =>
            file && file.name !== "" && file.name !== undefined && file.name !== null && !file.name.includes("_temp")
        );
        setCurrentFiles(trueFiles);
      } catch (error) {
        console.error(error);
      }
    };
  }, [files]);

  useEffect(() => {
    if (filesSearch.length > 0 || searchQuery) {
      setCurrentFiles(filesSearch);
    } else {
      setCurrentFiles(files);
    }
  }, [filesSearch, searchQuery]);

  const ITEM_HEIGHT = 48;

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = currentFiles.map((n) => n);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - currentFiles.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <FileManagementTableToolbar
        numSelected={selected.length}
        searchQuery={searchQuery}
        clearSearch={clearSearch}
        downloadSelectedFiles={downloadSelectedFiles}
        selectedFiles={selected}
        open={open}
        updateFiles={updateFiles}
        clearSelected={clearSelected}
        folderPrefix={folderPrefix}
        handleAlert={handleAlert}
      />
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer style={{ overflow: 'auto' }}>
          <Table aria-label="simple table" size="small">
            <FileManagementTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={currentFiles.length}
            />

            {isLoading ? (
              <TableBody
                sx={{
                  position: "relative",
                  height: 300,
                }}
              >
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <CircularProgress
                      sx={{
                        position: "relative",
                        top: "50% ",
                        left: "75% ",
                        margin: "auto",
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {stableSort(currentFiles, getComparator(order, orderBy))
                  .filter(e => user.client_user_role === "admin" ? true : e.user_id == user._id)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const tempCondition =
                      row.name === user.id + "_Temp";

                    if (row.name && !tempCondition) {
                      return (
                        <FileManagerRow
                          user={user}
                          allClientFiles={allClientFiles}
                          changeAllClientFiles={changeAllClientFiles}
                          row={row}
                          isItemSelected={isItemSelected}
                          labelId={labelId}
                          key={row.id}
                          filesAction={filesAction}
                          changePrefix={changePrefix}
                          folderPrefix={folderPrefix}
                          handleClick={handleClick}
                          itemHeight={ITEM_HEIGHT}
                          updateFiles={updateFiles}
                          showMoveModal={showMoveModal}
                          closeMoveModal={closeMoveModal}
                          folder={folder}
                          openMoveModal={openMoveModal}
                          handleAlert={handleAlert}
                          selected={selected}
                          clearSelected={clearSelected}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 1 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={currentFiles.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
