import * as React from "react";

import {
  Slide,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  TextareaAutosize,
} from "@mui/material";
import { styled } from '@mui/system';
import mainTheme from "../../theme.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  const { open, setOpen, message, title, functionOnAgree, textInput, setText, BtnCloseText="Disagree",BtnAgreeText,  aditionalclose
} =
    props;

  const handleClose = () => {
    if(aditionalclose){
      aditionalclose();
    }
    setOpen(false);

  };

  const handleAgree = () => {
    if(functionOnAgree){
      functionOnAgree();
    }
    setOpen(false);
  };
  // const StyledTextarea = styled(TextareaAutosize)(
  //   ({ theme }) => `
  //   width: "100%";
  //   resize: "vertical";
  //   font-family: IBM Plex Sans, sans-serif;
  //   font-size: 0.875rem;
  //   font-weight: 400;
  //   line-height: 1.5;
  //   padding: 12px;
  //   border-radius: 12px 12px 0 12px;
  //   &:hover {
  //     border-color: ${mainTheme.palette.primary.main};
  //   }
  
  //   &:focus {
  //     border-color: ${mainTheme.palette.primary.main};
  //     box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? mainTheme.palette.primary.main : mainTheme.palette.primary.main};
  //   }
  
  //   // firefox
  //   &:focus-visible {
  //     outline: 0;
  //   }`
  // )

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>
        {!!textInput && (
          <Box sx={{ pl: 2, pr: 2 }}>
            <TextareaAutosize
              aria-label="maximum height"
              className="textAreaCustomAlert"
              minRows={3}
              onChange={(e) => setText(e.target.value)}
              placeholder="How could we improve next time?"
            />
          </Box>
        )}
        <DialogActions>
          <Button onClick={handleClose}>{BtnCloseText}</Button>
          {BtnAgreeText?<Box onClick={handleAgree}>{BtnAgreeText}</Box>:<Button onClick={handleAgree}>{BtnAgreeText}</Button>}
          
        </DialogActions>
      </Dialog>
    </div>
  );
}
