import { useEffect, React, useState, useRef } from "react";
import {
  Alert,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Card,
  CardMedia,
  Button,
  FormControl,
  InputLabel,
  Input,
  Toolbar,
  Chip,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { ThemeProvider } from "@mui/material/styles";
import wordiblyLogo from "../../assets/img/wordiblyLogo.webp";
import { themeWordibly as theme } from "../../theme";
import Requests from "../../logic/Requests";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import "./MyFiles.css";
import useZustant from "../../hooks/useZustant";
import useShow from "../../hooks/useShow";
import useFetch from "../../hooks/useFetch";
import RecoverPasswordModal from "../../components/RecoverPasswordModal";
import CustomSnackBarAlert from "../../components/global/CustomSnackBarAlert";
import Config from "../../config";
import { useParams } from "react-router-dom";
import Utils from "../../utils";
import CustomAlertDialogSlide from "../../components/global/CustomAlertDialogSlide"
import WordiblyFooter from "../../components/myFiles/WordiblyFooter.js";

const recaptchaKeys = {
  Wordibly: {
    SiteKey: "6Lfo2KonAAAAAOJlViUcn44CAEk_WHEHX3-MQ5t5"
  },
  TOPS:
  {
    SiteKey: "6LfB26onAAAAAGylNSDvJlmPRJkhQo2YC6tbu8Fw"
  },
}

export default function AccountInvitation() {
  const { show: showAlert, close: closeAlert, open: openAlert } = useShow();
  const [currentAlertState, setCurrentAlertState] = useState({
    type: "error",
    message: "",
  });
  const navigate = useNavigate();
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [terms, setTerms] = useState(false);
  let { accountInvitationToken } = useParams();
  const [loading, setLoading] = useState(false);
  const [openDialog,setOpenDialog]=useState(false)
  const { data, isLoading } = useFetch(
    Requests.getTeamMemberByToken,
    accountInvitationToken
  );
  const { clearUser } = useZustant()

  useEffect(() => {
    document.title = "Create Account - Wordibly";
    if (!isLoading && data) {
      setFirstName(data.first_name);
      setLastName(data.last_name);
      setEmail(data.email);
    }
  }, [data, isLoading]);

  useEffect(() => {
    const host = window.location.host
    if (host.includes("wordibly")) {
      const wordiblyScript = document.createElement("script")
      wordiblyScript.src = "https://www.google.com/recaptcha/api.js?render=6Lfo2KonAAAAAOJlViUcn44CAEk_WHEHX3-MQ5t5"
      wordiblyScript.id = "recaptcha-key-wordibly"
      document.head.appendChild(wordiblyScript)
    } else {
      const topsScript = document.createElement("script")
      topsScript.src = "https://www.google.com/recaptcha/api.js?render=6LfB26onAAAAAGylNSDvJlmPRJkhQo2YC6tbu8Fw"
      topsScript.id = "recaptcha-key-tops"
      document.head.appendChild(topsScript)
    }
    return () => {
      if (host.includes("wordibly")) {
        document.getElementById("recaptcha-key-wordibly")?.remove()
      } else {
        document.getElementById("recaptcha-key-tops")?.remove()
      }
    }
  }, []);

  const handleAlert = (type, message) => {
    setCurrentAlertState({
      type,
      message,
    });
    openAlert();
  };

  const handleOnClick = e => {
    e.preventDefault();
    setLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(window.location.host.includes("wordibly")
        ? recaptchaKeys.Wordibly.SiteKey 
        : recaptchaKeys.TOPS.SiteKey, { action: 'submit' })
          .then(token =>  Requests.verifyReCaptchaResponse({token, domain: window.location.origin}))
          .then(res => {
            if(res.data.success){
              if(res.data.score > 0.6){
                handleSubmit()
              } else {
                handleAlert("error", "Please, try to sign up again")
              }
              setLoading(false)
            }
          })
          .catch(() => handleAlert("error", "There was an error, please try to sign up again"));
    });
  }

  const logout = async () => {
    try {
      document.cookie = `comesFromTranscriberHub=true; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${Config.cookieDomain
        }; path=/;"`;
      await Requests.logout().then((res) => {
        if (res.status === 200) {
          clearUser()
          navigate("/")
        }
      });
    } catch (error) {
      console.error(error)
    }
  }
  const handleSubmit = async (event) => {
    if (
      first_name === "" ||
      first_name === null ||
      last_name === "" ||
      last_name === null ||
      email === "" ||
      email === null ||
      password === "" ||
      password === null
    ) {
      handleAlert("error", "Please, fill all the required fields");
      return;
    } else {
      const validationMessage = Utils.checkPasswordRestrictions(password, { firstname: first_name, lastname: last_name });
      if (!validationMessage.validation) {
        handleAlert("error", validationMessage.message);
        return;
      }
      if (!terms) {
        handleAlert("error", "You must accept the terms and privacy policy");
        return;
      }
      try {
        if (accountInvitationToken) {
          await Requests.acceptTeamMemberInvite({
            first_name,
            last_name,
            email,
            password,
          });
          setOpenDialog(true);
          //await logout();
          
        } else {
          try {
            const newUserData = await Requests.createClientUser({
              first_name,
              last_name,
              email,
              password,
              companyName
            });
            setOpenDialog(true);
            //await logout();
           
    
          } catch (error) {
            handleAlert("error", error.response.data.error.error ? error.response.data.error.error : error.message);
          }
        }
      } catch (error) {
        handleAlert("error", error.response.data);
      }
    }
  };
  const handleOk = (event)=>{
    logout();
    navigate("/");
  }
  return (
    <ThemeProvider theme={theme}>
      <CustomSnackBarAlert
        show={showAlert}
        close={closeAlert}
        type={currentAlertState.type}
      >
        {currentAlertState.message}
      </CustomSnackBarAlert>
      <CustomAlertDialogSlide
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Activate your account."}
        message={
          "We are nearly finished. Please check the email you provided for the Welcome email and activate your account."}
        BtnCloseText={null}
        BtnAgreeText={
          <Button
            variant="contained"
            className="uploadFiles"
            color="green"            
            style={{ margin: "0 8px 8px 0" }}
            onClick={handleOk}
      
          >
            Ok
          </Button>
        }
        aditionalclose={handleOk}
      />
      <Toolbar sx={{ backgroundColor: "white" }}>
        <Box sx={{ flexGrow: 1 }}>
          <CardMedia
            component="img"
            style={{ width: "15rem" }}
            image={wordiblyLogo}
          />
        </Box>
      </Toolbar>
      <div
        id="register"
        className="mw6 mw7-l ph3 pb4 center flex-column items-center"
        style={{ margin: "0 18rem" }}
      >
        <Typography variant={"h1"}>Create Account</Typography>
        <Box className="mv2 w-60-m w-40-l">
          Already have an account?{" "}
          <Link href="/" className="sign-in-link">
            Sign In
          </Link>
        </Box>
        {!!accountInvitationToken && (
          <Chip
            className="notice"
            label="You are joining a shared account."
            color="primary"
          />
        )}

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputLabel className="label-required" htmlFor="FirstName">
              First name
            </InputLabel>
            <TextField
              variant="outlined"
              className="input-block-level v-text-input"
              size="small"
              required
              value={first_name}
              onChange={(event) => setFirstName(event.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel className="label-required" htmlFor="FirstName">
              Last name
            </InputLabel>
            <TextField
              variant="outlined"
              required
              className="input-block-level v-text-input"
              size="small"
              value={last_name}
              onChange={(event) => setLastName(event.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel className="label-required" htmlFor="Email">
              Email address
            </InputLabel>
            <TextField
              variant="outlined"
              required
              className="input-block-level v-text-input"
              size="small"
              readOnly={accountInvitationToken ? true : false}
              value={email}
              onChange={(event) =>
                !accountInvitationToken && setEmail(event.target.value)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel className="label-required" htmlFor="Password">
              Password
            </InputLabel>
            <TextField
              variant="outlined"
              type="password"
              required
              autoComplete="current-password"
              className="input-block-level v-text-input"
              size="small"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            <Typography variant={"span"} style={{ fontSize: "10px" }}>
              {
                Utils.checkPasswordRestrictions(password, {
                  firstname: first_name,
                  lastname: last_name,
                }).message
              }
            </Typography>
          </Grid>
          {!accountInvitationToken && (
            <Grid item xs={6} style={{ paddingTop: "0" }}>
              <InputLabel className="label-required" htmlFor="FirstName">
                company
              </InputLabel>
              <TextField
                variant="outlined"
                className="input-block-level v-text-input"
                style={{}}
                size="small"
                value={companyName}
                onChange={(event) => setCompanyName(event.target.value)}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox checked={terms} onChange={() => setTerms(!terms)} />
              }
              label={
                <InputLabel>
                  By creating an account, you agree to our{" "}
                  <Link
                    href="/clients/legal/terms"
                    style={{ textDecoration: "none" }}
                  >
                    terms of service
                  </Link>{" "}
                  and
                  <Link
                    href="/clients/legal/privacy"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    privacy policy
                  </Link>
                  .
                </InputLabel>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              style={{ marginTop: "0.2rem", padding: "1rem 5rem" }}
              onClick={handleOnClick}
            >
              {loading ? "Submitting..." : "CREATE ACCOUNT"}
            </Button>
          </Grid>
        </Grid>
      </div>
      <WordiblyFooter isFullWidth={true} />
    </ThemeProvider>
  );
}
