import {
  Modal,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from "@mui/material";
import Config from "../../config";
import "../../App.css";
import Utils from "../../utils";
import { useState, useEffect } from "react";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import awsUtils from "../../awsUtils";
import useZustant from "../../hooks/useZustant";
import Requests from "../../logic/Requests";


export default function MoveFilesModal(props) {
  const {
    show,
    close,
    folderPrefix,
    currentFolder,
    row,
    handleAlert,
    updateFiles,
    listOfFiles,
    clearSelected,
    setRowFileData,
  } = props;
  const { baseNameFolder } = Utils;
const { moveMyFilesObject, moveMyFilesMultipleObject } = awsUtils;
  const { allClientFiles, changeAllClientFiles, user } = useZustant();

  const [folders, setFolders] = useState([]);
  const [folderInsidePrefix, setFolderInsidePrefix] = useState([]);

  const fetchS3Folders = async (prefix) => {
    const response = await Requests.getMyFilesObjects(Config.awsBucketName, prefix !== "/" ? prefix : "")
    const folders = [];

    const s3Prefixes = response.CommonPrefixes;

    if (s3Prefixes) {
      folders.push(
        ...s3Prefixes.map((prefix) => ({
          id: prefix.Prefix,
          name: baseNameFolder(prefix.Prefix),
          isDir: true,
        }))
      );
    }
    return folders;
  };

  useEffect(() => {
    if (folderPrefix && folderPrefix.length > 0) {
      if (folderPrefix.length <= 2) {
        setFolderInsidePrefix([folderPrefix[0]]);
      } else {
        setFolderInsidePrefix(folderPrefix);
      }
    }
  }, [folderPrefix, show]);

  const FolderChain = () => {
    if (folderInsidePrefix) {
      return (
        <div style={{ display: "flex" }}>
          {folderInsidePrefix.map((folder) => {
            return (
              <h5
                key={folder.id}
                onClick={() => goFolderChainSpecificFolder(folder)}
                style={{ cursor: "pointer" }}
              >
                {allClientFiles?.filter(e => e.path == folder.id)[0]?.filename ?? folder.name} {" > "}
                {/* {folder.name} {" > "} */}
              </h5>
            );
          })}
        </div>
      );
    }
  };

  const returnCurrent = (folder) => {
    if (currentFolder.id === folder.id) {
      return <b style={{ marginLeft: "5px" }}> (Current Folder) </b>;
    } else {
      return null;
    }
  };

  const goFolderChainSpecificFolder = (folder) => {
    const index = folderInsidePrefix.indexOf(folder);

    const aux = [].concat(folderInsidePrefix);

    aux.length = index + 1;
    setFolderInsidePrefix(aux);
    clearSelected();
  };

  useEffect(() => {
    if (folderInsidePrefix && folderPrefix && folderInsidePrefix.length > 0) {
      fetchS3Folders(folderInsidePrefix[folderInsidePrefix.length - 1].id).then(
        (res) => {
          setFolders(res);
        }
      );
    }
  }, [setFolders, folderInsidePrefix, show]);

  const changePrefix = (folder) => {
    setFolderInsidePrefix([
      ...folderInsidePrefix,
      { id: folder.id, name: folder.name, isDir: true },
    ]);
  };

  const Body = () => {
    if (folderInsidePrefix && folderInsidePrefix.length > 0) {
      const currentFolderFullData = allClientFiles?.filter(e => e.path == folderInsidePrefix[folderInsidePrefix.length - 1].id)[0];
      return (
        <TableContainer sx={{ height: 300, marginBottom: "20px" }}>
          <Table>
            <TableHead>
              <TableRow sx={{ cursor: "pointer" }}>
                <TableCell sx={{ display: "flex", alignItems: "center" }}>
                  <FolderOpenIcon sx={{ marginRight: "5px" }} />
                  {currentFolderFullData?.filename ?? folderInsidePrefix[folderInsidePrefix.length - 1].name}{" "}
                  {returnCurrent(
                    folderInsidePrefix[folderInsidePrefix.length - 1]
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {folders.map((folder) => {
                return (
                  <TableRow
                    sx={{ cursor: "pointer" }}
                    key={folder.id}
                    onClick={() => changePrefix(folder)}
                  >
                    <TableCell
                      scope="row"
                      padding="none"
                      component="th"
                      sx={{
                        padding: "10px",
                        paddingLeft: "35px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FolderIcon sx={{ marginRight: "5px" }} /> {allClientFiles?.filter(e => e.path == folder.id)[0]?.filename ?? folder.name}
                      {returnCurrent(folder)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
  };

 const moveFilesObjectHandler = () =>{
  let res;
  try{
    if(listOfFiles){
       moveMyFilesMultipleObject(
        row,
        folderInsidePrefix,
        user,
        changeAllClientFiles,
        updateFiles,
        clearSelected,
        setRowFileData
      ).then(res=> 
         handleAlert("success","File moved successfully.")).catch((e)=>console.error(e));
    }else{
      res = moveMyFilesObject(
        row,
        folderInsidePrefix,
        user,
        changeAllClientFiles,
        updateFiles,
        clearSelected,
        setRowFileData
      ).then((res) => {
        handleAlert(res.type, res.message);
      });
      }
  }catch(error){
    if(error.type){
      handleAlert(error.type,error.message);
    }else{
      handleAlert("error","there was an error trying to move the file.");
    }
  }
    close()
 }

  return (
    <Modal open={show} onClose={close}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <FolderChain />
        <Body></Body>
        <Box sx={{ display: "flex", justifyContent: "right" }}>
          <Button
            onClick={() => close()}
            color="error"
            variant="contained"
            sx={{ marginRight: "7px" }}
          >
            Cancel
          </Button>
          <Button
            onClick={moveFilesObjectHandler}
            color="success"
            variant="contained"
            className="uploadFiles"
          >
            move
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
