import {
  Modal,
  Box,
  FormControl,
  InputLabel,
  Button,
  InputBase,
} from "@mui/material";
import Config from "../../config";
import "../../App.css";
import theme from "../../theme";
import Requests from "../../logic/Requests";
import useZustant from "../../hooks/useZustant";

export default function CreateFolderModal(props) {
  const { show, close, path, updateFiles, handleAlert } = props;
const {allClientFiles}=useZustant();
  const inputStyle = {
    marginTop: theme.spacing(3),
    position: "relative",
    borderRadius: "4px",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    borderBottom: "none",
    border: "1px solid black",
    fontSize: 18,
    padding: "5px 6px",
    height: "46px",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData(e.currentTarget);

      const name = formData.get("folderName");
    
      const alreadyExist = allClientFiles.map(e=>e.path).includes(path+name+"/")
      if(alreadyExist){
        handleAlert("warning", "This folder name is already in use. Please choose another one.");
      }else{
        const createFolderRes = await Requests.createFolder(
          { prefix: path,name}
            );
            handleAlert("success", "Folder created successfully");
            close();
            updateFiles();
      }
   
     } catch (error) {
      handleAlert("error", "There was an error creating the folder");
    }
  };

  return (
    <Modal open={show}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <form onSubmit={(e) => handleSubmit(e)}>
          <h2>Create Folder</h2>
          <FormControl fullWidth className="formControl" variant="standard">
            <InputLabel htmlFor="folderName" shrink>
              Name this folder
            </InputLabel>
            <InputBase
              sx={inputStyle}
              id="folderName"
              name="folderName"
              required
            />
          </FormControl>
          <div className="createFolderButtonContainer">
            <Button
              type="submit"
              color="success"
              variant="contained"
              sx={{ marginRight: "7px" }}
            >
              Create
            </Button>
            <Button onClick={() => close()} color="red" variant="contained">
              Cancel
            </Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
}
