import { useContext, useState, useRef } from "react"
import {
    Box,
    Button,
    Typography,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    FormControlLabel,
    Checkbox,
    Divider,
    InputLabel,
    IconButton,
    TextField
} from "@mui/material";
import { UploadFilesContext } from "../../../views/MyFiles/UploadFiles/context/UploadFilesContext"
import FileItem from "./FileItem";
import useZustant from "../../../hooks/useZustant";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const headCell = [
    {
        id: "filename",
        label: "Unit ID",
    },
    {
        id: "estimate",
        label: "Est. Delivery",
    },
    {
        id: "cost",
        label: "Cost",
    },
];

export default function OrderTicket({ setIsInCheckout }) {
    const { newOrderType } = useZustant()
    const {
        getInputProps,
        open,
        uploadingFiles,
        setUploadingFiles,
        isDragHover,
        uploadedFilesFilesObject,
        checkedOptionsFilesObject,
        setCheckedOptionsFilesObject,
        setFilesObject
    } = useContext(UploadFilesContext)

    const [orderReferenceName, setOrderReferenceName] = useState("")

    const [totalDuration, setTotalDuration] = useState(0)
    const [orderSubTotal, setOrderSubtotal] = useState(0.0)

    const orderFilesCost = useRef([])

    const [allHuman, setAllHuman] = useState(false)
    const [isAudioProfessionalQuality, setIsAudioProfessionalQuality] = useState(false)
    const [needsRush, setNeedsRush] = useState(false)
    const [needsTimeStamping, setNeedsTimeStamping] = useState(false)
    const [needsVerbatim, setNeedsVerbatim] = useState(false)
    const [needsRedaction, setNeedsRedaction] = useState(false)
    const [needsInstantFirstDraft, setNeedsInstantFirstDraft] = useState(false)
    const [needsHuman, setNeedsHuman] = useState(false)
    

    const uploadedFiles = useRef([])

    const aditionalWorkBasicRates = {
        allHuman: 1.25,
        audioProfessionalQualityCost: 1,
        rushOrderCost: 1,
        timeStampingConst: 0.3,
        verbatimCost: 0.5,
        instantFirstDraftCost: 0.1,
        humanTranscriptCost: 1.35,
        redactionCost: 0.30
    }


    const getTotalAditionalWorkCost = () => {
        let total = 0.0
        const duration = Math.ceil(totalDuration)
        if (allHuman) total = total + totalDuration * aditionalWorkBasicRates.allHuman
        if (isAudioProfessionalQuality) total = total + totalDuration * aditionalWorkBasicRates.audioProfessionalQualityCost
        if (needsRush) total = total + (totalDuration * aditionalWorkBasicRates.rushOrderCost)
        if (needsTimeStamping) total = total + (totalDuration * aditionalWorkBasicRates.timeStampingConst)
        if (needsVerbatim) total = total + (totalDuration * aditionalWorkBasicRates.verbatimCost)
        if (needsRedaction) total = total + (totalDuration * aditionalWorkBasicRates.redactionCost)
        if (needsInstantFirstDraft) total = total + (totalDuration * aditionalWorkBasicRates.instantFirstDraftCost)
        if (needsHuman) total = total + (totalDuration * aditionalWorkBasicRates.humanTranscriptCost)
        return total
    }

    const deleteFile = (tempId, duration) => {
        setUploadingFiles(uploadingFiles.filter((fi) => fi.file.tempId !== tempId))
        uploadedFiles.current = uploadedFiles.current.filter((fi) => fi.tempId !== tempId)
        orderFilesCost.current = orderFilesCost.current.filter((fi) => fi.tempId !== tempId)
        uploadedFilesFilesObject.current = uploadedFilesFilesObject.current.filter((fi) => fi.tempId !== tempId)
        setTotalDuration(totalDuration - duration)
    }

    const getSubtotalCost = () => {
        return orderFilesCost.current.reduce((totalCost, file) => {
            return totalCost + file.cost
        }, 0.0)
    }

    const getTotalCost = () => {
        const subtotal = getSubtotalCost()
        const additionalWork = getTotalAditionalWorkCost()
        return (subtotal + additionalWork).toFixed(2)
    }

    const goToCheckout = () => {
        const basicRates = {
            "ALL HUMAN": 1.25,
            "PROFESSIONAL": 1,
            "RUSH": 1.25,
            "TIMESTAMPING": 0.3,
            "VERBATIM": 0.5,
            "INSTANT FIRST DRAFT": 0.1,
            "NEED HUMAN": 1.35,
            "REDACTION" : 0.30
        }

        const formattedCheckedOptionsArray = []

        for (let key in checkedOptionsFilesObject) {
            formattedCheckedOptionsArray.push({
                checked: checkedOptionsFilesObject[key],
                label: key,
                value: totalDuration * basicRates[key],
                cost: basicRates[key]
            })
        }
        const filesObjectFormatted = {
            checkedOptions: formattedCheckedOptionsArray,
            completedUploads: uploadedFilesFilesObject.current,
            newOrderType,
            subtotal: getSubtotalCost(),
            total: Number(getTotalCost()),
            referenceName: orderReferenceName
        }
        setFilesObject(filesObjectFormatted)
        setIsInCheckout(true)
    }

    const handleCheckBoxChange = (label, e) => {

        setCheckedOptionsFilesObject({
            ...checkedOptionsFilesObject,
            [label]: e.target.checked
        })
    }
    return (
        <>
            <h2>Order Ticket</h2>
            <Box container="true" mt={2} width={"100%"} style={{ display: "flex" }}>
                <Box width={"72%"} >
                    <Box className="checkoutDescription" sx={{ px: 5 }}>
                        <input hidden multiple type="file" {...getInputProps()}  />
                        <Button
                            variant="outlined"
                            sx={{
                                marginBottom: "20px",
                                padding: "10px 20px",
                                fontWeight: "bold",
                            }}
                            onClick={()=>open()}
                            // {...getRootProps()}
                        >
                            Upload More Files
                        </Button>
                        <div>
                            <Box
                                sx={
                                    ({ width: "100%" },
                                        isDragHover ? { display: "none" } : { display: "inherit" })
                                }
                            >
                                <Paper>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    {headCell.map((headCell) => (
                                                        <TableCell
                                                            key={headCell.id}
                                                            align={
                                                               "left"
                                                            }
                                                        >
                                                            {headCell.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {uploadingFiles.map((currentUploadingFile) => (
                                                    <FileItem
                                                        key={currentUploadingFile.tempId}
                                                        file={currentUploadingFile.file}
                                                        uploadProcess={{}}
                                                        setOrderSubtotal={setOrderSubtotal}
                                                        orderSubtotal={orderSubTotal}
                                                        setTotalDuration={setTotalDuration}
                                                        deleteFile={deleteFile}
                                                        orderFilesCost={orderFilesCost}
                                                        uploadedFiles={uploadedFiles}
                                                        currentUploadingFile={currentUploadingFile}
                                                        uploadedFilesFilesObject={uploadedFilesFilesObject}
                                                        needsHuman={needsHuman}
                                                    />
                                                )
                                                )}
                                                {newOrderType === "human" ? (
                                                <TableRow>
                                                <TableCell component="th" scope="row" colSpan={3}>
                                                    <Typography  width={"100%"}>
                                                        Helpful Options 
                                                    </Typography>
                                                </TableCell>
                                                </TableRow> ) : 
                                                    <></>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            marginBottom: "40px",
                                        }}
                                    >
                                        {newOrderType === "human" ? (
                                            <>
                                                <div className="optionContainer">
                                                    <div className="infBody">
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    size="small"
                                                                    checked={allHuman}
                                                                    onChange={(e) => {
                                                                        setAllHuman(e.target.checked)
                                                                        handleCheckBoxChange("ALL HUMAN", e)
                                                                    }}
                                                                    name="allHuman"
                                                                />
                                                            }
                                                            label={<Typography fontWeight="bold">All Human + $1.25     <span style={{backgroundColor: "yellow", padding: "4px"}}>USER FAVORITE</span></Typography>}
                                                            className="options"
                                                        />
                                                        <Typography variant="h5" className="optionInf">
                                                            All human and no AI assist, great for more than 3 speakers.
                                                        </Typography>
                                                    </div>
                                                    <span className="optionCost">
                                                        $
                                                        {allHuman
                                                            ? (totalDuration * aditionalWorkBasicRates
                                                                .allHuman).toFixed(2)
                                                            : "0.00"}
                                                    </span>
                                                </div>
                                                <div className="optionContainer">
                                                    <div className="infBody">
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    size="small"
                                                                    checked={isAudioProfessionalQuality}
                                                                    onChange={(e) => {
                                                                        setIsAudioProfessionalQuality(e.target.checked)
                                                                        handleCheckBoxChange("PROFESSIONAL", e)
                                                                    }}
                                                                    name="isAudioProfessionalQuality"
                                                                />
                                                            }
                                                            label={<Typography fontWeight="bold">Professional Quality + $1.00 min</Typography>}
                                                            className="options"
                                                        />
                                                        <Typography variant="h5" className="optionInf">
                                                            Best for groups, challenging audio & business use.
                                                        </Typography>
                                                    </div>
                                                    <span className="optionCost">
                                                        $
                                                        {isAudioProfessionalQuality
                                                            ? (totalDuration * aditionalWorkBasicRates
                                                                .audioProfessionalQualityCost).toFixed(2)
                                                            : "0.00"}
                                                    </span>
                                                </div>
                                                <div className="optionContainer">
                                                    <div className="infBody">
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    size="small"
                                                                    checked={needsRush}
                                                                    onChange={(e) => {
                                                                        setNeedsRush(e.target.checked)
                                                                        handleCheckBoxChange("RUSH", e)
                                                                    }}
                                                                    name="needsRush"
                                                                />
                                                            }
                                                            label={<Typography fontWeight="bold">Expedite My Order + $1.00 min</Typography>}
                                                            className="options"
                                                        />
                                                        <Typography variant="h5" className="optionInf">
                                                            Twice as fast
                                                        </Typography>
                                                    </div>
                                                    <span className="optionCost">
                                                        $
                                                        {needsRush
                                                            ? (totalDuration * aditionalWorkBasicRates
                                                                .rushOrderCost).toFixed(2)
                                                            : "0.00"}
                                                    </span>
                                                </div>
                                                <div className="optionContainer">
                                                    <div className="infBody">
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    size="small"
                                                                    checked={needsTimeStamping}
                                                                    onChange={(e) => {
                                                                        setNeedsTimeStamping(e.target.checked)
                                                                        handleCheckBoxChange("TIMESTAMPING", e)
                                                                    }}
                                                                    name="needsTimeStamping"
                                                                />
                                                            }
                                                            label={<Typography fontWeight="bold">Timestamping + $0.30 min</Typography>}                                                          
                                                            className="options"
                                                        />
                                                        <Typography variant="h5" className="optionInf">
                                                            Every speaker change timestamps
                                                        </Typography>
                                                    </div>
                                                    <span className="optionCost">
                                                        $
                                                        {needsTimeStamping
                                                            ? (totalDuration * aditionalWorkBasicRates
                                                                .timeStampingConst).toFixed(2)
                                                            : "0.00"}
                                                    </span>
                                                </div>
                                                <div className="optionContainer">
                                                    <div className="infBody">
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    size="small"
                                                                    checked={needsVerbatim}
                                                                    onChange={(e) => {
                                                                        setNeedsVerbatim(e.target.checked)
                                                                        handleCheckBoxChange("VERBATIM", e)
                                                                    }}
                                                                    name="needsVerbatim"
                                                                />
                                                            }
                                                            label={<Typography fontWeight="bold">Verbatim + $0.50 min</Typography>}
                                                            className="options"
                                                        />
                                                        <Typography variant="h5" className="optionInf">
                                                            Include filler words (um, ah), nonverbal
                                                            communication, and false starts.
                                                        </Typography>
                                                    </div>
                                                    <span className="optionCost">
                                                        $
                                                        {needsVerbatim
                                                            ? (totalDuration * aditionalWorkBasicRates
                                                                .verbatimCost).toFixed(2)
                                                            : "0.00"}
                                                    </span>
                                                </div>
                                                <div className="optionContainer">
                                                    <div className="infBody">
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    size="small"
                                                                    checked={needsRedaction}
                                                                    onChange={(e) => {
                                                                        setNeedsRedaction(e.target.checked)
                                                                        handleCheckBoxChange("REDACTION", e)
                                                                    }}
                                                                    name="needsRedaction"
                                                                />
                                                            }
                                                            label={<Typography fontWeight="bold">Redaction – Removal of personal identifiable information + 0.30 min</Typography>}
                                                            className="options"
                                                        />
                                                    </div>
                                                    <span className="optionCost">
                                                        $
                                                        {needsRedaction
                                                            ? (totalDuration * aditionalWorkBasicRates
                                                                .redactionCost).toFixed(2)
                                                            : "0.00"}
                                                    </span>
                                                </div>
                                                <div className="optionContainer">
                                                    <div>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    size="small"
                                                                    checked={needsInstantFirstDraft}
                                                                    disabled
                                                                    onChange={(e) => {
                                                                        setNeedsInstantFirstDraft(e.target.checked)
                                                                        handleCheckBoxChange("INSTANT FIRST DRAFT", e)
                                                                    }}
                                                                    name="needsInstantFirstDraft"
                                                                />
                                                            }
                                                            label={<Typography style={{color: "#bdbdbd"}} fontWeight="bold">Instant First Draft + $0.10 min (Coming soon)</Typography>}
                                                            className="options"
                                                        />
                                                        <Typography style={{color: "#bdbdbd"}} variant="h5" className="optionInf">
                                                        Automated draft transcript delivered in minutes. (Coming soon)
                                                        </Typography>
                                                    </div>
                                                    <span style={{color: "#bdbdbd"}} className="optionCost">
                                                        $
                                                        {needsInstantFirstDraft
                                                            ? (totalDuration * aditionalWorkBasicRates
                                                                .instantFirstDraftCost).toFixed(2)
                                                            : "0.00"}
                                                    </span>
                                                </div>
                                            </>
                                        ):(
                                            <div></div>
                                        )}
                                        <Divider sx={{ marginTop: "15px" }} />
                                        <Box
                                            sx={{
                                                py: 5,
                                                px: 2,
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography variant="h2" sx={{ fontWeight: "300" }}>
                                                Order Total
                                            </Typography>

                                            <span style={{ fontSize: "18px" }}>
                                                $ {getTotalCost()}
                                            </span>
                                        </Box>
                                    </Box>
                                </Paper>
                                <Box sx={{ pb: 3 }}>
                                    <InputLabel sx={{ fontSize: 14 }}>
                                        FOLDER NAME (OPTIONAL)
                                        <IconButton variant="outlined">
                                            <InfoOutlinedIcon height={14} width={14} />
                                        </IconButton>
                                    </InputLabel>
                                    <TextField
                                        fullWidth 
                                        id="fullWidth"
                                        sx={{ boxShadow: -5 }}
                                        onChange={(e) => {
                                            setOrderReferenceName(e.target.value);
                                        }}
                                    />
                                </Box>
                            </Box>
                        </div>
                    </Box>
                </Box>
                <Box className="checkoutContainer" width={"28%"} sx={{ pl: 5 }}>
                    <Typography variant="h6" sx={{ marginBottom: "20px" }}>
                        Order Total &nbsp; ${getTotalCost()}
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{
                            width: "80%",
                            color: "white !important",
                            letterSpacing: "2px !important",
                            padding: "10px !important",
                        }}
                        color="primary"
                        onClick={() => goToCheckout()}
                        disabled={!(uploadedFiles.current.length === uploadingFiles.length)}
                    >
                        Checkout
                    </Button>
                </Box>
            </Box>
        </>
    )
}